import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from 'global/utils';

import { useCustomerAuthorizedUserSession } from 'talent-hub/utils';
import type { RoleLayout } from '../../components';
import { SelectJobRequirements } from './data';
import serializeJobRequirements from './JobRequirements.serializer';
import { JobRequirements } from './JobRequirements';

export function JobRequirementsController({
  roleLayoutProps,
}: {
  roleLayoutProps: React.ComponentProps<typeof RoleLayout>;
}) {
  const { viewingOrganization } = useCustomerAuthorizedUserSession();
  const { activeRole } = useParams<{
    activeRole: string;
  }>();

  const [currentView, setCurrentView] = useState('OVERVIEW');

  const { loading, data } = useQuery(SelectJobRequirements, {
    variables: {
      organization_id: viewingOrganization.ID,
      job_id: activeRole,
    },
    fetchPolicy: 'network-only',
  });

  const job: any = serializeJobRequirements(data);

  return (
    <JobRequirements
      currentView={currentView}
      job={job}
      loading={loading}
      onChangeView={(newView: string) => setCurrentView(newView)}
      roleLayoutProps={roleLayoutProps}
    />
  );
}
