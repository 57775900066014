import moment from 'moment';
import { useEffect } from 'react';
import { NetworkStatus } from '@apollo/client';
import { Switch, Route, useParams, useHistory, useRouteMatch, useLocation } from 'react-router-dom';

import * as events from 'global/events';
import { useQuery } from 'global/utils';
import { CustomRoute } from 'global/components';

import { useCustomerAuthorizedUserSession, useTalentHubFlags } from 'talent-hub/utils';

import { SelectRoleData } from './data';
import { serializeRoleData } from './Role.serializer';

import { roleTabs } from './components/RoleLayout';
import { CandidateProfileController } from './subfeatures/candidate-profile';
import { Progress } from './subfeatures/progress';
import { JobCandidatesBuckets } from './subfeatures/job-candidates-buckets';
import { JobRequirementsController } from './subfeatures/requirements';
import { RoleWeeklySummaries } from './subfeatures/weekly-summaries';
import { CreateEditJobController } from './subfeatures/create-edit-job';
import { ViewJobController } from './subfeatures/view-job';

import { CandidateFeedbackController } from './subfeatures/candidate-feedback';
import { ManageRolesController } from './subfeatures/manage-roles';
import { useInterviewFeedbackUtils } from './useInterviewFeedbackUtils';
import { ManageRolesDashboardController } from './subfeatures/manage-roles-roles-base';

const today = moment();

export function ActiveRoleController({
  children,
}: {
  children: ({
    activeRole,
    roleLayoutProps,
    isQueryLoading,
    activeRolePath,
    upcomingSubmissionCandidates,
    pastSubmissionCandidates,
    interviewsToBeScheduledCandidates,
    upcomingInterviewCandidates,
    pastInterviewCandidates,
    upcomingOffersCandidates,
    pastOffersCandidates,
    hiredCompleteCandidates,
    totalSubmissions,
    totalActiveCandidates,
    totalPositions,
    totalHired,
    totalHiresUpcomingText,
    role,
    positions,
    milestone1,
    milestone2,
    milestone3,
    milestone4,
    milestone5,
    recentCandidatesActivities,
    weeklySummaries,
    interviewFeedbackUtils,
  }: any) => JSX.Element;
}) {
  const {
    userPrioritizedRole,
    isClientReviewer,
    isRecruiter,
    user,
    viewingOrganization,
    isClientProspect,
  } = useCustomerAuthorizedUserSession();
  const history = useHistory();
  const { state } = useLocation<{
    from?: string;
  }>();
  const { path } = useRouteMatch();
  const { activeRole, activeTab } = useParams<{
    activeRole: string;
    activeTab: string;
  }>();
  const { loading, data, networkStatus } = useQuery(SelectRoleData, {
    variables: {
      organization: viewingOrganization.ID,
      job_id: activeRole,
      applicant_workflow_offset: 0,
    },
    fetchPolicy: 'network-only',
    skip: !activeRole || isClientReviewer,
  });

  const isQueryLoading = loading || networkStatus === NetworkStatus.refetch;

  const handleBackAction = () => {
    if (state?.from === 'dashboard') {
      history.push(`/`);
      return;
    }
    if (isClientReviewer) {
      history.push(`/candidates`);
      return;
    }
    history.push(`/role`);
  };

  function backActionMessageMapper(from: string | undefined) {
    if (isClientReviewer) {
      return 'Back to Manage Candidates';
    }

    switch (from) {
      case 'dashboard':
        return 'Back to Dashboard';
      default:
        return 'Back to Manage Roles';
    }
  }
  const backActionMessage = backActionMessageMapper(state?.from);

  const interviewFeedbackUtils = useInterviewFeedbackUtils({
    applicantWorkflowID: undefined,
    jobID: data?.icims_job?.[0]?.job?.id,
    shouldRefreshFeedbackList: true,
  });

  const {
    roleTitle,
    buckets: {
      upcomingSubmissionCandidates,
      pastSubmissionCandidates,
      interviewsToBeScheduledCandidates,
      upcomingInterviewCandidates,
      pastInterviewCandidates,
      upcomingOffersCandidates,
      pastOffersCandidates,
      hiredCompleteCandidates,
      recentCandidatesActivities,
    },
    progress: {
      metrics: {
        totalSubmissions,
        totalActiveCandidates,
        totalPositions,
        totalHired,
        totalHiresUpcomingText,
        role,
        positions,
      },
      milestones: { milestone1, milestone2, milestone3, milestone4, milestone5 },
    },
    weeklySummaries,
  } = serializeRoleData(data);

  // Event tracking for new role
  useEffect(() => {
    if (roleTitle && !loading) {
      events.track(events.name.viewedRole, {
        role: roleTitle,
      });
    }
  }, [roleTitle, loading]);

  // Event tracking for new role tab
  useEffect(() => {
    // Track changes to the active tab. Require activeTab, since the page will reroute to /progress
    // if no tab is set, and that clutters up the events
    if (activeTab && roleTitle && !loading) {
      events.track(events.name.viewedRoleTab, {
        role: roleTitle,
        tab: activeTab,
      });
    }
  }, [activeTab, roleTitle, loading]);

  const pageLayoutProps = {
    isClientReviewer,
    isRecruiter,
    orgName: viewingOrganization.name,
    user,
    isClientProspect,
    userPrioritizedRole,
  };

  const roleLayoutProps = {
    activeRole,
    hiredCompleteCandidates: hiredCompleteCandidates.length,
    pageLayoutProps: { ...pageLayoutProps, subHeader: null },
    roleTitle,
    upcomingInterviewCandidates: upcomingInterviewCandidates.length,
    upcomingOffersCandidates: upcomingOffersCandidates.length,
    upcomingSubmissionCandidates: upcomingSubmissionCandidates.length,
    showSubHeader: false,
    handleBackAction,
    backActionMessage,
    isClientReviewer,
  };

  return children({
    activeRole,
    roleLayoutProps,
    isQueryLoading,
    activeRolePath: path,
    upcomingSubmissionCandidates,
    pastSubmissionCandidates,
    interviewsToBeScheduledCandidates,
    upcomingInterviewCandidates,
    pastInterviewCandidates,
    upcomingOffersCandidates,
    pastOffersCandidates,
    hiredCompleteCandidates,
    totalSubmissions,
    totalActiveCandidates,
    totalPositions,
    totalHired,
    totalHiresUpcomingText,
    role,
    positions,
    milestone1,
    milestone2,
    milestone3,
    milestone4,
    milestone5,
    recentCandidatesActivities,
    weeklySummaries,
    interviewFeedbackUtils,
  });
}

export function RoleRouter() {
  const { path } = useRouteMatch();
  const {
    userPrioritizedRole,
    isClient,
    isClientReviewer,
    isRecruiter,
    user,
    viewingOrganization,
    isClientProspect,
  } = useCustomerAuthorizedUserSession();
  const pageLayoutProps = {
    isClientReviewer,
    isRecruiter,
    orgName: viewingOrganization.name,
    user,
    isClientProspect,
    userPrioritizedRole,
  };
  const featureFlags = useTalentHubFlags();

  return (
    <Switch>
      <Route exact path={path}>
        {featureFlags['role-based-rehaul'] ? (
          <ManageRolesDashboardController />
        ) : (
          <ManageRolesController />
        )}
      </Route>
      <CustomRoute
        path={`${path}/view/:jobID`}
        conditionalRedirects={[{ condition: !(isClient || isRecruiter), redirectURL: `/` }]}
      >
        <ViewJobController />
      </CustomRoute>
      <CustomRoute
        path={[`${path}/create`, `${path}/edit/:jobID`]}
        conditionalRedirects={[{ condition: !(isClient || isRecruiter), redirectURL: `/` }]}
      >
        <CreateEditJobController />
      </CustomRoute>
      <Route path={`${path}/:activeRole/`}>
        <ActiveRoleController>
          {({
            activeRole,
            roleLayoutProps,
            isQueryLoading,
            activeRolePath,
            upcomingSubmissionCandidates,
            pastSubmissionCandidates,
            interviewsToBeScheduledCandidates,
            upcomingInterviewCandidates,
            pastInterviewCandidates,
            upcomingOffersCandidates,
            pastOffersCandidates,
            hiredCompleteCandidates,
            totalSubmissions,
            totalActiveCandidates,
            totalPositions,
            totalHired,
            totalHiresUpcomingText,
            role,
            positions,
            milestone1,
            milestone2,
            milestone3,
            milestone4,
            milestone5,
            recentCandidatesActivities,
            weeklySummaries,
            interviewFeedbackUtils,
          }) => (
            <Switch>
              <CustomRoute path={`${activeRolePath}${roleTabs.progress}`}>
                <Progress
                  roleLayoutProps={{
                    ...roleLayoutProps,
                    pageLayoutProps,
                    showSubHeader: true,
                  }}
                  totalSubmissions={totalSubmissions}
                  totalActiveCandidates={totalActiveCandidates}
                  totalPositions={totalPositions}
                  totalHired={totalHired}
                  totalHiresUpcomingText={totalHiresUpcomingText}
                  role={role}
                  positions={positions}
                  milestone1={milestone1}
                  milestone2={milestone2}
                  milestone3={milestone3}
                  milestone4={milestone4}
                  milestone5={milestone5}
                  recentCandidatesActivities={recentCandidatesActivities}
                  isLoading={isQueryLoading}
                  activeRole={activeRole}
                  today={today}
                  interviewFeedbackUtils={interviewFeedbackUtils}
                />
              </CustomRoute>
              <Route path={`${activeRolePath}${roleTabs.submissions}`}>
                <JobCandidatesBuckets
                  roleLayoutProps={{
                    ...roleLayoutProps,
                    pageLayoutProps,
                    showSubHeader: true,
                  }}
                  candidatesBuckets={[
                    {
                      bucketTitle: 'Submissions Requiring Action',
                      candidates: upcomingSubmissionCandidates,
                      defaultCandidateStatus: 'PENDING',
                      dateColumnTitle: 'Submitted On',
                      pendingActionName: 'Review Candidate',
                    },
                    {
                      bucketTitle: 'Past Submissions',
                      candidates: pastSubmissionCandidates,
                      defaultCandidateStatus: 'COMPLETE',
                    },
                  ]}
                />
              </Route>
              <Route path={`${activeRolePath}${roleTabs.interviews}`}>
                <JobCandidatesBuckets
                  roleLayoutProps={{
                    ...roleLayoutProps,
                    pageLayoutProps,
                    showSubHeader: true,
                  }}
                  candidatesBuckets={[
                    {
                      bucketTitle: 'Interviews – Active',
                      candidates: upcomingInterviewCandidates,
                      defaultCandidateStatus: 'PENDING',
                      pendingActionName: 'View Candidate',
                      shouldShowFeedbackCTA: true,
                    },
                    {
                      bucketTitle: 'Interviews – Scheduling',
                      candidates: interviewsToBeScheduledCandidates,
                      defaultCandidateStatus: 'PENDING',
                      pendingActionName: 'View Candidate',
                      shouldShowFeedbackCTA: true,
                    },
                    {
                      bucketTitle: 'Past Interviews',
                      candidates: pastInterviewCandidates,
                      defaultCandidateStatus: 'COMPLETE',
                      shouldShowFeedbackCTA: true,
                    },
                  ]}
                />
              </Route>
              <Route path={`${activeRolePath}${roleTabs.offers}`}>
                <JobCandidatesBuckets
                  roleLayoutProps={{
                    ...roleLayoutProps,
                    pageLayoutProps,
                    showSubHeader: true,
                  }}
                  candidatesBuckets={[
                    {
                      bucketTitle: 'Active Offers',
                      candidates: upcomingOffersCandidates,
                      defaultCandidateStatus: 'COMPLETE',
                    },
                    {
                      bucketTitle: 'Past Offers',
                      candidates: pastOffersCandidates,
                      defaultCandidateStatus: 'COMPLETE',
                    },
                  ]}
                />
              </Route>
              <Route path={`${activeRolePath}${roleTabs.hires}`}>
                <JobCandidatesBuckets
                  roleLayoutProps={{
                    ...roleLayoutProps,
                    pageLayoutProps,
                    showSubHeader: true,
                  }}
                  candidatesBuckets={[
                    {
                      bucketTitle: 'Hired',
                      candidates: hiredCompleteCandidates,
                      defaultCandidateStatus: 'COMPLETE',
                    },
                  ]}
                />
              </Route>
              <Route path={`${activeRolePath}${roleTabs.requirements}`}>
                <JobRequirementsController
                  roleLayoutProps={{
                    ...roleLayoutProps,
                    pageLayoutProps,
                    showSubHeader: true,
                  }}
                />
              </Route>
              <Route path={`${activeRolePath}${roleTabs.reports}`}>
                <RoleWeeklySummaries
                  roleLayoutProps={{
                    ...roleLayoutProps,
                    pageLayoutProps,
                    showSubHeader: true,
                  }}
                  weeklySummaries={weeklySummaries}
                  activeRole={activeRole}
                />
              </Route>
              <Route path={`${activeRolePath}candidate/:candidateID/feedback`}>
                <CandidateFeedbackController roleLayoutProps={roleLayoutProps} />
              </Route>
              <Route path={`${activeRolePath}candidate/:candidateID`}>
                <CandidateProfileController roleLayoutProps={roleLayoutProps} />
              </Route>
              <CustomRoute
                path={activeRolePath}
                conditionalRedirects={[
                  {
                    condition: true,
                    redirectURL: `/role/${activeRole}/${roleTabs.progress}`,
                  },
                ]}
              />
            </Switch>
          )}
        </ActiveRoleController>
      </Route>
    </Switch>
  );
}

export function ClientReviewerRoleRouter() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/:activeRole/`}>
        <ActiveRoleController>
          {({ roleLayoutProps, activeRolePath }) => (
            <Switch>
              <Route path={`${activeRolePath}candidate/:candidateID/feedback`}>
                <CandidateFeedbackController roleLayoutProps={roleLayoutProps} />
              </Route>
              <Route path={`${activeRolePath}candidate/:candidateID`}>
                <CandidateProfileController roleLayoutProps={roleLayoutProps} />
              </Route>
              <CustomRoute
                path={activeRolePath}
                conditionalRedirects={[
                  {
                    condition: true,
                    redirectURL: `/candidates`,
                  },
                ]}
              />
            </Switch>
          )}
        </ActiveRoleController>
      </Route>
    </Switch>
  );
}
