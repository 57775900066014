// @ts-nocheck // TODO: [TP-1472] Fix file types
import { firstItemOr, stripAllHTMLAttributes } from 'global/utils';
import { pathOr } from 'ramda';
import { readableLocationOr } from 'talent-hub/utils';

function yearsOfExperienceToSeniority(yearsOfExperience) {
  const mapping = {
    '0-2': 'Jr. 0-2',
    '2-5': 'Intermediate 2-5',
    '5-10': 'Sr 5-10',
    '10+': 'Principal 10+',
    // TODO: remove these following old values, when all the data is updated
    'jr 0-2': 'Junior',
    'intermediate 2-5': 'Intermediate',
    'sr 5-10': 'Senior',
    'principal 10+': 'Principal',
  };

  const seniority = yearsOfExperience
    .map(({ value }) => {
      return typeof value === 'string' ? mapping[value.toLowerCase()] : mapping[value];
    })
    .filter(Boolean)
    .join(', ');

  return seniority;
}

function yearsOfExperienceToExperience(yearsOfExperience) {
  const mapping = {
    'jr 0-2': '0-2 years',
    'intermediate 2-5': '2-5 years',
    'sr 5-10': '5-10 years',
    'principal 10+': '10+ years',
  };

  return yearsOfExperience
    .map(({ value }) => {
      return typeof value === 'string' ? mapping[value.toLowerCase()] : mapping[value];
    })
    .filter(Boolean);
}

function headcountToString(active_positions, filled_positions) {
  const active = pathOr(0, ['aggregate', 'count'], active_positions);
  const filled = pathOr(0, ['aggregate', 'count'], filled_positions);
  return `${active} Position${active !== 1 ? 's' : ''} Active${
    filled ? `, ${filled} Position${filled !== 1 ? 's' : ''} Filled` : ''
  }`;
}

// TODO: Fix this
// eslint-disable-next-line import/no-default-export
export default function serializeJobRequirements(data) {
  if (!data) {
    return null;
  }
  const requirements = firstItemOr({}, data.requirementsList);
  const {
    about,
    additional_info,
    hiring_manager,
    active_positions,
    filled_positions,
    nice_to_have,
    tech_stack,
    title,
    what_you_bring,
    what_youll_do,
    years_experience,
  } = requirements;

  const location = requirements.locations
    ?.map(({ icims_location }) =>
      readableLocationOr(icims_location.readable_locations, icims_location.value),
    )
    .join('; ');

  const requiredSkills = typeof tech_stack === 'string' ? tech_stack.split(',') : [];

  return {
    additionalInfo: additional_info && stripAllHTMLAttributes(additional_info),
    companyExperiences: null,
    companySize: pathOr(null, ['icims_company', 'number_of_employees'], requirements),
    culture: null,
    education: null,
    experiences: years_experience ? yearsOfExperienceToExperience(years_experience) : [],
    headcount: headcountToString(active_positions, filled_positions),
    hiringManager: hiring_manager && {
      email: hiring_manager.email,
      fullName: `${hiring_manager.firstname} ${hiring_manager.lastname}`,
      position: firstItemOr({ title: null }, hiring_manager.work_experiences).title,
    },
    location,
    niceToHaveSkills: nice_to_have && stripAllHTMLAttributes(nice_to_have),
    requiredSkills,
    roleDescription: about && stripAllHTMLAttributes(about),
    salaryRange: null,
    seniority: years_experience && yearsOfExperienceToSeniority(years_experience),
    sizeOfEngineeringFunction: null,
    teamLocations: null,
    techStack: null,
    title,
    verticalExperiences: null,
    whatYouBring: what_you_bring && stripAllHTMLAttributes(what_you_bring),
    whatYouWillDo: what_youll_do && stripAllHTMLAttributes(what_youll_do),
  };
}
