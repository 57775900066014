import { Heading, Text, Divider, GridItem, Badge, SimpleGrid } from '@terminal/design-system';
import { Card, CardBody, CardHeader } from 'talent-hub/components';
import { RenderFieldHTML } from './RenderFieldHTML';

export function EmptyFieldText({ children }: { children: React.ReactNode }) {
  return <Text>{children}</Text>;
}

export function FieldRow({
  children,
  emptyText = 'Not Specified',
  label,
  value,
}: {
  children?: React.ReactNode;
  emptyText?: string;
  label: string;
  value?: string | number | string[] | number[];
}) {
  // TODO: Rename to hasContent (?)
  const isEmpty = Array.isArray(value) ? value.length : children || value;

  const renderValue = () => {
    if (children) {
      return children;
    }

    if (Array.isArray(value)) {
      return value.map((val) => (
        <Badge key={`${val}-${label}`} fontSize="sm" colorScheme="accent-lightest" mr={2} mb={2}>
          {val}
        </Badge>
      ));
    }

    return <Text>{value}</Text>;
  };

  return (
    <>
      <GridItem colSpan={1}>
        <Text variant="label" textAlign="right">
          {label}
        </Text>
      </GridItem>
      <GridItem colSpan={3}>
        {isEmpty ? renderValue() : <EmptyFieldText>{emptyText}</EmptyFieldText>}
      </GridItem>
    </>
  );
}

export function FieldDivider() {
  return (
    <GridItem colSpan={4} my={6}>
      <Divider />
    </GridItem>
  );
}
export function JobRequirementsOverview({
  job: {
    title,
    headcount,
    experiences,
    location,
    salaryRange,
    requiredSkills,
    niceToHaveSkills,
    hiringManager,
    education,
    verticalExperiences,
    companyExperiences,
    roleDescription,
    additionalInfo,
    whatYouWillDo,
    whatYouBring,
    sizeOfEngineeringFunction,
    teamLocations,
    techStack,
    companySize,
    culture,
  },
}: {
  job: any;
}) {
  return (
    <Card>
      <CardHeader>
        <Heading variant="heading-3" color="brand.darker">
          Role Requirements
        </Heading>
      </CardHeader>
      <CardBody py={6}>
        <SimpleGrid columns={4} gap={4}>
          <FieldRow label="Title" value={title} />
          <FieldRow label="Location" value={location} />
          {!!salaryRange && (
            <FieldRow label="Salary Range">
              <Text>
                {salaryRange.min}&nbsp;&nbsp;-&nbsp;&nbsp;{salaryRange.max}
              </Text>
            </FieldRow>
          )}
          <FieldRow label="Headcount" value={headcount} />
          <FieldDivider />
          <FieldRow label="Experience" value={experiences} />
          <FieldRow label="Required Skills" value={requiredSkills} />
          <FieldRow label="Good-to-Have Skills">
            {!!niceToHaveSkills && <RenderFieldHTML html={niceToHaveSkills} />}
          </FieldRow>
          {!!companyExperiences && (
            <FieldRow label="Company Experience" value={companyExperiences} />
          )}
          {!!verticalExperiences && (
            <FieldRow label="Vertical Experience" value={verticalExperiences} />
          )}
          {!!education && <FieldRow label="Education" value={education} />}
          <FieldDivider />
          <FieldRow label="Role Description">
            {!!roleDescription && <RenderFieldHTML html={roleDescription} />}
          </FieldRow>
          <FieldRow label="Day-to-Day Activities">
            {!!whatYouWillDo && <RenderFieldHTML html={whatYouWillDo} />}
          </FieldRow>
          <FieldRow label="What You Bring">
            {!!whatYouBring && <RenderFieldHTML html={whatYouBring} />}
          </FieldRow>
          {!!additionalInfo && (
            <FieldRow label="Additional Info">
              <RenderFieldHTML html={additionalInfo} />
            </FieldRow>
          )}
          <FieldDivider />
          {false && (
            <FieldRow label="Hiring Manager">
              {hiringManager ? (
                <>
                  {hiringManager.fullName && <Text>{hiringManager.fullName}</Text>}
                  {hiringManager.email && <Text>{hiringManager.email}</Text>}
                  {hiringManager.position && <Text>{hiringManager.position}</Text>}
                </>
              ) : (
                <EmptyFieldText>Not Specified</EmptyFieldText>
              )}
            </FieldRow>
          )}
          {!!sizeOfEngineeringFunction && (
            <FieldRow label="Size of Engineering Function" value={sizeOfEngineeringFunction} />
          )}
          {!!teamLocations && <FieldRow label="Team Locations" value={teamLocations} />}
          {!!techStack && <FieldRow label="Tech Stack" value={techStack} />}
          <FieldRow label="Size of Company" value={companySize} />
          {!!culture && (
            <FieldRow label="Culture">
              <RenderFieldHTML html={culture} />
            </FieldRow>
          )}
        </SimpleGrid>
      </CardBody>
    </Card>
  );
}
