import { useEffect } from 'react';
import * as events from 'global/events';
import { firebaseAuth } from 'global/firebaseApp';
import Sentry from 'global/sentry';
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useLocation,
  matchPath,
  useHistory,
} from 'react-router-dom';
import { CustomRoute, ActivityIndicator } from 'global/components';
import { useToast } from '@terminal/design-system';
import { useQuery, useRedirection } from 'global/utils';
import { signInAnonymously } from 'global/auth';
import { useCustomerAuth, useExperiment } from 'talent-hub/utils';

import { CompanyNameController } from './steps/company-name';
import { CompanyLocationController } from './steps/company-location';
import { RolesController } from './steps/roles';
import { FinalController } from './steps/final';
import type { OnboardingStep } from './Onboarding.types';
import { serializeOnboardingData } from './data/Onboarding.serializer';
import type { SelectOnboardingDataQuery, SelectOnboardingDataQueryVariables } from './data';
import { SelectOnboardingData } from './data';

function StepManager({
  allSteps,
  children,
}: {
  children: (props: {
    handleCompleteStep: () => void;
    redirectToStepIndex: (StepIndex: number) => void;
    initialStep: OnboardingStep;
    currentStep: OnboardingStep;
    currentStepIndex: number;
    totalStepCount: number;
  }) => React.ReactNode;
  allSteps: OnboardingStep[];
}) {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const redirectTo = useRedirection();

  const initialStep = allSteps[0] as OnboardingStep;

  const currentStepIndex = allSteps.findIndex((stepName) => {
    return matchPath(location.pathname, {
      path: `${path}/${stepName}`,
      exact: false, // False so that it matches correct with deeper routes of a step
      strict: false,
    });
  });

  useEffect(() => {
    events.trackPage(); // Track page view
  }, [location.pathname]);

  useEffect(function ensureUserDoesNot_skipSteps() {
    // On page load, only users how are being redirected to /final/sign-up from Google SSO
    // redirection should be allowed to visit that route. Those users are not considered having
    // anonymous authentication by firebase anymore.
    if (
      location.pathname === `${path}/final/sign-up` &&
      firebaseAuth.currentUser?.isAnonymous === false
    )
      return;

    // On page load, Moving no anonymous users out of the Onboarding because that means they have
    // completed onboarded as now they are authenticated in a normal way
    if (firebaseAuth.currentUser?.isAnonymous === false) {
      history.replace('/');
      return;
    }

    if (allSteps[currentStepIndex] !== initialStep) {
      history.replace('/onboard');
    }
    // Disabling since history is not going to change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectToStepIndex = (stepIndex: number) => {
    if (!allSteps[stepIndex]) throw new Error(`Step number ${stepIndex + 1} does not exist`);

    redirectTo(`${path}/${allSteps[stepIndex]}`);
  };

  const handleCompleteStep = () => {
    const nextStepIndex =
      allSteps.findIndex((stepName) => {
        return matchPath(location.pathname, {
          path: `${path}/${stepName}`,
          exact: false, // False so that it matches correct with deeper routes of a step
          strict: false,
        });
      }) + 1;

    if (allSteps[nextStepIndex]) {
      redirectToStepIndex(nextStepIndex);
    } else {
      history.replace('/'); // Signup completed
    }
  };

  return children({
    handleCompleteStep,
    initialStep,
    redirectToStepIndex,
    currentStep: allSteps[currentStepIndex] as OnboardingStep,
    currentStepIndex,
    totalStepCount: allSteps.length,
  }) as React.ReactElement;
}

function OnboardingAuthHandler({ children }: { children: React.ReactNode }) {
  const auth = useCustomerAuth();

  const toast = useToast({
    position: 'top',
    duration: 10000,
    status: 'error',
  });

  useEffect(() => {
    if (auth.friendlyErrorMessage) {
      toast({
        description: auth.friendlyErrorMessage,
      });
    }
  }, [auth.friendlyErrorMessage, toast]);

  useEffect(() => {
    if (
      !auth.isAuthenticated &&
      // To prevent anonymous sign in loop when auth fails. Chose 3 attempts to leave some
      // room for grace when auth fails
      auth.attemptCount <= 3
    ) {
      signInAnonymously({ auth: firebaseAuth, dispatch: auth.dispatch });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated]);

  // To prevent anonymous sign in loop when auth fails. Chose 3 attempts to leave some
  // room for grace when auth fails
  if (auth.attemptCount >= 3) {
    throw auth.error || new Error('Auth failed 3 times while attempting anonymous sign in.');
  }

  if (!auth.isAuthenticated) {
    return <ActivityIndicator aria-label="Loading" />;
  }

  return children as React.ReactElement;
}

export function OnboardingPrivateController({
  handleCompleteStep,
  initialStep,
  currentStepIndex,
  totalStepCount,
  redirectToStepIndex,
}: {
  handleCompleteStep: () => void;
  redirectToStepIndex: (stepIndex: number) => void;
  initialStep: OnboardingStep;
  currentStepIndex: number;
  totalStepCount: number;
}) {
  const toast = useToast();
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const experiment = useExperiment();

  const { loading, data } = useQuery<SelectOnboardingDataQuery, SelectOnboardingDataQueryVariables>(
    SelectOnboardingData,
    {
      // When Google SSO redirects the user to onboarding/final/sign-up, we don't need to fetch this query
      skip: firebaseAuth.currentUser?.isAnonymous === false,
    },
  );

  if (loading) {
    return <ActivityIndicator aria-label="Loading" />;
  }

  const { roleOptions } = serializeOnboardingData(data);

  const missingDataHandler = ({ from, missingInfo }: { from: string; missingInfo: string }) => {
    // After removing some read permissions for the client_prospect table we need to avoid displaying this error for Google SSO users that come back to finalize their setup
    if (firebaseAuth.currentUser?.isAnonymous === false) return;

    toast({
      description: "We've lost some of your data. Please start over!",
      status: 'error',
      duration: 5000,
    });

    Sentry.captureMessage('User is missing data', {
      errorDetails: {
        missingInfo,
        from,
      },
    });

    redirectToStepIndex(0);
  };

  return (
    <Switch>
      <CustomRoute path={`${path}/company-name`}>
        <CompanyNameController
          onStepComplete={handleCompleteStep}
          stepInfo={{ totalStepCount, currentStepIndex }}
        />
      </CustomRoute>
      <CustomRoute path={`${path}/company-location`}>
        <CompanyLocationController
          onStepComplete={handleCompleteStep}
          stepInfo={{ totalStepCount, currentStepIndex }}
        />
      </CustomRoute>
      <CustomRoute path={`${path}/roles`}>
        <RolesController
          onStepComplete={handleCompleteStep}
          stepInfo={{ totalStepCount, currentStepIndex }}
          options={roleOptions}
        />
      </CustomRoute>
      <CustomRoute path={`${path}/final`}>
        <FinalController
          onStepComplete={handleCompleteStep}
          onMissingData={missingDataHandler}
          signupVariation={experiment.onboardSignUp}
        />
      </CustomRoute>
      <Route
        path={path}
        render={() => {
          return (
            <Redirect
              to={{
                pathname: `${url}/${initialStep}`,
                search: location.search,
              }}
            />
          );
        }}
      />
    </Switch>
  );
}

export function OnboardingController() {
  return (
    <OnboardingAuthHandler>
      <StepManager allSteps={['roles', 'company-location', 'company-name', 'final']}>
        {({
          handleCompleteStep,
          initialStep,
          totalStepCount,
          currentStepIndex,
          redirectToStepIndex,
        }) => (
          <OnboardingPrivateController
            {...{
              handleCompleteStep,
              initialStep,
              totalStepCount,
              currentStepIndex,
              redirectToStepIndex,
            }}
          />
        )}
      </StepManager>
    </OnboardingAuthHandler>
  );
}
