import moment from 'moment';
import type {
  MergedPostingAndPosition,
  AllStagesAllBuckets,
  CandidateStageInfo,
  MergedPostingAndPosition as MergedPostingAndPositionGeneric,
} from 'talent-hub/utils';
import {
  calculateRoleAggregatesByStageGraphs,
  capitalizeEachWord,
  createCandidatesStageGraph,
  createCandidateStagesInfo,
  mergePostingsAndPositions,
} from 'talent-hub/utils';
import { compareByObjectsDateAsc, to_friendly_years_of_experience_range } from 'global/utils';
import type {
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
  Employment_Type_Choices_Enum,
  Job_Status_Choices_Enum,
  Role_Choices_Enum,
} from 'global/types';
import { CandidateInterviewSubStageName } from 'talent-hub/constants';
import type { CandidateWorkflowStatus } from 'talent-hub/types';
import {
  serializeCandidateBrowse,
  sortOptionBackendValues,
} from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/ExploreCandidates.serializer';
import type { SelectDashboardBrowseCandidatesQuery } from 'talent-hub/role/prospect/dashboard/data';
import type { SelectDashboardQuery, DashboardPosting, DashboardPendingJob } from './data';
import type { SelectCandidateMatchesByRoleQuery } from './data/graphql/types/SelectCandidateMatchesByRole.query.generated';

export type PostingWithTotals = MergedPostingAndPosition<DashboardPosting> & {
  openHeadCount: number;
  closedHeadCount: number | null;
  totalCandidates: number;
  totalActiveCandidates: number;
  totalHired: number;
  candidates_to_review_per_role: CandidateToReviewInfoWithStatusInfo[];
  candidates_matches_query_args: {
    must_have_skill_ids: number[];
    regions: null;
    countries: string[] | null;
    sort_option: string;
    employment_type: Employment_Type_Choices_Enum | null;
    badges: null;
    candidate_role: Role_Choices_Enum | null;
    should_have_skill_ids: never[];
    min_years_of_experience: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum | null;
  };
};

export type PendingJob = {
  id: number;
  name: string;
  status: Job_Status_Choices_Enum;
  positions: number;
  yoe: string;
  topSkills: string;
  candidates_matches_query_args: {
    must_have_skill_ids: number[];
    regions: null;
    countries: string[] | null;
    sort_option: string;
    employment_type: Employment_Type_Choices_Enum | null;
    badges: null;
    candidate_role: Role_Choices_Enum | null;
    should_have_skill_ids: never[];
    min_years_of_experience: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum | null;
  };
};

type CandidatesToReviewStages = Pick<
  AllStagesAllBuckets<CandidateToReviewStagingInfo>,
  'submission' | 'interview'
>;

export function candidateFriendlyStatusInfo(status: string | undefined): {
  status: string;
  friendlyStatustext: string;
  linkText: string;
} {
  // Product decided to use Awaiting Your Review and the warning color as a default state because it's a generic enough status to apply to just about any situation
  if (!status) {
    return {
      friendlyStatustext: 'Awaiting Your Review',
      status: 'warning',
      linkText: 'View Candidate',
    };
  }

  switch (status) {
    case 'Submitted to Client - Submitted':
      return {
        friendlyStatustext: 'Awaiting Your Review',
        status: 'warning',
        linkText: 'Review Candidate',
      };

    case 'Client Review - Interview Completed':
    case 'Client Review - Awaiting Client Feedback':
    case 'Client Review - Final Interview Completed':
    case 'Client Review - Interview #1 - Initial Interview':
    case 'Client Review - Interview #2 - Technical Interview / Assessment':
    case 'Client Review - Interview #3 - Final interview':
    case 'Client Review - Interview #4 - Additional Interview':
    case 'Client Review - Interview #1':
    case 'Client Review - Interview #2':
    case 'Client Review - Interview #3':
    case 'Client Review - Interview #4':
      return {
        friendlyStatustext: 'Ready for Feedback',
        status: 'info',
        linkText: 'Leave Feedback',
      };
    case 'Client Review - Interview Scheduled':
    case 'Submitted to Client - Client Portal Advance':
    case 'Client Review - Interview to be Scheduled':
    case 'Client Review - Interview #1 to be Scheduled':
    case 'Client Review - Interview #2 to be Scheduled':
    case 'Client Review - Interview #3 to be Scheduled':
    case 'Client Review - Interview #4 to be Scheduled':
      return {
        friendlyStatustext: 'Interview Scheduled',
        status: 'info',
        linkText: 'Review Candidate',
      };

    default:
      return {
        friendlyStatustext: 'Awaiting Your Review',
        status: 'warning',
        linkText: 'View Candidate',
      };
  }
}

function groupCandidatesToDashboardCandidatesToReview({
  submission,
  interview,
}: CandidatesToReviewStages): {
  upcomingSubmissionCandidates: CandidateToReviewInfoWithStatusInfo[];
  upcomingInterviewCandidates: CandidateToReviewInfoWithStatusInfo[];
  interviewsToBeScheduledCandidates: CandidateToReviewInfoWithStatusInfo[];
} {
  const pickProfileData = (candidateProfile: CandidateToReviewStagingInfo) => {
    const { friendlyStatustext, status, linkText } = candidateFriendlyStatusInfo(
      candidateProfile.candidateToReviewInfo.statusText,
    );
    return {
      ...candidateProfile.candidateToReviewInfo,
      statusText: friendlyStatustext,
      status,
      linkText,
    };
  };
  const upcomingInterviewCandidatesUnMapped: CandidateToReviewStagingInfo[] =
    interview.inProgress.buckets[CandidateInterviewSubStageName.Scheduled] || [];

  const interviewsToBeScheduledCandidatesUnMapped: CandidateToReviewStagingInfo[] =
    interview.inProgress.buckets[CandidateInterviewSubStageName.ToBeScheduled] || [];
  return {
    upcomingSubmissionCandidates: submission.inProgress.all
      .map(pickProfileData)
      .sort(compareByObjectsDateAsc),
    upcomingInterviewCandidates: upcomingInterviewCandidatesUnMapped
      .map(pickProfileData)
      .sort(compareByObjectsDateAsc),
    interviewsToBeScheduledCandidates: interviewsToBeScheduledCandidatesUnMapped
      .map(pickProfileData)
      .sort(compareByObjectsDateAsc),
  };
}

export type CandidateToReviewInfo = {
  jobID?: number | null;
  id?: number | null;
  name: string;
  statusText?: string;
  date?: string;
  role: string;
};

export type CandidateToReviewInfoWithStatusInfo = CandidateToReviewInfo & {
  status: string;
  linkText: string;
};

export type CandidateToReviewStagingInfo = {
  candidateToReviewInfo: CandidateToReviewInfo;
} & CandidateStageInfo;

type MergedPostingAndPositions = MergedPostingAndPositionGeneric<DashboardPosting>[];

type DashboardApplicantWorkflow = MergedPostingAndPositions[number]['applicant_workflows'][number];

function transformToCandidatesWithPartialStageInfo(
  applicantWorkFlows: DashboardApplicantWorkflow[],
  postingName: MergedPostingAndPosition<DashboardPosting>['name'],
): CandidateToReviewStagingInfo[] {
  return applicantWorkFlows.map(({ job_profile_id, icims_person, workflow_statuses }) => {
    const statuses = workflow_statuses.map(
      ({ date, status }): CandidateWorkflowStatus => ({
        date,
        title: status?.title || '',
      }),
    );

    const { currentStatus, ...rest } = createCandidateStagesInfo(statuses);

    return {
      ...rest,
      currentStatus,
      candidateToReviewInfo: {
        jobID: job_profile_id,
        id: icims_person?.profile_id,
        name: `${capitalizeEachWord(
          icims_person?.candidate?.firstname || icims_person?.firstname,
        )} ${capitalizeEachWord(icims_person?.candidate?.lastname || icims_person?.lastname)}`,
        statusText: currentStatus?.title,
        date: moment(currentStatus?.date).format('MMM D, YYYY'),
        role: postingName || '-',
      },
    };
  });
}

function augmentPostingWithTotals(
  postings: MergedPostingAndPosition<DashboardPosting>[],
): PostingWithTotals[] {
  return postings.map((posting) => {
    const stagedCandidates = transformToCandidatesWithPartialStageInfo(
      posting.applicant_workflows,
      posting.name,
    );
    const candidateStageGraph = createCandidatesStageGraph(stagedCandidates);

    const {
      upcomingSubmissionCandidates,
      upcomingInterviewCandidates,
      interviewsToBeScheduledCandidates,
    } = groupCandidatesToDashboardCandidatesToReview({
      submission: candidateStageGraph.submission,
      interview: candidateStageGraph.interview,
    });

    const {
      totalCandidates: total_submitted_candidates,
      totalActiveCandidates,
      totalHired,
    } = calculateRoleAggregatesByStageGraphs(candidateStageGraph);

    const exploreCandidateArgs_countries = posting.job?.job_acceptable_locations
      ?.map(({ location: { country } }) => country)
      .filter((country) => country !== null);

    return {
      ...posting,
      totalCandidates: total_submitted_candidates,
      totalActiveCandidates,
      totalHired,
      candidates_to_review_per_role: upcomingSubmissionCandidates.concat(
        upcomingInterviewCandidates,
        interviewsToBeScheduledCandidates,
      ),
      candidates_matches_query_args: {
        must_have_skill_ids:
          posting.job?.job_required_skills?.map(({ skill_id }) => skill_id) || [],
        regions: null,
        countries: exploreCandidateArgs_countries?.length ? exploreCandidateArgs_countries : null,
        sort_option: sortOptionBackendValues.Recommended,
        employment_type: posting.job?.employment_type || null,
        badges: null,
        candidate_role: posting.job?.role_type || null,
        should_have_skill_ids: [],
        min_years_of_experience: posting.job?.min_years_of_experience || null,
      },
    };
  });
}

function augmentPendingJob(jobs: DashboardPendingJob[]): PendingJob[] {
  return jobs.map((job) => {
    const exploreCandidateArgs_countries = job?.job_acceptable_locations
      ?.map(({ location: { country } }) => country)
      .filter((country) => country !== null);
    return {
      id: job.id,
      name: job.title || '-',
      status: job.status,
      positions: job.job_positions_aggregate.aggregate?.count || 0,
      yoe: to_friendly_years_of_experience_range(job.min_years_of_experience) || '-',
      topSkills: job.job_required_skills.map<string>(({ skill }) => skill.name)?.join(', ') || '-',
      candidates_matches_query_args: {
        must_have_skill_ids: job?.job_required_skills?.map(({ skill_id }) => skill_id) || [],
        regions: null,
        countries: exploreCandidateArgs_countries?.length ? exploreCandidateArgs_countries : null,
        sort_option: sortOptionBackendValues.Recommended,
        employment_type: job?.employment_type || null,
        badges: null,
        candidate_role: job?.role_type || null,
        should_have_skill_ids: [],
        min_years_of_experience: job?.min_years_of_experience || null,
      },
    };
  });
}

export function serializeDashboard({
  data,
  bookmarkedRoleIDs,
}: {
  data?: SelectDashboardQuery;
  bookmarkedRoleIDs: number[];
}): {
  totalSubmittedCandidates: number;
  totalActiveMembers: number;
  totalOpenRoles: number;
  totalOpenPositions: number;
  totalActiveCandidates: number;
  hasMoreRoles: boolean;
  roles: {
    active: PostingWithTotals[];
    pending: PendingJob[];
  };
} {
  if (!data) {
    return {
      totalSubmittedCandidates: 0,
      totalActiveMembers: 0,
      totalOpenRoles: 0,
      totalOpenPositions: 0,
      totalActiveCandidates: 0,
      hasMoreRoles: false,
      roles: {
        active: [],
        pending: [],
      },
    };
  }

  const postings = mergePostingsAndPositions(data.active_postings);
  const augmentedPostings = augmentPostingWithTotals(postings);

  const { roleTotalActiveCandidates, roleTotalOpenHeadCount, roleTotalCandidateSubmissions } =
    augmentedPostings.reduce(
      (acc, augmentedPosting) => {
        return {
          roleTotalActiveCandidates:
            acc.roleTotalActiveCandidates + augmentedPosting.totalActiveCandidates,
          roleTotalOpenHeadCount: acc.roleTotalOpenHeadCount + augmentedPosting.openHeadCount,
          roleTotalCandidateSubmissions:
            acc.roleTotalCandidateSubmissions + augmentedPosting.totalCandidates,
        };
      },
      {
        roleTotalActiveCandidates: 0,
        roleTotalOpenHeadCount: 0,
        roleTotalCandidateSubmissions: 0,
      },
    );

  const sorted_active_roles = augmentedPostings.sort(
    (a, b) => Number(bookmarkedRoleIDs.includes(b.id)) - Number(bookmarkedRoleIDs.includes(a.id)),
  );

  const pendingRoles = augmentPendingJob(data.job);

  const selectedActiveRolesToShow = sorted_active_roles.slice(0, 3);
  const selectedPendingRolesToShow = pendingRoles.slice(0, 3 - selectedActiveRolesToShow.length);

  return {
    totalSubmittedCandidates: roleTotalCandidateSubmissions,
    totalActiveMembers: data.members?.aggregate?.count || 0,
    totalOpenRoles: sorted_active_roles.length,
    totalOpenPositions: roleTotalOpenHeadCount,
    totalActiveCandidates: roleTotalActiveCandidates,
    hasMoreRoles: sorted_active_roles.length + pendingRoles.length > 3,
    roles: {
      active: selectedActiveRolesToShow,
      pending: selectedPendingRolesToShow,
    },
  };
}

// TODO: Carlos - Fix types
export function serializeDashboardOtherGreatCandidates(
  data: Omit<SelectDashboardBrowseCandidatesQuery, 'location'>,
) {
  if (!data) {
    return {
      recentlyActive: [],
      topCandidateExperience: [],
      zeroOneExperience: [],
      techLeadExperience: [],
    };
  }
  const {
    candidate_browse_recently_active,
    candidate_top_company_experience,
    candidate_zero_one_experience,
    candidate_tech_lead,
  } = data;

  const { candidates: recentlyActive } = serializeCandidateBrowse({
    data: candidate_browse_recently_active
      ? {
          candidate_browse: candidate_browse_recently_active,
        }
      : undefined,
    isSortByRecommended: false,
    isLoadingCandidates: false,
    highlightBadgeType: null,
  });
  const { candidates: topCandidateExperience } = serializeCandidateBrowse({
    data: candidate_top_company_experience
      ? {
          candidate_browse: candidate_top_company_experience,
        }
      : undefined,

    isSortByRecommended: false,
    isLoadingCandidates: false,
    highlightBadgeType: null,
  });
  const { candidates: zeroOneExperience } = serializeCandidateBrowse({
    data: candidate_zero_one_experience
      ? {
          candidate_browse: candidate_zero_one_experience,
        }
      : undefined,
    isSortByRecommended: false,
    isLoadingCandidates: false,
    highlightBadgeType: null,
  });
  const { candidates: techLeadExperience } = serializeCandidateBrowse({
    data: candidate_tech_lead
      ? {
          candidate_browse: candidate_tech_lead,
        }
      : undefined,
    isSortByRecommended: false,
    isLoadingCandidates: false,
    highlightBadgeType: null,
  });

  return {
    recentlyActive,
    topCandidateExperience,
    zeroOneExperience,
    techLeadExperience,
  };
}

function createExploreCandidatesURL(
  candidates_matches_query_args: PostingWithTotals['candidates_matches_query_args'],
): string {
  const {
    candidate_role,
    min_years_of_experience,
    sort_option,
    employment_type,
    countries,
    must_have_skill_ids,
  } = candidates_matches_query_args;

  const queryParams: string[] = [];

  if (candidate_role) {
    queryParams.push(`role=${encodeURIComponent(candidate_role)}`);
  }

  if (min_years_of_experience) {
    queryParams.push(`yoe=${encodeURIComponent(min_years_of_experience)}`);
  }

  if (sort_option) {
    queryParams.push(`sort=${encodeURIComponent(sort_option)}`);
  }

  if (employment_type) {
    queryParams.push(`emp_type=${encodeURIComponent(employment_type)}`);
  }

  if (countries && countries.length > 0) {
    queryParams.push(`countries=${encodeURIComponent(countries.join(','))}`);
  }

  if (must_have_skill_ids && must_have_skill_ids.length > 0) {
    queryParams.push(`skills=${encodeURIComponent(must_have_skill_ids.join(','))}`);
  }

  return `/explore-candidates?${queryParams.join('&')}`;
}

export function serializeCandidateMatchesByRole({
  data,
  allRoles,
}: {
  data: SelectCandidateMatchesByRoleQuery | undefined;
  allRoles: (PostingWithTotals | PendingJob)[];
}) {
  if (!allRoles.length) {
    return {};
  }

  return allRoles.reduce((acc, currentRole, i) => {
    const candidate_browse =
      data?.[`candidate_matches_role${i + 1}` as keyof SelectCandidateMatchesByRoleQuery];
    const { candidates } = serializeCandidateBrowse({
      data:
        candidate_browse != null
          ? {
              candidate_browse,
            }
          : undefined,
      isSortByRecommended: false,
      isLoadingCandidates: false,
      highlightBadgeType: null,
      skillsToSearch: currentRole.candidates_matches_query_args.must_have_skill_ids,
    });
    return {
      ...acc,
      [currentRole.id]: {
        candidates,
        link: createExploreCandidatesURL(currentRole.candidates_matches_query_args),
      },
    };
  }, {});
}
