import { useEffect } from 'react';
import { BlankScreenLoading } from 'global/components';
import { useCustomFormik } from 'global/utils/useCustomFormik';
import { useSignUp, googleSignIn, createPasswordYupValidation } from 'global/auth';
import { validators } from 'global/utils';
import * as Yup from 'yup';
import { firebaseAuth } from 'global/firebaseApp';
import Sentry from 'global/sentry';
import { useMutation } from '@apollo/client';
import type { ApolloError } from '@apollo/client';
import { useCustomerAuth, triggerGoal } from 'talent-hub/utils';
import type { Experiment } from 'talent-hub/utils';
import { UpsertLastStepCompleted } from '../../data/graphql';
import type {
  UpsertLastStepCompletedMutationVariables,
  UpsertLastStepCompletedMutation,
} from '../../data/graphql';
import { SignUp } from './SignUp';
import type { OnboardingState } from '../../../Prospect.types';

const signUpValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please make sure your email address is valid')
    .test(
      'Allowed domains',
      'Please make sure your are using your company email',
      (value) => !/gmail|yahoo|outlook|hotmail/.test(value || ''),
    )
    .required('Please include an email address.'),
  fullName: Yup.string()
    .transform((value) => value?.trim() || '')
    .matches(validators.fullName.regex, validators.fullName.message)
    .required('Please include your full name.'),
  password: createPasswordYupValidation(),
});

const SignUpVariationMap: Record<
  Experiment['onboardSignUp'],
  typeof SignUp.Variation.MatchingCandidates | typeof SignUp.Variation.BlurredDashboard
> = {
  'matching-candidates': SignUp.Variation.MatchingCandidates,
  'blurred-dashboard': SignUp.Variation.BlurredDashboard,
};

export function SignUpController({
  onStepComplete,
  continueURL,
  roles,
  hasMatches,
  signupVariation,
}: {
  onStepComplete: () => void;
  continueURL: string;
  roles: OnboardingState['roles'];
  hasMatches: boolean;
  signupVariation: Experiment['onboardSignUp'];
}) {
  const auth = useCustomerAuth();
  const SignUpVariation =
    SignUpVariationMap[signupVariation] || SignUpVariationMap['matching-candidates'];

  const [upsertLastStepCompleted] = useMutation<
    UpsertLastStepCompletedMutation,
    UpsertLastStepCompletedMutationVariables
  >(UpsertLastStepCompleted, {
    onError: (error: ApolloError) => {
      Sentry.captureException(error);
    },
  });

  const hanldeStepComplete = () => {
    triggerGoal({ goalID: 1, campaignID: 82 });
    upsertLastStepCompleted({
      variables: {
        userId: auth.user?.id as number,
        lastStepCompleted: 'Signup',
      },
    });
    onStepComplete();
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!firebaseAuth.currentUser?.isAnonymous) {
      if (
        // To work around a race issue where user gets authorized before the
        // createAuth..putUser function is resolved. Since non-anonymous user
        // will always have first_name, we can wait until first_name is in auth user
        auth.user?.first_name
      ) {
        hanldeStepComplete();
      } else {
        // Handling the edge where user main now have the first_name. we don't want the user
        // get stock on seeing the loading bar if for one reason or other they don't have first_name
        timer = setTimeout(() => {
          hanldeStepComplete();
        }, 4000);
      }
    }

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseAuth.currentUser?.isAnonymous, auth?.user?.first_name]);

  const {
    handleSignUpSubmit,
    isLoading,
    isResolved: signUpIsResolved,
  } = useSignUp({
    continueURL,
    contextRole: 'authenticated',
  });

  const formik = useCustomFormik<{ email: string; fullName: string; password: string }>({
    initialValues: {
      email: '',
      fullName: '',
      password: '',
    },
    validationSchema: signUpValidationSchema,
    onSubmit: ({ email, fullName, password }) => {
      handleSignUpSubmit({ email, fullName, password });
    },
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  if (signUpIsResolved || !firebaseAuth.currentUser?.isAnonymous) {
    return <BlankScreenLoading aria-label="Loading" />;
  }

  return (
    <SignUpVariation
      isLoading={isLoading}
      shouldHideGoogleSSO
      values={formik.values}
      errors={formik.errors}
      touched={formik.touched}
      submitCount={formik.submitCount}
      onSubmit={formik.handleSubmit}
      handleBlur={formik.handleBlur}
      handleChange={formik.handleChange}
      onGoogleLoginClicked={() => googleSignIn({ auth: firebaseAuth, dispatch: auth.dispatch })}
      highlightedRole={{
        roleName: roles?.[0]?.roleDisplayName || '',
        experienceDescription: roles?.[0]?.experienceDescription || '',
        skills: roles?.[0]?.skillNames || [],
      }}
      hasMatches={hasMatches}
    />
  );
}
