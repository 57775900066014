import { createExperimentProvider } from 'global/utils/experiment';

export type Experiment = {
  onboardSignUp: 'matching-candidates' | 'blurred-dashboard';
};

const defaultExperiment: Experiment = {
  onboardSignUp: 'matching-candidates',
};

export const { ExperimentProvider, useExperiment, triggerExperiments, triggerGoal } =
  createExperimentProvider<Experiment>({
    defaultValue: defaultExperiment,
  });
