import { useEffect, useMemo, useRef } from 'react';
import { NetworkStatus, useMutation } from '@apollo/client';
import { Box, useToast } from '@terminal/design-system';
import Sentry from 'global/sentry';
import type { Candidate_Curation_Years_Of_Exp_Range_Choices_Enum } from 'global/types';
import { Client_Prospect_Events_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import { useQuery, useQueryString } from 'global/utils';
import { useCustomerAuthorizedUserSession, useTalentHubFlags } from 'talent-hub/utils';
import {
  ExploreCandidates,
  useExploreCandidatesController,
  candidateLimit,
  candidateSearchDateRange,
} from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates';
import { useParams, useHistory } from 'react-router-dom';
import type {
  SelectBrowseCandidatesQuery,
  SelectBrowseCandidatesQueryVariables,
} from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/data';
import { SelectBrowseCandidates } from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/data';
import {
  serializeCandidateBrowse,
  sortOptionBackendValues,
} from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/ExploreCandidates.serializer';
import { ViewType } from 'talent-hub/shared/features/explore-candidates';
import { useCalendlyWidget } from '../../components/CalendlyWidget';
import { CandidateProfileController } from '../candidate-profile';
import { InsertClientProspectEvents } from '../../dashboard/data';
import type {
  InsertClientProspectEventsMutation,
  InsertClientProspectEventsMutationVariables,
} from '../../dashboard/data';
import {
  serializeOnboardingMostMatchingRole,
  serializeFiltersFromParams,
} from './ExploreCandidates.serializer';

function useMostMatchingRoleDataOnce() {
  const dataStringRef = useRef(localStorage.getItem('onboarding_mostMatchingRole'));
  localStorage.removeItem('onboarding_mostMatchingRole');

  return {
    dataString: dataStringRef.current,
    clearData: () => {
      dataStringRef.current = null;
    },
  };
}

// TODO:  - Maybe some variable names could be improved, but i tried to change less possible
// TODO: Rename this to BrowseCandidatesController
export function ExploreCandidatesController({ isInLimitedMode }: { isInLimitedMode?: boolean }) {
  const { candidateID } = useParams<{ candidateID?: string }>();
  const history = useHistory();
  const queryString = useQueryString();
  const { queryArgs, initialValues } = serializeFiltersFromParams({
    role: queryString.get('role'),
    yoe: queryString.get('yoe'),
    badges: queryString.get('badges'),
    sort: queryString.get('sort'),
  });
  const {
    isClientReviewer,
    isRecruiter,
    user,
    viewingOrganization,
    isClientProspect,
    userPrioritizedRole,
  } = useCustomerAuthorizedUserSession();
  const calendlyModal = useCalendlyWidget();
  const { topCandidatesCriteria } = useTalentHubFlags();

  const { dataString, clearData: clearData_mostMatchingRole } = useMostMatchingRoleDataOnce();
  const { queryArgs: queryArgsFromLocalStorage, initialValues: initialValuesFromLocalStorage } =
    serializeOnboardingMostMatchingRole({
      dataString,
      userID: user?.id,
      onError: (err) => {
        Sentry.captureException(err);
      },
    });

  const browseCandidatesQuery_defaultVariables = useMemo(
    () => ({
      limit: candidateLimit,
      offset: 0,
      candidate_browse_args: {
        organization_id: viewingOrganization.ID,
        candidate_role: null,
        must_have_skill_ids: [],
        should_have_skill_ids: [],
        regions: null,
        min_years_of_experience: null,
        sort_option: sortOptionBackendValues.Recommended,
        top_candidates_criteria: topCandidatesCriteria,
        employment_type: null,
        badges: null,
      },
      organization: viewingOrganization.ID,
      user_id: user?.id,
      updatedAfter: candidateSearchDateRange,
      isClientProspect,
    }),
    [isClientProspect, topCandidatesCriteria, user?.id, viewingOrganization.ID],
  );

  const {
    loading: loadingCandidates,
    data,
    refetch,
    fetchMore,
    networkStatus,
    client,
    variables,
  } = useQuery<SelectBrowseCandidatesQuery, SelectBrowseCandidatesQueryVariables>(
    SelectBrowseCandidates,
    {
      variables: {
        ...browseCandidatesQuery_defaultVariables,
        candidate_browse_args: {
          ...browseCandidatesQuery_defaultVariables.candidate_browse_args,
          ...queryArgsFromLocalStorage,
          ...queryArgs,
        },
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const isFilterSelected =
    /* eslint-disable eqeqeq */
    browseCandidatesQuery_defaultVariables.candidate_browse_args.candidate_role !=
      variables?.candidate_browse_args.candidate_role ||
    browseCandidatesQuery_defaultVariables.candidate_browse_args.min_years_of_experience !=
      variables?.candidate_browse_args.min_years_of_experience ||
    browseCandidatesQuery_defaultVariables.candidate_browse_args.employment_type !=
      variables?.candidate_browse_args.employment_type ||
    browseCandidatesQuery_defaultVariables.candidate_browse_args.regions !=
      variables?.candidate_browse_args.regions ||
    browseCandidatesQuery_defaultVariables.candidate_browse_args.badges !=
      variables?.candidate_browse_args.badges ||
    browseCandidatesQuery_defaultVariables.candidate_browse_args.must_have_skill_ids?.length !=
      variables?.candidate_browse_args.must_have_skill_ids?.length ||
    browseCandidatesQuery_defaultVariables.candidate_browse_args.should_have_skill_ids?.length !=
      variables?.candidate_browse_args.should_have_skill_ids?.length;
  /* eslint-enable */

  const {
    exploreCandidates: {
      onCloseCandidateProfileClick,
      exploreCandidatesOptions,
      refetchCandidates,
      setInfiniteScrollRef,
      searches,
      filterOption: {
        roles,
        skills,
        locations,
        yearsOfExperience,
        sorting,
        employmentType,
        candidateHighlights,
      },
    },
    saveCandidate: {
      savedByMeCandidatesCount,
      sharedWithMeCandidatesCount,
      handleOnRemoveSaveCandidate,
      handleOnSaveCandidate,
    },
  } = useExploreCandidatesController({
    browseCandidates: {
      loadingCandidates,
      refetch,
      fetchMore,
      networkStatus,
      client,
      data,
      defaultVariables: browseCandidatesQuery_defaultVariables,
    },
  });

  const selectedYoE = variables?.candidate_browse_args
    .min_years_of_experience as Candidate_Curation_Years_Of_Exp_Range_Choices_Enum;
  const skillsToSearch = variables?.candidate_browse_args.must_have_skill_ids;

  // ! Refactor the serializers to be smallers, to be able to compose each one using small serializers ! warning: Easly to create early abstractions
  const {
    candidates,
    otherCandidates,
    highlightedCandidates,
    highlightedCandidatesTitle,
    highlightedCandidatesSubtitle,
  } = serializeCandidateBrowse({
    data,
    skillsToSearch,
    selectedYoE,
    highlightBadgeType: topCandidatesCriteria,
    isFilterSelected,
    isSortByRecommended:
      browseCandidatesQuery_defaultVariables.candidate_browse_args.sort_option ===
      variables?.candidate_browse_args.sort_option,
    isLoadingCandidates: networkStatus === NetworkStatus.refetch ? false : loadingCandidates,
  });

  // #region onboarding steps logic
  const toast = useToast({
    position: 'bottom-left',
    duration: 4000,
  });
  const [insertProspectEvent] = useMutation<
    InsertClientProspectEventsMutation,
    InsertClientProspectEventsMutationVariables
  >(InsertClientProspectEvents, {
    onError: (error) => {
      toast({
        description: 'Something went wrong trying to save the step info. Please try again!',
        status: 'error',
      });

      Sentry.captureException(error);
    },
  });

  useEffect(() => {
    insertProspectEvent({
      variables: {
        user_id: user?.id as number,
        event_choice: Client_Prospect_Events_Choices_Enum.EnterBrowseCandidates,
      },
    });
  }, [insertProspectEvent, user?.id]);
  // #endregion

  return (
    <>
      <ExploreCandidates
        filterOptions={{
          roles,
          skills,
          isLoading: exploreCandidatesOptions.loading,
          locations,
          yearsOfExperience,
          sorting,
          employmentType,
          candidateHighlights,
        }}
        searches={searches}
        // When we do a refetch after deleting/adding a favorite candidate, we dont want to show the loading state again
        loadingCandidates={networkStatus === NetworkStatus.refetch ? false : loadingCandidates}
        networkStatus={networkStatus}
        pageLayoutProps={{
          isClientReviewer,
          isRecruiter,
          orgName: viewingOrganization.name,
          user,
          isClientProspect,
          userPrioritizedRole,
          isCallScheduled: calendlyModal.isCallScheduled,
          onUpgradeAccountClick: calendlyModal.isCallScheduled ? undefined : calendlyModal.onOpen,
        }}
        refetchCandidates={refetchCandidates}
        candidates={candidates}
        quantityResults={data?.candidate_browse?.[0]?.total_count || 0}
        savedByMeCandidatesCount={savedByMeCandidatesCount}
        sharedWithMeCandidatesCount={sharedWithMeCandidatesCount}
        handleOnRemoveSaveCandidate={handleOnRemoveSaveCandidate}
        handleOnSaveCandidate={handleOnSaveCandidate}
        sharedCandidateWithProps={{
          // * not required data, since isInLimitedMode
          teamMembers: undefined,
          handleInsertSharedWith: () => {},
          isShareWithModalOpen: false,
          onOpenShareWithModal: calendlyModal.onOpen,
          onCloseShareWithModal: () => {},
        }}
        isInLimitedMode={isInLimitedMode}
        initialValues={initialValues || initialValuesFromLocalStorage}
        onBeforeClearAllFilters={() => {
          clearData_mostMatchingRole();
          history.replace({ pathname: '/explore-candidates', search: '' });
        }}
        selectedYoE={selectedYoE}
        otherCandidates={otherCandidates}
        highlightedCandidates={highlightedCandidates}
        highlightedCandidatesTitle={highlightedCandidatesTitle}
        highlightedCandidatesSubtitle={highlightedCandidatesSubtitle}
        isFilterSelected={isFilterSelected}
      />
      {/* Div used as target to observe in infinite scroll feature */}
      {candidates?.length && <Box ref={setInfiniteScrollRef} height={4} />}
      <CandidateProfileController
        candidateID={Number(candidateID)}
        onCloseCandidateProfileClick={onCloseCandidateProfileClick}
        handleOnRemoveSaveCandidate={handleOnRemoveSaveCandidate}
        handleOnSaveCandidate={handleOnSaveCandidate}
        isInLimitedMode={isInLimitedMode}
        onOpenBookCallModal={calendlyModal.onOpen}
        viewPage={ViewType.SEARCH}
      />
    </>
  );
}
