import React, { useEffect } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';

import { AuthenticationRouter } from 'global/auth';
import * as events from 'global/events';
import { ErrorBoundary } from 'global/sentry';
import { useQueryString } from 'global/utils';
import { Helmet } from 'react-helmet';

import { CustomRoute, ActivityIndicator, SentryTest } from 'global/components';
import {
  useCustomerAuthorizedUserSession,
  CustomerAuthorizedUserSessionProvider,
  useCustomerAuth,
} from 'talent-hub/utils';
import { ErrorFallback } from 'talent-hub/components';
import { ProspectRouter } from 'talent-hub/role/prospect';
import { OnboardingController } from 'talent-hub/role/prospect/onboarding';

import { RecruiterRouter } from 'talent-hub/role/recruiter';
import { ClientRouter } from 'talent-hub/role/client';
import { ClientReviewerRouter } from 'talent-hub/role/client-reviewer';
import { SignUpController } from 'talent-hub/role/prospect/trial-signup/SignUp.controller';
import { ExploreCandidatesRedirectController } from 'talent-hub/role/prospect/explore-candidates-redirect';
import { CandidatePublicProfileRouter } from 'talent-hub/role/prospect/candidate-profile';

import womenSVG from './women-at-the-computer.jpg';
import { customerAuthPageTemplateOverwrite } from './components/CustomerAuthPages/CustomerAuthPages';

function CustomerAppRouter() {
  const history = useHistory();
  const { userPrioritizedRole, isRecruiter, setViewingOrg } = useCustomerAuthorizedUserSession();
  // Store a reference to the pathanme to track page changes
  // Initialize history and dispatch

  // Track page view
  const { pathname } = useLocation();

  useEffect(() => {
    events.trackPage();
  }, [pathname]);

  /** To enable Recruiters to switch by deep-link to other organizations */
  const querystring = useQueryString();
  useEffect(() => {
    if (!isRecruiter) return;
    const queryOrg = Number(querystring.get('org'));
    if (!queryOrg || Number.isNaN(queryOrg)) return;
    setViewingOrg(queryOrg);
  }, [isRecruiter, querystring, setViewingOrg]);

  useEffect(() => {
    const redirectTo = localStorage.getItem('redirect_to');

    if (redirectTo) {
      localStorage.removeItem('redirect_to');

      history.push(redirectTo);
    }
    // Disabling because of history
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (userPrioritizedRole === 'client') return <ClientRouter />;
  if (userPrioritizedRole === 'recruiter') return <RecruiterRouter />;
  if (userPrioritizedRole === 'client-reviewer') return <ClientReviewerRouter />;
  if (userPrioritizedRole === 'client-prospect') return <ProspectRouter />;
  return null;
}

export function AppRouter() {
  const auth = useCustomerAuth();
  const { pathname } = useLocation();

  // ! What to do if a firebase session fails to load?
  if (!auth.isResolved && !auth.isError) {
    return <ActivityIndicator type="simple-spinner" description="Initializing your profile..." />;
  }

  return (
    <ErrorBoundary
      fallback={
        <ErrorFallback
          onActionClick={() => {
            window.location.reload();
          }}
          showBackButton
        />
      }
    >
      <Switch>
        <Route path="/auth">
          <React.StrictMode>
            <AuthenticationRouter
              {...auth}
              overwrite={{
                pageTemplate: customerAuthPageTemplateOverwrite,
              }}
              skipEmailVerify={auth.user?.skip_email_verify === true}
            />
          </React.StrictMode>
        </Route>
        <CustomRoute
          path={['/onboard', '/onboarding']}
          conditionalRedirects={[
            { condition: pathname.includes('/onboarding'), redirectURL: '/onboard' },
          ]}
        >
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />

            <title>Terminal Talent Hub - Browse Talent For Free Today!</title>
            <meta
              name="description"
              content="Instant access to a global pool of top engineers seeking full-time & contract roles. Hire and employ vetted remote developers that cost up to 40% less than local talent. Get started today for free."
            />

            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://hub.terminal.io/onboard" />
            <meta
              property="og:title"
              content="Terminal Talent Hub - Browse Talent For Free Today!"
            />
            <meta
              property="og:description"
              content="Instant access to a global pool of top engineers seeking full-time & contract roles. Hire and employ vetted remote developers that cost up to 40% less than local talent. Get started today for free."
            />
            <meta property="og:image" content={womenSVG} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:domain" content="hub.terminal.io" />
            <meta property="twitter:url" content="https://hub.terminal.io/onboard" />
            <meta
              name="twitter:title"
              content="Terminal Talent Hub - Browse Talent For Free Today!"
            />
            <meta
              name="twitter:description"
              content="Instant access to a global pool of top engineers seeking full-time & contract roles. Hire and employ vetted remote developers that cost up to 40% less than local talent. Get started today for free."
            />
            <meta name="twitter:image" content={womenSVG} />
          </Helmet>
          <OnboardingController />
        </CustomRoute>
        <CustomRoute path="/trial">
          <SignUpController />
        </CustomRoute>
        {!auth.isAuthorized && (
          <CustomRoute path="/explore-candidates">
            <ExploreCandidatesRedirectController />
          </CustomRoute>
        )}
        <CustomRoute path="/profile">
          <CandidatePublicProfileRouter />
        </CustomRoute>
        <CustomRoute path="/sentry-test">
          <SentryTest />
        </CustomRoute>
        <CustomRoute
          path="/"
          conditionalRedirects={[{ condition: !auth.isAuthorized, redirectURL: '/auth' }]}
        >
          <React.StrictMode>
            <CustomerAuthorizedUserSessionProvider>
              <CustomerAppRouter />
            </CustomerAuthorizedUserSessionProvider>
          </React.StrictMode>
        </CustomRoute>
      </Switch>
    </ErrorBoundary>
  );
}
