import { type ComponentProps } from 'react';
import { compareByDateAsc, to_friendly_years_of_experience_range } from 'global/utils';
import Sentry from 'global/sentry';
import { Job_Status_Choices_Enum } from 'global/types';

import {
  calculateRoleAggregatesByStageGraphs,
  createCandidatesStageGraph,
  createCandidateStagesInfo,
  determineRoleHealth,
  find_stage_by_status as find_candidate_stage,
  hasCandidateHitStage,
  mergePostingsAndPositions,
} from 'talent-hub/utils';

import type { MergedPostingAndPosition } from 'talent-hub/utils';
import { workflowStatuses_ToCandidateWorkflowStatus } from 'talent-hub/utils/candidate/serializer.utils';
import type { JobHealthStatus } from 'talent-hub/constants';
import moment from 'moment';
import type { ManageRolesQuery, OverviewPosting, OverviewFilledPosting } from './data';

import { type ManageRolesDashboard } from './ManageRolesDashboard';

export type PostingWithTotalsAndHealthStatus = MergedPostingAndPosition<OverviewPosting> & {
  openHeadCount: number;
  closedHeadCount: number | null;
  totalCandidates: number;
  totalActiveCandidates: number;
  totalHired: number;
  healthStatus: JobHealthStatus;
};

function augment_posting_with_totals_and_health_status(
  postings: MergedPostingAndPosition<OverviewPosting>[],
): PostingWithTotalsAndHealthStatus[] {
  return postings.map((posting) => {
    const stagedCandidates = posting.applicant_workflows
      .map(({ workflow_statuses }) => {
        return workflow_statuses.map(workflowStatuses_ToCandidateWorkflowStatus);
      })
      // Removes the candidate that has any of the statuses in the Ignored bucket
      .filter((statuses) => !hasCandidateHitStage('Ignored', statuses))
      .map(createCandidateStagesInfo);

    const candidateStageGraph = createCandidatesStageGraph(stagedCandidates);

    const { totalCandidates, totalActiveCandidates, totalHired } =
      calculateRoleAggregatesByStageGraphs(candidateStageGraph);

    return {
      ...posting,
      openedDate: moment(posting.openedDate).format('MMM D, YYYY'),
      totalCandidates,
      totalActiveCandidates,
      totalHired,
      healthStatus: determineRoleHealth({
        totalHired,
        totalPositions: posting.openHeadCount,
        totalActiveCandidates,
        totalSubmissions: totalCandidates,
        roleCreateDate: posting.openedDate,
        jobStatus: posting.job?.status || null,
      }),
    };
  });
}

export type FilledRole = {
  id: number;
  openedDate: string;
  name: Maybe<string>;
  location: string;
  totalHired: number;
};

function calculate_time_to_fill(filled_role: MergedPostingAndPosition<OverviewFilledPosting>) {
  let first_offer_accepted_date: string | null = null;
  filled_role.applicant_workflows
    .flatMap((workflow) => workflow.workflow_statuses)
    .filter((status) => {
      if (!status.status?.title) {
        return false;
      }

      const stage_name = find_candidate_stage(status?.status.title);

      if (!stage_name) return false;

      return ['Hired', 'Offer'].includes(stage_name);
    })
    .forEach((status) => {
      if (
        // Since we want the first offer accepted date, we ensure we get the earliest date
        !first_offer_accepted_date ||
        moment(status.date).isBefore(moment(first_offer_accepted_date))
      ) {
        first_offer_accepted_date = status.date;
      }
    });

  if (!first_offer_accepted_date) {
    Sentry.captureMessage('No first offer accepted date found');
    return '';
  }
  // In an edge case where the first offer accepted date is not found, we use the role's opened date
  // to display 0 days to fill
  const time_to_fill_days_diff = moment(first_offer_accepted_date).diff(
    moment(filled_role.openedDate),
    'days',
  );

  return time_to_fill_days_diff > 1
    ? `${time_to_fill_days_diff} days`
    : `${time_to_fill_days_diff} day`;
}

export function to_active_roles_props(
  active_postives_augmented: PostingWithTotalsAndHealthStatus[],
  active_role_bookmark_ids: number[],
): ComponentProps<typeof ManageRolesDashboard>['active_roles'] {
  return (
    active_postives_augmented
      .map((active_posting) => {
        return {
          id: active_posting.id,
          title: active_posting.name,
          active_candidates: active_posting.totalActiveCandidates,
          date_opened: moment(active_posting.openedDate).format('MMM D, YYYY'),
          hires_made: active_posting.totalHired,
          open_positions: active_posting.openHeadCount,
          is_bookmarked: active_role_bookmark_ids.includes(active_posting.id),
        };
      })
      // eslint-disable-next-line no-nested-ternary
      .sort((a, b) => (a.is_bookmarked === b.is_bookmarked ? 0 : a.is_bookmarked ? -1 : 1))
  );
}

export function to_draft_roles_props(
  draft_postings: ManageRolesQuery['job'],
): ComponentProps<typeof ManageRolesDashboard>['draft_roles'] {
  return draft_postings
    .filter(({ status }) =>
      [Job_Status_Choices_Enum.Draft, Job_Status_Choices_Enum.Submitted].includes(status),
    )
    .map((draft_posting) => {
      let status: ComponentProps<typeof ManageRolesDashboard>['draft_roles'][number]['status'] = {
        title: 'Draft',
        type: 'warning',
      };

      if (draft_posting.status === Job_Status_Choices_Enum.Draft) {
        status = {
          title: 'Draft',
          type: 'warning',
        };
      }

      if (draft_posting.status === Job_Status_Choices_Enum.Submitted) {
        status = {
          title: 'Pending Approval',
          type: 'primary',
        };
      }

      return {
        id: draft_posting.id,
        title: draft_posting.title,
        status,
        top_skills: draft_posting.job_required_skills.map<string>((skill) => skill.skill.name),
        number_of_positions: draft_posting.job_positions_aggregate.aggregate?.count || 0,
        years_of_experience: to_friendly_years_of_experience_range(
          draft_posting.min_years_of_experience,
        ),
      };
    });
}

export function to_filled_roles_props(
  filled_postings: ManageRolesQuery['filled_postings'],
): ComponentProps<typeof ManageRolesDashboard>['filled_roles'] {
  const filled_roles = mergePostingsAndPositions(filled_postings);

  return filled_roles
    .map((filled_role) => {
      return {
        id: filled_role.id,
        title: filled_role.name,
        hires_made: filled_role.closedHeadCount || 0,
        time_to_fill: calculate_time_to_fill(filled_role),
        date_opened: moment(filled_role.openedDate).format('MMM D, YYYY'),
      };
    })
    .sort((role_a, role_b) => compareByDateAsc(role_a.date_opened, role_b.date_opened));
}

export function serializer_for_manage_roles_dashboard(
  data: ManageRolesQuery | null,
  active_role_bookmark_ids: number[] = [],
): Omit<
  ComponentProps<typeof ManageRolesDashboard>,
  'is_loading' | 'pageLayoutProps' | 'on_role_bookmark_click'
> {
  if (!data) {
    return {
      active_roles: [],
      draft_roles: [],
      filled_roles: [],
      widgets: [],
    };
  }

  const active_roles = mergePostingsAndPositions(data.active_postings);
  const active_roles_augmented = augment_posting_with_totals_and_health_status(active_roles);

  const { all_active_candidates, all_open_head_counts, all_total_hires } =
    active_roles_augmented.reduce(
      (acc, augmentedPosting) => {
        return {
          all_active_candidates: acc.all_active_candidates + augmentedPosting.totalActiveCandidates,
          all_open_head_counts: acc.all_open_head_counts + augmentedPosting.openHeadCount,
          all_total_hires: acc.all_total_hires + augmentedPosting.totalHired,
        };
      },
      { all_active_candidates: 0, all_open_head_counts: 0, all_total_hires: 0 },
    );

  const all_active_members = Object.values(
    data.members.reduce((acc, next) => ({ ...acc, [next.member_details.id]: true }), {}),
  ).length;

  const filled_positions_total_hires = data.filled_postings.reduce((acc, filledPosting) => {
    const totalHiredCount = filledPosting.filled_positions_aggregate.total?.hires || 0;
    return acc + totalHiredCount;
  }, 0);

  // Total hires should never be less than the current count of activeMembers
  // since all active members must have been hired
  const max_of_total_members_n_hires = Math.max(
    all_active_members,
    all_total_hires + filled_positions_total_hires,
  );

  return {
    active_roles: to_active_roles_props(active_roles_augmented, active_role_bookmark_ids),
    draft_roles: to_draft_roles_props(data.job),
    filled_roles: to_filled_roles_props(data.filled_postings),
    widgets: [
      {
        subject: 'positions',
        title: `${Intl.NumberFormat('en-US').format(
          Number(all_open_head_counts || 0),
        )} Active Positions`,
        description: `across ${active_roles_augmented.length} role${
          active_roles_augmented.length !== 1 ? 's' : ''
        }`,
      },
      {
        subject: 'candidates',
        title: `${Intl.NumberFormat('en-US').format(
          Number(all_active_candidates) || 0,
        )} Active Candidates`,
        description: 'in process for your open roles',
      },
      {
        subject: 'members',
        title: `${Intl.NumberFormat('en-US').format(
          Number(all_active_members) || 0,
        )} Active Members`,
        description: `${max_of_total_members_n_hires} hires all time`,
      },
    ],
  };
}
