import { useState, useEffect, useRef, type ReactNode } from 'react';
import { useFormik } from 'formik';
import type { ApolloQueryResult, FetchResult } from '@apollo/client';
import { NetworkStatus } from '@apollo/client';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  Grid,
  Heading,
  Image,
  Text,
  ListItem,
  Skeleton,
  UnorderedList,
  Spinner,
  CustomSelect,
  useBreakpointValue,
  ArrowLeftIcon,
  ArrowRightIcon,
  DeleteIcon,
  HStack,
  MultiSelectTypeaheadWithCount,
  CustomSelectWithCount,
  CloseIcon,
  Tag,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  useDisclosure,
  DrawerCloseButton,
  DrawerHeader,
  Accordion,
  FormLabel,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
  TrendingIcon,
  Divider,
  DropdownIcon,
  BookmarkIcon,
  MenuItem,
  Menu,
  MenuButton,
  MenuList,
  MenuGroup,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalFooter,
} from '@terminal/design-system';
import * as events from 'global/events';
import type { Candidate_Curation_Years_Of_Exp_Range_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import { Card, CardBody, PageLayout, PageLayoutPaddedBody } from 'talent-hub/components';
import type {
  DeleteSavedCandidateMutation,
  InsertSavedCandidateMutation,
  SelectTeamMembersQuery,
} from 'talent-hub/utils';
import { toFriendlyYearsOfExperienceRange } from 'global/utils';
import {
  CarouselIconButton,
  ExploreCandidatesTabsLayout,
  ExploreCandidatesTabsLayoutIndex,
  ShareWithOthersModal,
  CandidateCard,
  CarouselSlide,
  Carousel,
  useCarousel,
  NoSearchesIcon,
} from 'talent-hub/shared/features/explore-candidates/components';
import type { KeenSliderHooks, KeenSliderInstance } from 'keen-slider/react';

import type {
  Formfields,
  serializeCandidateBrowse,
  serializeExploreCandidatesOptions,
} from './ExploreCandidates.serializer';

import { serializeTeamMembersToShareWith } from './ExploreCandidates.serializer';

import type { SelectBrowseCandidatesQuery } from './data';
import plant from '../../assets/plant.svg?url';
import contactus from '../../assets/contactus.svg?url';

const validationSchema = Yup.object().shape({
  candidateRole: Yup.string(),
  requiredSkills: Yup.array().of(
    Yup.object()
      .shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
      .nullable(),
  ),
  niceToHaveSkills: Yup.array().of(
    Yup.object()
      .shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
      .nullable(),
  ),
  location: Yup.string(),
  sort_option: Yup.string(),
});

export const formInitialValues: Formfields = {
  candidateRole: '',
  location: '',
  yearsOfExperience: '',
  employmentType: '',
  requiredSkills: [],
  niceToHaveSkills: [],
  sort_option: 'Recommended',
  candidateHighlights: [],
};

const orderToShowSelectedValues = [
  'requiredSkills',
  'location',
  'candidateRole',
  'employmentType',
  'niceToHaveSkills',
  'yearsOfExperience',
  'candidateHighlights',
] as const;

type FilterOptions = {
  roles: ReturnType<typeof serializeExploreCandidatesOptions>['roles'];
  skills: ReturnType<typeof serializeExploreCandidatesOptions>['skills'];
  locations: ReturnType<typeof serializeExploreCandidatesOptions>['locations'];
  yearsOfExperience: ReturnType<typeof serializeExploreCandidatesOptions>['yearsOfExperience'];
  employmentType: ReturnType<typeof serializeExploreCandidatesOptions>['employmentType'];
  sorting: ReturnType<typeof serializeExploreCandidatesOptions>['sorting'];
  candidateHighlights: ReturnType<typeof serializeExploreCandidatesOptions>['candidateHighlights'];
  isLoading: boolean;
};
type Searches = {
  popular: ReturnType<typeof serializeExploreCandidatesOptions>['popularSearches'];
  user: ReturnType<typeof serializeExploreCandidatesOptions>['savedSearches'];
  handleSave: (values: Formfields) => void;
  handleDelete: (id: number) => void;
};

function AllFiltersDrawer({
  isOpen,
  onClose,
  handleClearAllFilters,
  onSubmit,
  currentValues,
  filterOptions,
}: {
  onClose: () => void;
  isOpen: boolean;
  handleClearAllFilters: () => void;
  onSubmit: ReturnType<typeof useFormik<Formfields>>['setValues'];
  currentValues: ReturnType<typeof useFormik<Formfields>>['values'];
  filterOptions: FilterOptions;
}) {
  const draftForm = useFormik<Formfields>({
    initialValues: {
      ...currentValues,
    },
    validateOnMount: true,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: () => {
      onSubmit(draftForm.values);
    },
  });

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="lg" closeOnOverlayClick={false}>
      <DrawerOverlay />
      <DrawerContent h="full" justifyContent="space-between">
        <DrawerHeader p={0} border="0" bgColor="bg.tertiary">
          <DrawerCloseButton
            id="drawer-close-all-filters-icon"
            color="accent.main"
            pos="relative"
            size="lg"
          />
          <Heading variant="heading-1" pl={16} pt={2} pb={6}>
            All Filters
          </Heading>
        </DrawerHeader>
        <DrawerBody py={8} px={[2, 2, 14]}>
          <Accordion
            allowToggle={false}
            allowMultiple
            headingVariant="heading-4"
            motionProps={{
              // * required to avoid the overflow hidden https://github.com/chakra-ui/chakra-ui/blob/1a97c08b5bc159dfa17268a88e3a6a5c4ece339c/packages/components/transition/src/collapse.tsx#L146 - https://github.com/chakra-ui/chakra-ui/issues/2966
              style: { overflow: 'initial' },
            }}
            accordions={[
              {
                title: 'Skills',
                children: (
                  <>
                    <FormControl
                      id="requiredSkills"
                      isInvalid={!!draftForm.errors.requiredSkills}
                      paddingTop={6}
                    >
                      <MultiSelectTypeaheadWithCount
                        shouldOpenOnFocus
                        noOptionsMatchedCopy="Skill, comma-separated"
                        placeholder="Required skills"
                        inputValueToShow="Required skills"
                        options={filterOptions.skills}
                        initialSelectedOptions={draftForm.initialValues?.requiredSkills}
                        formInputValue={draftForm.values.requiredSkills?.length}
                        fullScreen={{
                          title: 'Required Skills',
                        }}
                        renderBefore={({ getLabelProps }) => (
                          <FormLabel {...getLabelProps()} mb={1}>
                            Required Skills
                          </FormLabel>
                        )}
                        name="requiredSkills"
                        containerProps={{
                          display: 'flex',
                          flexDirection: 'column',
                          sx: {
                            '& div': {
                              bg: 'bg.primary',
                            },
                          },
                        }}
                        onBlur={draftForm.handleBlur}
                        onSelectedItemsChange={(field, value) => {
                          draftForm.setFieldValue(field, value);
                        }}
                      />
                    </FormControl>
                    <Flex my={4} gap={2} wrap="wrap">
                      {draftForm.values.requiredSkills.map((skill) => (
                        <Tag
                          key={`selected-item-draft-requiredSkills-${skill.label}`}
                          colorScheme="disabled"
                          variant="filter"
                          sx={{
                            '&:hover svg': { color: 'ui.primary' },
                            userSelect: 'none',
                          }}
                        >
                          {skill.label}
                          <CloseIcon
                            onClick={() => {
                              draftForm.setFieldValue(
                                'requiredSkills',
                                draftForm.values.requiredSkills.filter(
                                  (item) => item.value !== skill.value,
                                ),
                              );
                            }}
                            cursor="pointer"
                            py={2}
                            pl={2}
                            w={6}
                            h={6}
                          />
                        </Tag>
                      ))}
                    </Flex>
                    <FormControl
                      id="niceToHaveSkills"
                      isInvalid={!!draftForm.errors.niceToHaveSkills}
                    >
                      <MultiSelectTypeaheadWithCount
                        shouldOpenOnFocus
                        noOptionsMatchedCopy="Skill, comma-separated"
                        placeholder="Nice to have skills"
                        inputValueToShow="Nice to have skills"
                        options={filterOptions.skills}
                        initialSelectedOptions={draftForm.initialValues?.niceToHaveSkills}
                        formInputValue={draftForm.values.niceToHaveSkills?.length}
                        fullScreen={{
                          title: 'Nice to Have Skills',
                        }}
                        renderBefore={({ getLabelProps }) => (
                          <FormLabel {...getLabelProps()} mb={1}>
                            Nice to Have Skills
                          </FormLabel>
                        )}
                        name="niceToHaveSkills"
                        containerProps={{
                          display: 'flex',
                          flexDirection: 'column',
                          sx: {
                            '& div': {
                              bg: 'bg.primary',
                            },
                          },
                        }}
                        onBlur={draftForm.handleBlur}
                        onSelectedItemsChange={(field, value) => {
                          draftForm.setFieldValue(field, value);
                        }}
                      />
                    </FormControl>
                    <Flex my={4} gap={2} wrap="wrap">
                      {draftForm.values.niceToHaveSkills.map((skill) => (
                        <Tag
                          key={`selected-item-draft-niceToHaveSkills-${skill.label}`}
                          colorScheme="disabled"
                          variant="filter"
                          sx={{
                            '&:hover svg': { color: 'ui.primary' },
                            userSelect: 'none',
                          }}
                        >
                          {skill.label}
                          <CloseIcon
                            onClick={() => {
                              draftForm.setFieldValue(
                                'niceToHaveSkills',
                                draftForm.values.niceToHaveSkills.filter(
                                  (item) => item.value !== skill.value,
                                ),
                              );
                            }}
                            cursor="pointer"
                            py={2}
                            pl={2}
                            w={6}
                            h={6}
                          />
                        </Tag>
                      ))}
                    </Flex>
                  </>
                ),
              },
              {
                title: 'Location',
                children: (
                  <RadioGroup
                    onChange={(newValue) => draftForm.setFieldValue('location', newValue)}
                    value={draftForm.values.location}
                    paddingTop={6}
                  >
                    <FormControl isInvalid={!!draftForm.errors.location}>
                      <Flex wrap="wrap" gap={6}>
                        {filterOptions.locations.map(({ label }) => (
                          <Radio flex="40%" value={label} key={`filter-drawer-location-${label}`}>
                            {label}
                          </Radio>
                        ))}
                      </Flex>
                    </FormControl>
                  </RadioGroup>
                ),
              },
              {
                title: 'Role',
                children: (
                  <RadioGroup
                    onChange={(newValue) => draftForm.setFieldValue('candidateRole', newValue)}
                    value={draftForm.values.candidateRole}
                    paddingTop={6}
                  >
                    <FormControl isInvalid={!!draftForm.errors.candidateRole}>
                      <Flex wrap="wrap" gap={6}>
                        {filterOptions.roles.map((label) => (
                          <Radio flex="40%" value={label} key={`filter-drawer-role-${label}`}>
                            {label}
                          </Radio>
                        ))}
                      </Flex>
                    </FormControl>
                  </RadioGroup>
                ),
              },
              {
                title: 'Type of Employment',
                children: (
                  <RadioGroup
                    onChange={(newValue) => draftForm.setFieldValue('employmentType', newValue)}
                    value={draftForm.values.employmentType}
                    paddingTop={6}
                  >
                    <FormControl isInvalid={!!draftForm.errors.employmentType}>
                      <Flex wrap="wrap" gap={6}>
                        {filterOptions.employmentType.map(({ label, value }) => (
                          <Radio
                            flex="40%"
                            value={label}
                            key={`filter-drawer-employment-type-${value}`}
                          >
                            {label}
                          </Radio>
                        ))}
                      </Flex>
                    </FormControl>
                  </RadioGroup>
                ),
              },
              {
                title: 'Minimum Years of Experience',
                children: (
                  <RadioGroup
                    onChange={(newValue) => draftForm.setFieldValue('yearsOfExperience', newValue)}
                    value={draftForm.values.yearsOfExperience}
                    paddingTop={6}
                  >
                    <FormControl isInvalid={!!draftForm.errors.yearsOfExperience}>
                      <Flex wrap="wrap" gap={6}>
                        {filterOptions.yearsOfExperience.map(({ label, value }) => (
                          <Radio flex="40%" value={label} key={`filter-drawer-yox-${value}`}>
                            {label}
                          </Radio>
                        ))}
                      </Flex>
                    </FormControl>
                  </RadioGroup>
                ),
              },
              {
                title: 'Candidate Highlights',
                children: (
                  <CheckboxGroup
                    onChange={(newValues) => {
                      draftForm.setFieldValue('candidateHighlights', newValues);
                    }}
                    value={draftForm.values.candidateHighlights}
                  >
                    <FormControl isInvalid={!!draftForm.errors.candidateHighlights}>
                      <Flex wrap="wrap" gap={6} paddingTop={6}>
                        {filterOptions.candidateHighlights.map(({ label, value }) => (
                          <Checkbox
                            flex="40%"
                            value={value}
                            data-testid={`filter-drawer-candidate-highlight-${value}`}
                            key={`filter-drawer-candidate-highlight-${value}`}
                          >
                            {label}
                          </Checkbox>
                        ))}
                      </Flex>
                    </FormControl>
                  </CheckboxGroup>
                ),
              },
            ]}
          />
        </DrawerBody>
        <DrawerFooter
          px={[4, 4, 14]}
          py={[4, 4, 6]}
          gridGap={4}
          boxShadow="reverse.md"
          display="flex"
          flexDir={['column', 'column', 'row']}
        >
          <Button
            id="drawer-clear-all-filters-button"
            variant="ghost"
            colorScheme="primary"
            width="full"
            size="lg"
            onClick={() => {
              handleClearAllFilters();
              onClose();
            }}
          >
            Clear
          </Button>
          <Button
            id="drawer-apply-filters-button"
            variant="solid"
            colorScheme="primary"
            width="full"
            size="lg"
            onClick={() => {
              draftForm.handleSubmit();
            }}
          >
            Apply
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

function Filters({
  filterOptions,
  searches,
  loadingCandidates,
  initialValues,
  formUtils: { setValues, isValid, values, errors, handleBlur, handleSubmit, setFieldValue, dirty },
  handleClearAllFilters,
  handleSearchCandidates,
  children,
}: {
  filterOptions: FilterOptions;
  searches: Searches;
  loadingCandidates: boolean;
  initialValues?: Partial<Formfields>;
  formUtils: ReturnType<typeof useFormik<Formfields>>;
  handleClearAllFilters: () => void;
  children: ReactNode;
  handleSearchCandidates: (
    variables: Partial<ReturnType<typeof useFormik<Formfields>>['values']>,
  ) => Promise<void>;
}) {
  const numberOfFilterChanges = useRef(0);

  // TODO: simplify this code. Its becoming complex and hard to reason one. Ideally we move part of it into pure function and
  // write test it for it too.
  const badgesToShow = orderToShowSelectedValues.reduce((acc, field) => {
    const selectedValue: Formfields[typeof field] = values[field];

    let tagProps: {
      label: string;
      value: string;
      onClick?: () => void;
    }[];

    if (!selectedValue || !selectedValue?.length) return acc;

    if (!Array.isArray(selectedValue)) {
      tagProps = [
        {
          value: selectedValue,
          label: selectedValue,
          onClick: () => {
            setFieldValue(field, '');
          },
        },
      ];
    } else if (selectedValue.some((item) => typeof item !== 'string')) {
      tagProps = selectedValue as {
        value: string;
        label: string;
      }[];
    } else {
      tagProps = (selectedValue as string[]).map((selectedValueValue) => {
        let item: { value: string; label: string } | undefined;

        if (field === 'candidateHighlights') {
          item = filterOptions.candidateHighlights.find(({ value }) => {
            return selectedValueValue === value;
          });
        }

        return {
          value: item?.value || '', // the or empty string is only fix typescript. This technically is not possible
          label: item?.label || '',
          onClick: () => {
            setFieldValue(
              field,
              (values[field] as string[]).filter((value) => value !== item?.value || ''),
            );
          },
        };
      });
    }

    return [
      ...acc,
      ...tagProps.map(({ label, value, onClick }) => {
        return (
          <Tag
            key={`selected-item-${field}-${label}`}
            colorScheme="disabled"
            variant="filter"
            sx={{
              '&:hover svg': { color: 'ui.primary' },
              userSelect: 'none',
            }}
          >
            {label}
            <CloseIcon
              onClick={() => {
                if (onClick) {
                  onClick();
                  return;
                }
                setFieldValue(
                  field,
                  (values[field] as { value: string; label: string }[]).filter(
                    (item) => item.value !== value,
                  ),
                );
              }}
              cursor="pointer"
              py={2}
              pl={2}
              w={6}
              h={6}
            />
          </Tag>
        );
      }),
    ];
  }, [] as JSX.Element[]);

  // TODO: (TAL-1143) This is an incorrect situation to use useEffect for. Insteadf we should move this effect to when
  // the event of filter changes are triggered.
  useEffect(() => {
    if (
      !isValid ||
      (numberOfFilterChanges.current === 0 && !dirty) // to not submit the form on page load (before user changing any filter)
    )
      return;

    numberOfFilterChanges.current += 1;
    handleSubmit();
    // * this is required to submit the form each time that the users selects or remove a value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isValid,
    values.requiredSkills,
    values.niceToHaveSkills,
    values.location,
    values.candidateRole,
    values.employmentType,
    values.yearsOfExperience,
    values.candidateHighlights,
  ]);

  const { onOpen, onClose, isOpen } = useDisclosure();
  const [searchToDelete, setSearchToDelete] = useState<number | null>(null);
  const confirmDelete_savedSearch_modal = useDisclosure();

  return (
    <>
      <Box p={6} bgColor="bg.primary" shadow="sm">
        <Flex
          gap={4}
          wrap="nowrap"
          justifyContent="space-between"
          flexDir={['column', 'column', 'row']}
        >
          <FormControl id="requiredSkills" isInvalid={!!errors.requiredSkills} flex="2">
            <MultiSelectTypeaheadWithCount
              showSelectIcon
              shouldOpenOnFocus
              noOptionsMatchedCopy="Skill, comma-separated"
              placeholder="Required skills"
              inputValueToShow="Required skills"
              options={filterOptions.skills}
              initialSelectedOptions={initialValues?.requiredSkills}
              formInputValue={values.requiredSkills?.length}
              fullScreen={{
                title: 'Required Skills',
              }}
              name="requiredSkills"
              containerProps={{
                display: 'flex',
                flexDirection: 'column',
                sx: {
                  '& div': {
                    bg: 'bg.primary',
                  },
                },
              }}
              onBlur={handleBlur}
              onSelectedItemsChange={(field, value) => {
                setFieldValue(field, value);
              }}
            />
          </FormControl>
          <FormControl id="location" isInvalid={!!errors.location} flex="2">
            <CustomSelectWithCount
              initialValue={values.location}
              name="location"
              fullScreen={{
                title: 'Location',
              }}
              placeholder="Location"
              inputValueToShow="Location"
              noOptionsMatchedCopy="Location not found"
              options={filterOptions.locations.map(({ label }) => label)}
              formInputValue={values.location}
              containerProps={{
                display: 'flex',
                flexDirection: 'column',
                sx: {
                  '& input': {
                    bg: 'bg.primary',
                  },
                },
              }}
              onBlur={handleBlur}
              onSelectionChange={(field, value) => {
                setFieldValue(field, value);
              }}
            />
          </FormControl>
          <FormControl id="candidateRole" isInvalid={!!errors.candidateRole} flex="2">
            <CustomSelectWithCount
              initialValue={initialValues?.candidateRole}
              name="candidateRole"
              fullScreen={{
                title: 'Role',
              }}
              placeholder="Role"
              inputValueToShow="Role"
              noOptionsMatchedCopy="Role not found"
              options={filterOptions.roles}
              formInputValue={values.candidateRole}
              containerProps={{
                display: 'flex',
                flexDirection: 'column',
                sx: {
                  '& input': {
                    bg: 'bg.primary',
                  },
                },
              }}
              onBlur={handleBlur}
              onSelectionChange={(field, value) => {
                setFieldValue(field, value);
              }}
            />
          </FormControl>
          <FormControl id="employmentType" isInvalid={!!errors.employmentType} flex="2">
            <CustomSelectWithCount
              initialValue={values.employmentType}
              name="employmentType"
              fullScreen={{
                title: 'Employment Type',
              }}
              placeholder="Employment Type"
              inputValueToShow="Employment Type"
              noOptionsMatchedCopy="Employment Type not found"
              options={filterOptions.employmentType.map(({ label }) => label)}
              formInputValue={values.employmentType}
              containerProps={{
                display: 'flex',
                flexDirection: 'column',
                sx: {
                  '& input': {
                    bg: 'bg.primary',
                  },
                },
              }}
              onBlur={handleBlur}
              onSelectionChange={(field, value) => {
                setFieldValue(field, value);
              }}
            />
          </FormControl>
          <Box display="flex" flex="1">
            <FormControl>
              <Button
                colorScheme="primary"
                disabled={!!loadingCandidates}
                onClick={onOpen}
                type="button"
                variant="outline"
                w="full"
                id="open-all-filters-button"
                rightIcon={
                  badgesToShow.length ? (
                    <Tag
                      ml={2}
                      p={0}
                      justifyContent="center"
                      size="sm"
                      color="text.inverse"
                      backgroundColor="ui.darker.info"
                      fontWeight="bold"
                      verticalAlign="text-bottom"
                    >
                      {badgesToShow.length}
                    </Tag>
                  ) : undefined
                }
              >
                All Filters
              </Button>
            </FormControl>
            <Divider orientation="vertical" mx={5} />
            <Menu>
              <MenuButton
                as={Button}
                className="save-search-button"
                colorScheme="primary"
                disabled={!!loadingCandidates}
                type="button"
                variant="ghost"
                w="full"
                rightIcon={<DropdownIcon fontSize="md" />}
              >
                Save Search
              </MenuButton>
              <MenuList>
                <MenuItem
                  className="save-search-link"
                  onClick={() => {
                    events.track(events.name.exploreCandidates.searchSaved, {
                      must_have_skills: values.requiredSkills.map((skill) => skill.label),
                      nice_to_have_skills: values.niceToHaveSkills.map((skill) => skill.label),
                      role: values.candidateRole,
                      region: values.location,
                      years_of_experience: values.yearsOfExperience,
                      candidate_highlights: values.candidateHighlights,
                      employment_type: values.employmentType,
                    });
                    searches.handleSave(values);
                  }}
                >
                  <BookmarkIcon mr={2} />
                  <Text variant="body">Save this search</Text>
                </MenuItem>
                <MenuGroup>
                  <Text
                    variant="caption"
                    color="text.secondary"
                    fontWeight="bold"
                    bgColor="bg.secondary"
                    py={4}
                    px={6}
                  >
                    Your Recent Saved Searches
                  </Text>
                  {searches.user.length ? (
                    searches.user.map((search) => (
                      <MenuItem
                        className="saved-search-item"
                        cursor="default"
                        justifyContent="space-between"
                        key={search.id}
                        onClick={() => {
                          events.track(events.name.exploreCandidates.searchSavedRun);
                          setValues(search.values);
                        }}
                      >
                        <Flex direction="column" cursor="pointer" className="run-search-link">
                          <Text variant="body">{search.title}</Text>
                          <Text variant="hint" color="text.secondary">
                            {search.createdAt}
                          </Text>
                        </Flex>
                        <Flex justifyContent="space-between" ml={6}>
                          <ArrowRightIcon
                            color="brand.main"
                            fontSize="3xl"
                            px={1}
                            cursor="pointer"
                            className="run-search-link"
                          />
                          <DeleteIcon
                            color="ui.error"
                            fontSize="3xl"
                            px={1}
                            marginLeft={10}
                            cursor="pointer"
                            className="delete-search-link"
                            onClick={(ev) => {
                              ev.stopPropagation();
                              setSearchToDelete(search.id);
                              confirmDelete_savedSearch_modal.onOpen();
                            }}
                          />
                        </Flex>
                      </MenuItem>
                    ))
                  ) : (
                    <Center flexDirection="column" p={6}>
                      <NoSearchesIcon fontSize="7xl" />
                      <Text mt={4} variant="caption" fontWeight="bold" color="text.secondary">
                        No Saved Searches
                      </Text>
                      <Text variant="caption" color="text.disabled">
                        You can save a search by clicking the button above.{' '}
                      </Text>
                    </Center>
                  )}
                </MenuGroup>
              </MenuList>
            </Menu>
          </Box>
        </Flex>
        {!!badgesToShow?.length && (
          <Flex pt={6} gap={2} wrap="wrap">
            {badgesToShow}
            <Button
              id="clear-all-filters-button"
              colorScheme="primary"
              disabled={!!loadingCandidates}
              onClick={handleClearAllFilters}
              type="reset"
              variant="ghost"
              size="sm"
            >
              Clear All
            </Button>
          </Flex>
        )}
        {!badgesToShow?.length && !!searches?.popular?.length && (
          <Flex pt={6} gap={2} wrap="wrap">
            <Text variant="label" py={1} mr={2}>
              <TrendingIcon marginRight={2} />
              Popular Searches:
            </Text>
            {searches.popular.map((search, i) => (
              <Tag
                // eslint-disable-next-line react/no-array-index-key
                key={`popular-search--${i}`}
                colorScheme="disabled"
                variant="filter"
                border="1px solid"
                borderColor="ui.secondary"
                bgColor="bg.primary"
                cursor="pointer"
                mr={2}
                onClick={() => setValues(search.values)}
              >
                {search.title}
              </Tag>
            ))}
          </Flex>
        )}
      </Box>
      <Flex px={6} py={4} flexDir="row" alignItems="center" justifyContent="space-between">
        {children}
        <Flex alignItems="center" gap="4">
          <Text variant="label">Sort By</Text>
          <CustomSelect
            initialValue={values.sort_option}
            formInputValue={values.sort_option}
            name="sort_option"
            fullScreen={{
              title: 'Sort By',
            }}
            placeholder="Recommended"
            options={filterOptions.sorting.map(({ label }) => label)}
            containerProps={{
              display: 'flex',
              flexDirection: 'column',
              sx: {
                '& input': {
                  bg: 'bg.primary',
                },
              },
            }}
            onBlur={handleBlur}
            onSelectionChange={(_field, newSelectValue) => {
              setFieldValue(_field, newSelectValue).then(() => {
                handleSearchCandidates({
                  ...values,
                  // * https://stackoverflow.com/questions/56613496/issue-with-values-formik
                  [_field]: newSelectValue,
                });
              });
            }}
          />
        </Flex>
      </Flex>
      {
        // * this is required to unmount the component onClose
        isOpen && (
          <AllFiltersDrawer
            isOpen={isOpen}
            onClose={onClose}
            handleClearAllFilters={handleClearAllFilters}
            onSubmit={async (drawerFormValues, shouldValidate) => {
              await setValues(drawerFormValues, shouldValidate);
              onClose();
            }}
            currentValues={values}
            filterOptions={filterOptions}
          />
        )
      }
      <Modal
        isCentered
        isOpen={confirmDelete_savedSearch_modal.isOpen}
        onClose={() => {
          setSearchToDelete(null);
          confirmDelete_savedSearch_modal.onClose();
        }}
        scrollBehavior="outside"
        closeOnEsc={false}
        closeOnOverlayClick={false}
        motionPreset="scale"
      >
        <ModalOverlay />
        <ModalContent maxW={['21.938rem', '21.938rem', 'lg']}>
          <ModalHeader
            borderBottom="1px solid"
            borderColor="ui.inverse.secondary"
            pt={6}
            bg="transparent"
          >
            <Heading color="text.secondary" display="inline-block" variant="heading-4">
              Delete this saved search?
            </Heading>
            <ModalCloseButton color="brand.darkest" top={4} right={4} size="lg" />
          </ModalHeader>
          <ModalBody p={4} textAlign="center">
            <Text color="text.primary">
              You are about to delete your saved search. Do you want to continue?
            </Text>
          </ModalBody>
          <ModalFooter p={4} px={4} gap={4}>
            <Button
              variant="outline"
              colorScheme="primary"
              size="lg"
              w="full"
              type="reset"
              onClick={() => {
                confirmDelete_savedSearch_modal.onClose();
                setSearchToDelete(null);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              colorScheme="primary"
              size="lg"
              w="full"
              type="button"
              onClick={() => {
                if (!searchToDelete) return;
                searches.handleDelete(searchToDelete);
                confirmDelete_savedSearch_modal.onClose();
                setSearchToDelete(null);
              }}
              disabled={!searchToDelete}
            >
              Delete Search
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function Layout({
  children,
  filterOptions,
  searches,
  loadingCandidates,
  pageLayoutProps,
  savedByMeCandidatesCount,
  sharedWithMeCandidatesCount,
  isInLimitedMode,
  initialValues,
  handleOnTabClick,
  handleClearAllFilters,
  formUtils,
  selectedYoE,
  candidatesLength,
  otherCandidatesLength,
  highlightedCandidatesLength,
  candidatesTotalLength,
  handleSearchCandidates,
}: {
  children: React.ReactNode;
  filterOptions: FilterOptions;
  searches: Searches;
  loadingCandidates: boolean;
  pageLayoutProps: React.ComponentProps<typeof PageLayout>;
  savedByMeCandidatesCount: number;
  sharedWithMeCandidatesCount: number;
  isInLimitedMode?: boolean;
  initialValues?: Partial<Formfields>;
  handleOnTabClick?: () => void;
  formUtils: ReturnType<typeof useFormik<Formfields>>;
  handleClearAllFilters: () => void;
  selectedYoE?: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum;
  candidatesLength: number;
  otherCandidatesLength?: number;
  highlightedCandidatesLength: number;
  candidatesTotalLength: number;
  handleSearchCandidates: (
    variables: Partial<ReturnType<typeof useFormik<Formfields>>['values']>,
  ) => Promise<void>;
}) {
  return (
    <PageLayout headerTitle="Explore Candidates" {...pageLayoutProps} disableMaxWidth>
      <ExploreCandidatesTabsLayout
        isInLimitedMode={isInLimitedMode}
        currentTab={ExploreCandidatesTabsLayoutIndex.Search}
        savedByMeCandidatesCount={savedByMeCandidatesCount}
        sharedWithMeCandidatesCount={sharedWithMeCandidatesCount}
        handleOnTabClick={handleOnTabClick}
      >
        <Filters
          filterOptions={filterOptions}
          loadingCandidates={loadingCandidates}
          initialValues={initialValues}
          handleClearAllFilters={handleClearAllFilters}
          formUtils={formUtils}
          handleSearchCandidates={handleSearchCandidates}
          searches={searches}
        >
          {selectedYoE &&
          candidatesLength === 0 &&
          highlightedCandidatesLength === 0 &&
          otherCandidatesLength ? (
            <Text>
              We&apos;re sorry, no candidates found for{' '}
              <strong>{toFriendlyYearsOfExperienceRange(selectedYoE)} of experience</strong>
            </Text>
          ) : (
            <Text mr={3}>
              {candidatesTotalLength} Candidate
              {candidatesTotalLength === 1 ? '' : 's'}
            </Text>
          )}
        </Filters>
        <PageLayoutPaddedBody pt={0} maxW="full">
          {children}
        </PageLayoutPaddedBody>
      </ExploreCandidatesTabsLayout>
    </PageLayout>
  );
}

function HighlightedCandidatesCarousel({
  onOpenShareWithModal,
  setShareWithCandidateInfo,
  handleOnRemoveSaveCandidate,
  handleOnSaveCandidate,
  highlightedCandidatesTitle,
  highlightedCandidatesSubtitle,
  isFilterSelected,
  isInLimitedMode,
  highlightedCandidates,
}: {
  onOpenShareWithModal: () => void;
  setShareWithCandidateInfo: (candidateData: { id: number; name: string }) => void;
  handleOnRemoveSaveCandidate: (
    candidateId: number,
  ) => Promise<FetchResult<DeleteSavedCandidateMutation, Record<string, any>, Record<string, any>>>;
  handleOnSaveCandidate: (
    candidateId: number,
  ) => Promise<FetchResult<InsertSavedCandidateMutation, Record<string, any>, Record<string, any>>>;
  isInLimitedMode?: boolean;
  highlightedCandidates: ReturnType<typeof serializeCandidateBrowse>['highlightedCandidates'];
  highlightedCandidatesTitle: ReturnType<
    typeof serializeCandidateBrowse
  >['highlightedCandidatesTitle'];
  highlightedCandidatesSubtitle: ReturnType<
    typeof serializeCandidateBrowse
  >['highlightedCandidatesSubtitle'];
  isFilterSelected: boolean;
}) {
  const [renderedCandidates, setRenderedCandidates] = useState(
    highlightedCandidates.map((candidate) => candidate.id),
  );
  const [currentSlide, setCurrentSlide] = useState(0);
  const slidesPerView = useBreakpointValue({ base: 1, sm: 2, md: 3 });
  const [ref, slider] = useCarousel({
    slides: {
      perView: slidesPerView,
      spacing: useBreakpointValue({ base: 24 }),
    },
    defaultAnimation: {
      duration: 15,
    },
    rubberband: false,
    drag: false,
    slideChanged: (sliderChanged: KeenSliderInstance<{}, {}, KeenSliderHooks>) =>
      setCurrentSlide(sliderChanged.track.details.rel),
  });

  useEffect(() => {
    if (
      !renderedCandidates.every((id) =>
        highlightedCandidates.map((candidate) => candidate.id).includes(id),
      )
    ) {
      slider.current?.moveToIdx(0);
      setCurrentSlide(0);
      setRenderedCandidates(highlightedCandidates.map((candidate) => candidate.id));
    }
  }, [highlightedCandidates, renderedCandidates, slider, slidesPerView]);

  if (
    !renderedCandidates.every((id) =>
      highlightedCandidates.map((candidate) => candidate.id).includes(id),
    )
  ) {
    return null;
  }

  return (
    <Box p={6} pt={4} mx={-6} background={isFilterSelected ? 'bg.tertiary' : 'ui.lightest.warning'}>
      <Flex justifyContent="space-between" alignItems="center">
        {(!!highlightedCandidatesTitle || !!highlightedCandidatesSubtitle) && (
          <Box>
            {!!highlightedCandidatesTitle && (
              <Heading variant="heading-3">{highlightedCandidatesTitle}</Heading>
            )}
            {!!highlightedCandidatesSubtitle && (
              <Text variant="body" mt={1}>
                {highlightedCandidatesSubtitle}
              </Text>
            )}
          </Box>
        )}
        {highlightedCandidates.length > 3 && (
          <Box>
            <CarouselIconButton
              onClick={() => slider.current?.prev()}
              icon={<ArrowLeftIcon />}
              aria-label="Previous slide"
              disabled={currentSlide === 0}
              minW={12}
              h={12}
            />
            <CarouselIconButton
              onClick={() => slider.current?.next()}
              icon={<ArrowRightIcon />}
              aria-label="Next slide"
              disabled={currentSlide + Number(slidesPerView) === highlightedCandidates.length}
              minW={12}
              h={12}
            />
          </Box>
        )}
      </Flex>
      <HStack mt={4}>
        <Carousel ref={ref} direction="row" width="full">
          {highlightedCandidates.map((candidate) => (
            <CarouselSlide
              key={candidate.id}
              data-index={candidate.id}
              cursor="pointer"
              transition="all 200ms"
            >
              <CandidateCard
                isInLimitedMode={isInLimitedMode}
                candidate={candidate}
                linkTo={`/explore-candidates/candidate/${candidate.id}`}
                handleOnRemoveSaveCandidate={handleOnRemoveSaveCandidate}
                handleOnSaveCandidate={handleOnSaveCandidate}
                handleOpenShareWithOthers={() => {
                  if (!isInLimitedMode)
                    setShareWithCandidateInfo({
                      id: candidate.id,
                      name: candidate.fullName,
                    });
                  onOpenShareWithModal();
                }}
              />
            </CarouselSlide>
          ))}
        </Carousel>
      </HStack>
    </Box>
  );
}

// TODO: Rename this to BrowseCandidates
export function ExploreCandidates({
  candidates,
  filterOptions,
  searches,
  loadingCandidates,
  pageLayoutProps,
  refetchCandidates,
  quantityResults,
  savedByMeCandidatesCount,
  sharedWithMeCandidatesCount,
  handleOnRemoveSaveCandidate,
  handleOnSaveCandidate,
  sharedCandidateWithProps: {
    teamMembers,
    handleInsertSharedWith,
    isShareWithModalOpen,
    onCloseShareWithModal,
    onOpenShareWithModal,
  },
  isInLimitedMode,
  onClearAllFilters,
  onBeforeClearAllFilters,
  initialValues,
  selectedYoE,
  otherCandidates,
  highlightedCandidates,
  highlightedCandidatesTitle,
  highlightedCandidatesSubtitle,
  isFilterSelected,
  networkStatus,
}: {
  searches: Searches;
  candidates: ReturnType<typeof serializeCandidateBrowse>['candidates'];
  filterOptions: FilterOptions;
  loadingCandidates: boolean;
  pageLayoutProps: React.ComponentProps<typeof PageLayout>;
  refetchCandidates: (
    variables: Partial<ReturnType<typeof useFormik<Formfields>>['values']>,
  ) => Promise<ApolloQueryResult<SelectBrowseCandidatesQuery>>;
  quantityResults: number;
  savedByMeCandidatesCount: number;
  sharedWithMeCandidatesCount: number;
  handleOnRemoveSaveCandidate: (
    candidateId: number,
  ) => Promise<FetchResult<DeleteSavedCandidateMutation, Record<string, any>, Record<string, any>>>;
  handleOnSaveCandidate: (
    candidateId: number,
  ) => Promise<FetchResult<InsertSavedCandidateMutation, Record<string, any>, Record<string, any>>>;
  sharedCandidateWithProps: {
    teamMembers: SelectTeamMembersQuery | undefined;
    handleInsertSharedWith: (candidateId: number, teamMembersIds: string[]) => void;
    isShareWithModalOpen: boolean;
    onCloseShareWithModal: () => void;
    onOpenShareWithModal: () => void;
  };
  isInLimitedMode?: boolean;
  onBeforeClearAllFilters?: () => void;
  onClearAllFilters?: () => void;
  initialValues?: Partial<Formfields>;
  selectedYoE?: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum;
  otherCandidates: ReturnType<typeof serializeCandidateBrowse>['otherCandidates'];
  highlightedCandidates: ReturnType<typeof serializeCandidateBrowse>['highlightedCandidates'];
  highlightedCandidatesTitle: ReturnType<
    typeof serializeCandidateBrowse
  >['highlightedCandidatesTitle'];
  highlightedCandidatesSubtitle: ReturnType<
    typeof serializeCandidateBrowse
  >['highlightedCandidatesSubtitle'];
  isFilterSelected: boolean;
  networkStatus: NetworkStatus;
}) {
  // TODO: move to the controller
  const handleSearchCandidates = async (
    formNewValues: Partial<ReturnType<typeof useFormik<Formfields>>['values']>,
  ) => {
    await refetchCandidates(formNewValues).then(({ data }) => {
      events.track(events.name.exploreCandidates.searchUsed, {
        must_have_skills: (formNewValues?.requiredSkills || []).map((skill) => skill.label),
        nice_to_have_skills: (formNewValues?.niceToHaveSkills || []).map((skill) => skill.label),
        role: formNewValues.candidateRole,
        region: formNewValues.location,
        years_of_experience: formNewValues.yearsOfExperience,
        candidate_highlights: formNewValues.candidateHighlights,
        employment_type: formNewValues.employmentType,
        num_of_results:
          (data?.candidate_browse || []).length > 0 ? data?.candidate_browse?.[0]?.total_count : 0,
      });
    });
  };

  const formUtils = useFormik<Formfields>({
    initialValues: {
      ...formInitialValues,
      ...(initialValues || {}),
    },
    validateOnMount: true,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async (formNewValues) => {
      // TODO: The UI component shouldn't know that when the form gets sumbitted it needs to refetch candidates
      // thats the controller responsibility. We need to rename this to onFiltersApplied or something like that
      // * Search triggered by the submit button, always should reset sort_by field and use Recommended
      await handleSearchCandidates({
        ...formNewValues,
        // * search triggered by the button should always be sort_by recommended
        sort_option: formInitialValues.sort_option,
      });
      formUtils.setFieldValue('sort_option', formInitialValues.sort_option);
    },
  });

  const [shareWithCandidateInfo, setShareWithCandidateInfo] = useState<
    { id: number; name: string } | undefined
  >();

  const handleClearAllFilters = async () => {
    onBeforeClearAllFilters?.();
    formUtils.resetForm({
      values: {
        ...formInitialValues,
      },
    });
    await handleSearchCandidates({
      ...formInitialValues,
    });
    onClearAllFilters?.();
  };

  const candidatesTotalLength = selectedYoE
    ? candidates.length + highlightedCandidates.length
    : quantityResults;

  if (loadingCandidates && (!candidates || candidates.length === 0)) {
    return (
      <Layout
        initialValues={initialValues}
        filterOptions={filterOptions}
        searches={searches}
        loadingCandidates={loadingCandidates}
        pageLayoutProps={pageLayoutProps}
        savedByMeCandidatesCount={savedByMeCandidatesCount}
        sharedWithMeCandidatesCount={sharedWithMeCandidatesCount}
        isInLimitedMode={isInLimitedMode}
        formUtils={formUtils}
        handleClearAllFilters={handleClearAllFilters}
        selectedYoE={selectedYoE}
        candidatesLength={candidates.length}
        otherCandidatesLength={otherCandidates?.length}
        highlightedCandidatesLength={highlightedCandidates.length}
        candidatesTotalLength={candidatesTotalLength}
        handleSearchCandidates={handleSearchCandidates}
      >
        <Grid
          mt={6}
          gap={6}
          autoColumns="1fr"
          templateColumns={['1fr', '1fr 1fr', 'repeat(3, 1fr)']}
        >
          {Array(6)
            .fill(0)
            .map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Card key={index}>
                <CardBody>
                  <Skeleton height={4} borderRadius="lg" width="25%" />
                  <Skeleton height={4} borderRadius="lg" width="50%" mt={1} />
                  <Skeleton height={32} mt={4} endColor="ui.inverse.secondary" />
                  <Flex mt={4}>
                    <Skeleton
                      height={6}
                      flex={1}
                      endColor="ui.inverse.secondary"
                      borderRadius="lg"
                    />
                    <Skeleton
                      height={6}
                      flex={1}
                      endColor="ui.inverse.secondary"
                      borderRadius="lg"
                      ml={2}
                    />
                    <Skeleton
                      height={6}
                      flex={1}
                      endColor="ui.inverse.secondary"
                      borderRadius="lg"
                      ml={2}
                    />
                    <Skeleton
                      height={6}
                      flex={1}
                      endColor="ui.inverse.secondary"
                      borderRadius="lg"
                      ml={2}
                    />
                  </Flex>
                </CardBody>
              </Card>
            ))}
        </Grid>
      </Layout>
    );
  }

  if (
    networkStatus === NetworkStatus.ready &&
    (selectedYoE
      ? candidates.length === 0 && !otherCandidates?.length && highlightedCandidates.length === 0
      : candidates.length === 0 && highlightedCandidates.length === 0)
  ) {
    return (
      <Layout
        initialValues={initialValues}
        filterOptions={filterOptions}
        searches={searches}
        loadingCandidates={loadingCandidates}
        pageLayoutProps={pageLayoutProps}
        savedByMeCandidatesCount={savedByMeCandidatesCount}
        sharedWithMeCandidatesCount={sharedWithMeCandidatesCount}
        isInLimitedMode={isInLimitedMode}
        formUtils={formUtils}
        handleClearAllFilters={handleClearAllFilters}
        selectedYoE={selectedYoE}
        candidatesLength={candidates.length}
        otherCandidatesLength={otherCandidates?.length}
        highlightedCandidatesLength={highlightedCandidates.length}
        candidatesTotalLength={candidatesTotalLength}
        handleSearchCandidates={handleSearchCandidates}
      >
        <Box maxW="ml" mt={6} mx="auto">
          <Image src={plant} alt="Plant" maxH={20} mx="auto" />
          <Heading variant="heading-2" mt={6}>
            We&apos;re sorry. Your search criteria has returned no Candidates
          </Heading>
          <Heading variant="heading-4" mt={4}>
            Search Help
          </Heading>
          <UnorderedList mt={1}>
            <ListItem>
              <Text>Broaden your search by limiting Required Skills</Text>
            </ListItem>
            <ListItem>
              <Text>Remove some Nice to Have Skills</Text>
            </ListItem>
            <ListItem>
              <Text>Contact your Talent Partner for help</Text>
            </ListItem>
          </UnorderedList>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout
      initialValues={initialValues}
      filterOptions={filterOptions}
      searches={searches}
      loadingCandidates={loadingCandidates}
      pageLayoutProps={pageLayoutProps}
      savedByMeCandidatesCount={savedByMeCandidatesCount}
      sharedWithMeCandidatesCount={sharedWithMeCandidatesCount}
      isInLimitedMode={isInLimitedMode}
      formUtils={formUtils}
      handleClearAllFilters={handleClearAllFilters}
      selectedYoE={selectedYoE}
      candidatesLength={candidates.length}
      otherCandidatesLength={otherCandidates?.length}
      highlightedCandidatesLength={highlightedCandidates.length}
      candidatesTotalLength={candidatesTotalLength}
      handleSearchCandidates={handleSearchCandidates}
    >
      <>
        {Boolean(highlightedCandidates.length) && !loadingCandidates && (
          <HighlightedCandidatesCarousel
            onOpenShareWithModal={onOpenShareWithModal}
            setShareWithCandidateInfo={setShareWithCandidateInfo}
            handleOnRemoveSaveCandidate={handleOnRemoveSaveCandidate}
            handleOnSaveCandidate={handleOnSaveCandidate}
            highlightedCandidatesTitle={highlightedCandidatesTitle}
            highlightedCandidatesSubtitle={highlightedCandidatesSubtitle}
            isFilterSelected={isFilterSelected}
            isInLimitedMode={isInLimitedMode}
            highlightedCandidates={highlightedCandidates}
          />
        )}
        {Boolean(candidates.length) && (
          <Grid
            mt={6}
            gap={6}
            templateColumns={['1fr', '1fr 1fr', 'repeat(3, 1fr)']}
            autoColumns="1fr"
          >
            {isInLimitedMode && (
              <Center
                p={6}
                height="full"
                flexDir="column"
                textAlign="center"
                border="1px solid"
                borderColor="ui.secondary"
                backgroundColor="bg.tertiary"
                __css={{
                  // ! this is to sort it at the end of the first line
                  gridRowEnd: 2,
                  gridColumnStart: candidates.length > 1 ? 3 : 2,
                }}
              >
                <Image mx="auto" maxH={48} src={contactus} />
                <Heading mt="6" variant="heading-2" color="text.primary">
                  {candidatesTotalLength < 10 ? 'Want to see more?' : 'Ready to Hire?'}
                </Heading>
                <Text color="text.primary" mt="1">
                  {candidatesTotalLength < 10
                    ? `Our global sourcing team is always searching for more Talent. Schedule a call today to tell us about your unique needs and we will quickly find you more matching candidates.`
                    : `As your partner for global engineering talent, we want to learn about your team’s unique hiring needs and set you up for success!`}
                </Text>
                <Button
                  mt={6}
                  variant="solid"
                  colorScheme="primary"
                  size="lg"
                  type="button"
                  onClick={onOpenShareWithModal}
                  width="full"
                >
                  {candidatesTotalLength < 10 ? 'Talk to Us' : `Let’s Build Your Team`}
                </Button>
              </Center>
            )}
            {candidates.map((candidate) => (
              <CandidateCard
                isInLimitedMode={isInLimitedMode}
                key={candidate.id}
                candidate={candidate}
                linkTo={`/explore-candidates/candidate/${candidate.id}`}
                handleOnRemoveSaveCandidate={handleOnRemoveSaveCandidate}
                handleOnSaveCandidate={handleOnSaveCandidate}
                handleOpenShareWithOthers={() => {
                  if (!isInLimitedMode)
                    setShareWithCandidateInfo({ id: candidate.id, name: candidate.fullName });
                  onOpenShareWithModal();
                }}
              />
            ))}
          </Grid>
        )}
        {otherCandidates && Boolean(otherCandidates.length) && (
          <>
            <Heading variant="heading-3" mt={4}>
              Other Candidates you may be interested in
            </Heading>
            <Grid
              mt={4}
              gap={4}
              templateColumns={['1fr', '1fr 1fr', 'repeat(3, 1fr)']}
              autoColumns="1fr"
            >
              {otherCandidates.map((candidate) => (
                <CandidateCard
                  isInLimitedMode={isInLimitedMode}
                  key={candidate.id}
                  candidate={candidate}
                  linkTo={`/explore-candidates/candidate/${candidate.id}`}
                  handleOnRemoveSaveCandidate={handleOnRemoveSaveCandidate}
                  handleOnSaveCandidate={handleOnSaveCandidate}
                  handleOpenShareWithOthers={() => {
                    if (!isInLimitedMode)
                      setShareWithCandidateInfo({ id: candidate.id, name: candidate.fullName });
                    onOpenShareWithModal();
                  }}
                />
              ))}
            </Grid>
          </>
        )}
        {(loadingCandidates ||
          (selectedYoE
            ? candidates.length === 0 &&
              !otherCandidates?.length &&
              highlightedCandidates.length === 0
            : candidates.length === 0 && highlightedCandidates.length === 0)) && (
          <Flex justifyContent="center" my={6}>
            <Spinner
              thickness="4px"
              emptyColor="ui.secondary"
              color="brand.main"
              size="xl"
              aria-label="Loading"
              role="status"
            />
          </Flex>
        )}
        {/* Share candidate with modal */}
        {!isInLimitedMode && !!shareWithCandidateInfo?.id && (
          <ShareWithOthersModal
            onClose={onCloseShareWithModal}
            candidateInfo={shareWithCandidateInfo}
            isOpen={isShareWithModalOpen}
            teamMembers={serializeTeamMembersToShareWith(teamMembers, shareWithCandidateInfo?.id)}
            handleInsert={handleInsertSharedWith}
          />
        )}
      </>
    </Layout>
  );
}
