import { Link as RouterLink } from 'react-router-dom';
import { VStack, Heading, Text, HStack, Divider, Box, Link } from '@terminal/design-system';
import { Card, CardHeader, CardBody } from 'talent-hub/components';

import type { WeekSummariesProps } from '../../data';
import { RoleLayout } from '../../components/RoleLayout';

export function RoleWeeklySummaries({
  weeklySummaries,
  roleLayoutProps,
  activeRole,
}: {
  roleLayoutProps: React.ComponentProps<typeof RoleLayout>;
  weeklySummaries: WeekSummariesProps;
  activeRole: string;
}) {
  return (
    <RoleLayout {...roleLayoutProps}>
      <VStack px={12} py={10} spacing={6}>
        {weeklySummaries.map((weekSummary) => (
          <Card key={`week-${weekSummary.dateRange}`}>
            <CardHeader>
              <Heading variant="heading-3" color="brand.darker">
                Weekly Report: {weekSummary.dateRange}
              </Heading>
            </CardHeader>
            <CardBody px={2}>
              <Heading variant="heading-3">Stats</Heading>
              <>
                {weekSummary.bucketSummaries.map((bucketSummary) => (
                  <Box key={`bucket-summary${weekSummary.dateRange}-${bucketSummary.title}`} mt={4}>
                    <HStack pl={2} borderLeft="4px" borderLeftColor="brand.main">
                      <Text variant="label">
                        {bucketSummary.title}: {bucketSummary.week.total}
                      </Text>
                      <Divider orientation="vertical" py={3} />
                      <Text>All Time: {bucketSummary.allTime}</Text>
                    </HStack>
                    <Text pl={3} variant="caption">
                      {bucketSummary.week.candidateNames}
                    </Text>
                  </Box>
                ))}
              </>
              <Heading variant="heading-3" mt={6}>
                Candidate Activity
              </Heading>
              {!weekSummary.candidatesActivityReviews.length ? <Text>No Activity</Text> : null}
              {weekSummary.candidatesActivityReviews.map((candidateActivityReview, index) => (
                <HStack
                  // eslint-disable-next-line react/no-array-index-key
                  key={`candidate-activity-${weekSummary.dateRange}-${candidateActivityReview.id}-${index}`}
                  mt={4}
                >
                  <Link
                    to={`/role/${activeRole}/candidate/${candidateActivityReview.id}`}
                    as={RouterLink}
                    display="inline-block"
                  >
                    {candidateActivityReview.name}
                  </Link>
                  <Divider w={2} />
                  <Text variant="caption">
                    {`${candidateActivityReview.activity}  ${candidateActivityReview.status.title} on ${candidateActivityReview.status.date}`}
                  </Text>
                </HStack>
              ))}
              {/* <h5 className="mt-4 mb-4">Additional Notes</h5> */}
              {/* <p>- 5 screenings scheduled for next week</p> */}
              {/* <p>- Focusing on building a strong pipeline</p> */}
            </CardBody>
          </Card>
        ))}
      </VStack>
    </RoleLayout>
  );
}
