import type { KeyboardEvent } from 'react';
import { useCustomFormik } from 'global/utils/useCustomFormik';
import * as Yup from 'yup';
import {
  Heading,
  Flex,
  FormControl,
  FormLabel,
  MultiSelectTypeahead,
  Typeahead,
  FormErrorMessage,
  Textarea,
  Input,
  Select,
  CheckboxToggle,
  Checkbox,
  Grid,
  Button,
  WarningCircleSolidIcon,
  Divider,
  Text,
  Box,
} from '@terminal/design-system';
import { RadioButton } from 'talent-hub/components';

import {
  Employment_Type_Choices_Enum,
  Job_Status_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';
import { useState } from 'react';
import { getErrorMessage } from 'global/utils';
import type { serializeCreateJobOptions, serializeSelectJob } from './CreateEditJob.serializer';
import type { InsertJobMutationVariables, FormData } from './data';
import { serializeInsertJobVariables } from './CreateEditJob.serializer';

const validationSchema = Yup.object().shape({
  jobTitle: Yup.string().required('This is a required field'),
  numberPositions: Yup.string().required('This is a required field'),
  jobType: Yup.string().required('This is a required field'),
  employmentType: Yup.string().required('This is a required field'),
  contractLength: Yup.string().when('employmentType', {
    is: (employmentType: string) => employmentType === Employment_Type_Choices_Enum.Contract,
    then: Yup.string().required('This is a required field'),
    otherwise: Yup.string().nullable(),
  }),
  locations: Yup.array()
    .of(Yup.string().required('Choose from acceptable locations'))
    .min(1, 'Choose from acceptable locations'),
  useTerminalRange: Yup.boolean().required(),
  minContractRate: Yup.number().when(['employmentType', 'useTerminalRange'], {
    is: (employmentType: string, useTerminalRange: boolean) =>
      employmentType === Employment_Type_Choices_Enum.Contract && useTerminalRange === false,
    then: Yup.number().required('Field is required'),
    otherwise: Yup.number().nullable(),
  }),
  maxContractRate: Yup.number().when(['employmentType', 'useTerminalRange'], {
    is: (employmentType: string, useTerminalRange: boolean) =>
      employmentType === Employment_Type_Choices_Enum.Contract && useTerminalRange === false,
    then: Yup.number().required('Field is required'),
    otherwise: Yup.number().nullable(),
  }),
  minimumSalary: Yup.number().when(['employmentType', 'useTerminalRange'], {
    is: (employmentType: string, useTerminalRange: boolean) =>
      employmentType === Employment_Type_Choices_Enum.FullTime && useTerminalRange === false,
    then: Yup.number().required('Field is required'),
    otherwise: Yup.number().nullable(),
  }),
  maximumSalary: Yup.number().when(['employmentType', 'useTerminalRange'], {
    is: (employmentType: string, useTerminalRange: boolean) =>
      employmentType === Employment_Type_Choices_Enum.FullTime && useTerminalRange === false,
    then: Yup.number().required('Field is required'),
    otherwise: Yup.number().nullable(),
  }),
  offeringBonus: Yup.string().when('employmentType', {
    is: (employmentType: string) => employmentType === Employment_Type_Choices_Enum.FullTime,
    then: Yup.string().required('This is a required field'),
    otherwise: Yup.string().nullable(),
  }),
  offeringEquity: Yup.string(),
  level: Yup.string().required('This is a required field'),
  techStack: Yup.array().of(
    Yup.object()
      .shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
      .required('This is a required field'),
  ),
  yearsExperience: Yup.string().required('This is a required field'),
  requiredSkills: Yup.array()
    .of(
      Yup.object()
        .shape({
          value: Yup.string().required(),
          label: Yup.string().required(),
        })
        .required('This is a required field'),
    )
    .min(1, 'This is a required field')
    .max(3, 'Maximum 3 options allowed'),
  educationRequired: Yup.string().required('This is a required field'),
  niceToHaveSkills: Yup.array().of(
    Yup.object().shape({
      value: Yup.string().required(),
      label: Yup.string().required(),
    }),
  ),
  aboutRole: Yup.string(),
  whatYouDo: Yup.string(),
  whatYouBring: Yup.string(),
  hiringManager: Yup.string(),
  additionalNotes: Yup.string(),
});

const bullet = '\u2022';
const handleKeyUp = (event: KeyboardEvent<HTMLTextAreaElement>) => {
  const target = event.target as HTMLTextAreaElement;

  if (event.key !== '-') return;

  let parsedValue = target.value?.replaceAll('\n-', `\n${bullet}`) || '';

  if (parsedValue.startsWith('-')) {
    parsedValue = parsedValue.replace('-', `\u2022`);
  }

  target.value = parsedValue;
};

export function CreateJobForm({
  options,
  handleSubmit,
  viewingOrg,
  initialValues,
}: {
  options: ReturnType<typeof serializeCreateJobOptions>['options'];
  handleSubmit: (formData: InsertJobMutationVariables) => void;
  viewingOrg: number;
  initialValues: ReturnType<typeof serializeSelectJob>;
}) {
  const [draftInputError, setDraftInputError] = useState('');

  const {
    headCounts,
    jobTypes,
    acceptableLocations,
    jobLevelChoices,
    techStack,
    yearsOfExperience,
    educationRequirement,
    requiredSkills,
    niceToHaveSkills,
    organizationUsers,
    employmentTypes,
    contactLengths,
  } = options;

  const {
    values,
    errors,
    handleBlur,
    handleSubmit: formikHandleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    touched,
  } = useCustomFormik<FormData>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: () => {
      const organizationUserId = organizationUsers.find((el) => el.label === values.hiringManager);
      handleSubmit(
        serializeInsertJobVariables(
          values,
          viewingOrg,
          Job_Status_Choices_Enum.Submitted,
          organizationUserId?.value,
        ),
      );
    },
  });

  const handleSaveDraft = async () => {
    // [TAL-718] We need to do this validation manually without using validateField() formik utility because on our custom hook useCustomFormik
    // we are ignoring the validation errors when we are not submitting the form and if we change that, we break the submit/create new role flow
    try {
      await Yup.reach(validationSchema, 'jobTitle').validate(values.jobTitle);
      const organizationUserId = organizationUsers.find((el) => el.label === values.hiringManager);

      setDraftInputError('');
      handleSubmit(
        serializeInsertJobVariables(
          values,
          viewingOrg,
          Job_Status_Choices_Enum.Draft,
          organizationUserId?.value,
        ),
      );
    } catch (error) {
      setDraftInputError(getErrorMessage(error));
    }
  };

  return (
    <Flex
      w="full"
      h="full"
      flexDir="column"
      py={7}
      px={[6, 6, 10]}
      mt={[6, 6, 0]}
      bg="bg.primary"
      border="1px solid"
      borderColor="ui.secondary"
    >
      <Heading variant="heading-2">Basic Job Information</Heading>
      <Grid mt={7} gap={[4, 4, 6]} gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}>
        <FormControl
          isInvalid={(touched.jobTitle && !!errors.jobTitle) || !!draftInputError}
          isRequired
        >
          <FormLabel htmlFor="jobTitle" mb={2}>
            Job Title
          </FormLabel>
          <Input
            id="jobTitle"
            name="jobTitle"
            placeholder="Enter a job title"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.jobTitle}
          />
          {errors.jobTitle && (
            <FormErrorMessage>
              <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.jobTitle}
            </FormErrorMessage>
          )}
          {!!draftInputError && (
            <FormErrorMessage>
              <WarningCircleSolidIcon color="ui.error" mr={2} /> {draftInputError}
            </FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={touched.numberPositions && !!errors.numberPositions} isRequired>
          <FormLabel htmlFor="numberPositions" mb={2}>
            Number of Positions
          </FormLabel>
          <Select
            id="numberPositions"
            name="numberPositions"
            placeholder="Select"
            onChange={handleChange}
            onBlur={handleBlur}
            color={values.numberPositions ? 'text.primary' : 'text.disabled'}
            style={{
              color: 'inherit',
            }}
          >
            {headCounts.map((head) => (
              <option
                value={head.value}
                key={head.value}
                selected={values.numberPositions === head.value}
              >
                {head.label}
              </option>
            ))}
          </Select>
          {errors.numberPositions && (
            <FormErrorMessage>
              <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.numberPositions}
            </FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={touched.jobType && !!errors.jobType} isRequired>
          <FormLabel htmlFor="jobType" mb={2}>
            Job Type/Family
          </FormLabel>
          <Select
            id="jobType"
            name="jobType"
            placeholder="Select"
            onChange={handleChange}
            onBlur={handleBlur}
            color={values.jobType ? 'text.primary' : 'text.disabled'}
            style={{
              color: 'inherit',
            }}
          >
            {jobTypes.map((jobType) => (
              <option
                value={jobType.value}
                key={jobType.value}
                selected={values.jobType === jobType.value}
              >
                {jobType.label}
              </option>
            ))}
          </Select>
          {errors.jobType && (
            <FormErrorMessage>
              <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.jobType}
            </FormErrorMessage>
          )}
        </FormControl>
        <Flex gap={[4, 4, 6]}>
          <FormControl isInvalid={touched.employmentType && !!errors.employmentType} isRequired>
            <FormLabel htmlFor="employmentType" mb={2}>
              Employment Type
            </FormLabel>
            <Select
              id="employmentType"
              name="employmentType"
              onChange={(e) => {
                if (e.target.value === Employment_Type_Choices_Enum.FullTime) {
                  setValues({
                    ...values,
                    useTerminalRange: false,
                    minContractRate: null,
                    maxContractRate: null,
                    contractLength: '',
                  });
                } else if (e.target.value === Employment_Type_Choices_Enum.Contract) {
                  setValues({
                    ...values,
                    useTerminalRange: false,
                    minimumSalary: null,
                    maximumSalary: null,
                    offeringBonus: '',
                    offeringEquity: '',
                  });
                }
                handleChange(e);
              }}
              onBlur={handleBlur}
              style={{
                color: 'inherit',
              }}
            >
              {employmentTypes.map((employmentType) => (
                <option
                  value={employmentType.value}
                  key={employmentType.value}
                  selected={values.employmentType === employmentType.value}
                >
                  {employmentType.label}
                </option>
              ))}
            </Select>
            {errors.employmentType && (
              <FormErrorMessage>
                <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.employmentType}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isInvalid={!!errors.contractLength}
            isRequired={values.employmentType === Employment_Type_Choices_Enum.Contract}
            isDisabled={values.employmentType !== Employment_Type_Choices_Enum.Contract}
          >
            <FormLabel htmlFor="contractLength" mb={2}>
              Contract Length
            </FormLabel>
            <Select
              id="contractLength"
              name="contractLength"
              placeholder="Select"
              onChange={handleChange}
              onBlur={handleBlur}
              color={values.contractLength ? 'text.primary' : 'text.disabled'}
              style={{
                color:
                  values.employmentType === Employment_Type_Choices_Enum.Contract ? 'inherit' : '',
              }}
            >
              {contactLengths.map((contractLength) => (
                <option
                  value={contractLength.value}
                  key={contractLength.value}
                  selected={values.contractLength === contractLength.value}
                >
                  {contractLength.label}
                </option>
              ))}
            </Select>
            {errors.contractLength && (
              <FormErrorMessage>
                <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.contractLength}
              </FormErrorMessage>
            )}
          </FormControl>
        </Flex>
      </Grid>
      <Flex mt={[4, 4, 6]}>
        <FormControl isInvalid={touched.locations && !!errors.locations} isRequired>
          <FormLabel htmlFor="locations" w="fit-content">
            Acceptable Locations
          </FormLabel>
          <Flex flexWrap="wrap">
            {acceptableLocations.map((location) => (
              <CheckboxToggle
                key={location.value}
                id="locations"
                name="locations"
                onChange={handleChange}
                value={location.value}
                mr={3}
                mt={2}
                isChecked={values.locations.includes(location.value)}
              >
                {location.label}
              </CheckboxToggle>
            ))}
          </Flex>
          {errors.locations && (
            <FormErrorMessage>
              <WarningCircleSolidIcon color="ui.error" mr={2} /> Choose from acceptable locations
            </FormErrorMessage>
          )}
        </FormControl>
      </Flex>

      <Divider mb={10} mt={8} />

      <Heading variant="heading-2">Salary Information</Heading>
      {values.employmentType === Employment_Type_Choices_Enum.FullTime ? (
        <Grid mt={7} gap={[4, 4, 6]} gridTemplateColumns={['1fr', '1fr', '2fr 1fr 1fr']}>
          <FormControl
            isInvalid={
              (touched.minimumSalary && !!errors.minimumSalary) ||
              (touched.maximumSalary && !!errors.maximumSalary)
            }
            isRequired
          >
            <FormLabel htmlFor="minimumSalary" mb={2}>
              Salary Range (USD)
            </FormLabel>
            <Flex alignItems="center" mb={2}>
              <Input
                id="minimumSalary"
                name="minimumSalary"
                key="minimumSalary"
                placeholder="Minimum Salary"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.minimumSalary ? values.minimumSalary : ''} // * to hide 0
                disabled={values.useTerminalRange}
              />
              <Text mx={2}>to</Text>
              <Input
                id="maximumSalary"
                name="maximumSalary"
                key="maximumSalary"
                placeholder="Maximum Salary"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.maximumSalary ? values.maximumSalary : ''} // * to hide 0
                disabled={values.useTerminalRange}
              />
            </Flex>
            <Checkbox
              variant="square"
              mt={2}
              name="useTerminalRange"
              onChange={(e) => {
                if (e.target.checked) {
                  setValues({
                    ...values,
                    minimumSalary: 0,
                    maximumSalary: 0,
                  });
                }
                handleChange(e);
              }}
              isChecked={values.useTerminalRange}
            >
              <Text>I want to use Terminal&apos;s recommended ranges</Text>
            </Checkbox>
            {(!!errors.minimumSalary || !!errors.maximumSalary) && (
              <FormErrorMessage>
                <WarningCircleSolidIcon color="ui.error" mr={2} /> Enter a valid range or use
                Terminal&apos;s recommended ranges
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            maxW={44}
            isInvalid={touched.offeringBonus && !!errors.offeringBonus}
            isRequired
          >
            <FormLabel htmlFor="offeringBonus" mb={2}>
              Offering Bonus?
            </FormLabel>
            <Flex gridGap={4}>
              <RadioButton
                id="offeringBonus"
                name="offeringBonus"
                onChange={handleChange}
                value="YES"
                isChecked={values.offeringBonus === 'YES'}
                mr={2}
              >
                <Box p={2} border="1px solid" borderColor="ui.secondary" borderRadius="base">
                  <Text textAlign="center">Yes</Text>
                </Box>
              </RadioButton>
              <RadioButton
                id="offeringBonus"
                name="offeringBonus"
                onChange={handleChange}
                value="NO"
                isChecked={values.offeringBonus === 'NO'}
              >
                <Box p={2} border="1px solid" borderColor="ui.secondary" borderRadius="base">
                  <Text textAlign="center">No</Text>
                </Box>
              </RadioButton>
            </Flex>
            {errors.offeringBonus && (
              <FormErrorMessage>
                <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.offeringBonus}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl maxW={44} isInvalid={touched.offeringEquity && !!errors.offeringEquity}>
            <FormLabel htmlFor="offeringEquity" mb={2}>
              Offering Equity?
            </FormLabel>
            <Flex gridGap={4}>
              <RadioButton
                id="offeringEquity"
                name="offeringEquity"
                onChange={handleChange}
                value="YES"
                isChecked={values.offeringEquity === 'YES'}
                mr={2}
              >
                <Box p={2} border="1px solid" borderColor="ui.secondary" borderRadius="base">
                  <Text textAlign="center">Yes</Text>
                </Box>
              </RadioButton>
              <RadioButton
                id="offeringEquity"
                name="offeringEquity"
                onChange={handleChange}
                value="NO"
                isChecked={values.offeringEquity === 'NO'}
              >
                <Box p={2} border="1px solid" borderColor="ui.secondary" borderRadius="base">
                  <Text textAlign="center">No</Text>
                </Box>
              </RadioButton>
            </Flex>
            {errors.offeringEquity && (
              <FormErrorMessage>
                <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.offeringEquity}
              </FormErrorMessage>
            )}
          </FormControl>
        </Grid>
      ) : (
        <Grid mt={7} gap={[4, 4, 6]} gridTemplateColumns={['1fr', '1fr', '2fr 1fr 1fr']}>
          <FormControl
            isInvalid={
              (touched.minContractRate && !!errors.minContractRate) ||
              (touched.maxContractRate && !!errors.maxContractRate)
            }
            isRequired
          >
            <FormLabel htmlFor="minContractRate" mb={2}>
              Contractor Rate Range (USD)
            </FormLabel>
            <Flex alignItems="center" mb={2}>
              <Input
                id="minContractRate"
                name="minContractRate"
                key="minContractRate"
                placeholder="Minimum Rate"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.minContractRate ? values.minContractRate : ''} // * to hide 0
                disabled={values.useTerminalRange}
              />
              <Text mx={2}>to</Text>
              <Input
                id="maxContractRate"
                name="maxContractRate"
                key="maxContractRate"
                placeholder="Maximum Rate"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.maxContractRate ? values.maxContractRate : ''} // * to hide 0
                disabled={values.useTerminalRange}
              />
            </Flex>
            <Checkbox
              variant="square"
              mt={2}
              name="useTerminalRange"
              onChange={(e) => {
                if (e.target.checked) {
                  setValues({
                    ...values,
                    minContractRate: 0,
                    maxContractRate: 0,
                  });
                }
                handleChange(e);
              }}
              isChecked={values.useTerminalRange}
            >
              <Text>I want to use Terminal&apos;s recommended ranges</Text>
            </Checkbox>
            {(!!errors.minContractRate || !!errors.maxContractRate) && (
              <FormErrorMessage>
                <WarningCircleSolidIcon color="ui.error" mr={2} /> Enter a valid range or use
                Terminal&apos;s recommended ranges
              </FormErrorMessage>
            )}
          </FormControl>
        </Grid>
      )}

      <Divider mb={10} mt={8} />

      <Heading variant="heading-2">Skills & Experience</Heading>
      <Grid mt={7} gap={[4, 4, 6]} gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}>
        <FormControl isInvalid={touched.level && !!errors.level} isRequired>
          <FormLabel htmlFor="level" mb={2}>
            Level
          </FormLabel>
          <Select
            id="level"
            name="level"
            placeholder="Select"
            onChange={handleChange}
            onBlur={handleBlur}
            color={values.level ? 'text.primary' : 'text.disabled'}
            style={{
              color: 'inherit',
            }}
          >
            {jobLevelChoices.map((el) => (
              <option value={el.value} key={el.value} selected={values.level === el.value}>
                {el.label}
              </option>
            ))}
          </Select>
          {errors.level && (
            <FormErrorMessage>
              <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.level}
            </FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={touched.techStack && !!errors.techStack}>
          <MultiSelectTypeahead
            noOptionsMatchedCopy="Tech Stack, comma-separated"
            shouldOpenOnFocus
            placeholder="Start typing a language or framework to add"
            options={techStack}
            fullScreen={{
              title: 'Tech Stack Skills',
            }}
            name="techStack"
            renderBefore={({ getLabelProps }) => (
              <FormLabel {...getLabelProps()} mb={2}>
                Tech Stack
              </FormLabel>
            )}
            containerProps={{
              display: 'flex',
              flexDirection: 'column',
              sx: {
                '& div': {
                  bg: 'bg.primary',
                },
              },
            }}
            onBlur={handleBlur}
            onSelectedItemsChange={(
              name: string,
              selectedValues: { value: string; label: string }[],
            ) => setFieldValue(name, selectedValues)}
            isInvalid={touched.techStack && !!errors.techStack}
            initialSelectedOptions={initialValues.techStack}
          />
          {errors.techStack && (
            <FormErrorMessage>
              <>
                <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.techStack}
              </>
            </FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={touched.yearsExperience && !!errors.yearsExperience} isRequired>
          <FormLabel htmlFor="yearsExperience" mb={2}>
            Years of Experience
          </FormLabel>
          <Select
            id="yearsExperience"
            name="yearsExperience"
            placeholder="Select"
            onChange={handleChange}
            onBlur={handleBlur}
            color={values.yearsExperience ? 'text.primary' : 'text.disabled'}
            style={{
              color: 'inherit',
            }}
          >
            {yearsOfExperience.map((el) => (
              <option
                value={el.value}
                key={el.value}
                selected={values.yearsExperience === el.value}
              >
                {el.label}
              </option>
            ))}
          </Select>
          {errors.yearsExperience && (
            <FormErrorMessage>
              <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.yearsExperience}
            </FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={touched.educationRequired && !!errors.educationRequired} isRequired>
          <FormLabel htmlFor="educationRequired" mb={2}>
            Education Required
          </FormLabel>
          <Select
            id="educationRequired"
            name="educationRequired"
            placeholder="Select"
            onChange={handleChange}
            onBlur={handleBlur}
            color={values.educationRequired ? 'text.primary' : 'text.disabled'}
            style={{
              color: 'inherit',
            }}
          >
            {educationRequirement.map((el) => (
              <option
                value={el.value}
                key={el.value}
                selected={values.educationRequired === el.value}
              >
                {el.label}
              </option>
            ))}
          </Select>
          {errors.educationRequired && (
            <FormErrorMessage>
              <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.educationRequired}
            </FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={touched.requiredSkills && !!errors.requiredSkills} isRequired>
          <MultiSelectTypeahead
            noOptionsMatchedCopy="Required skills, comma-separated"
            placeholder="Start typing required skills"
            shouldOpenOnFocus
            options={requiredSkills}
            fullScreen={{
              title: 'Required Skills (max. 3)',
            }}
            name="requiredSkills"
            renderBefore={({ getLabelProps }) => (
              <Flex alignItems="baseline">
                <FormLabel {...getLabelProps()} mb={2}>
                  Required Skills
                </FormLabel>
                <Text variant="hint">(max. 3)</Text>
              </Flex>
            )}
            containerProps={{
              display: 'flex',
              flexDirection: 'column',
              sx: {
                '& div': {
                  bg: 'bg.primary',
                },
              },
            }}
            onBlur={handleBlur}
            onSelectedItemsChange={(
              name: string,
              selectedValues: { value: string; label: string }[],
            ) => setFieldValue(name, selectedValues)}
            isInvalid={touched.requiredSkills && !!errors.requiredSkills}
            initialSelectedOptions={initialValues.requiredSkills}
            isDisabled={values.requiredSkills?.length >= 3}
          />
          {errors.requiredSkills && (
            <FormErrorMessage>
              <>
                <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.requiredSkills}
              </>
            </FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={touched.niceToHaveSkills && !!errors.niceToHaveSkills}>
          <MultiSelectTypeahead
            noOptionsMatchedCopy="Nice-to-Have Skills, comma-separated"
            placeholder="Start typing nice-to-have skills"
            shouldOpenOnFocus
            options={niceToHaveSkills}
            fullScreen={{
              title: 'Nice to have skills',
            }}
            name="niceToHaveSkills"
            renderBefore={({ getLabelProps }) => (
              <FormLabel {...getLabelProps()} mb={2}>
                Nice-to-Have Skills
              </FormLabel>
            )}
            containerProps={{
              display: 'flex',
              flexDirection: 'column',
              sx: {
                '& div': {
                  bg: 'bg.primary',
                },
              },
            }}
            onBlur={handleBlur}
            onSelectedItemsChange={(
              name: string,
              selectedValues: { value: string; label: string }[],
            ) => setFieldValue(name, selectedValues)}
            isInvalid={!!errors.niceToHaveSkills}
            initialSelectedOptions={initialValues.niceToHaveSkills}
          />
          {errors.niceToHaveSkills && (
            <FormErrorMessage>
              <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.niceToHaveSkills}
            </FormErrorMessage>
          )}
        </FormControl>
      </Grid>

      <Divider mb={10} mt={8} />

      <Heading variant="heading-2">Role Description Details</Heading>
      <Flex w="full" mt={7} flexDir="column" gridGap={6}>
        <FormControl isInvalid={touched.aboutRole && !!errors.aboutRole}>
          <FormLabel htmlFor="aboutRole" mb={2}>
            About the Role
          </FormLabel>
          <Textarea
            id="aboutRole"
            name="aboutRole"
            rows={8}
            placeholder="Enter summary information about the role and team"
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyUp={handleKeyUp}
            value={values.aboutRole}
            borderColor={touched.aboutRole && !!errors.aboutRole ? 'ui.error' : 'ui.secondary'}
          />
          {errors.aboutRole && (
            <FormErrorMessage>
              <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.aboutRole}
            </FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={touched.whatYouDo && !!errors.whatYouDo}>
          <FormLabel htmlFor="whatYouDo" mb={2}>
            What You&apos;ll Do
          </FormLabel>
          <Textarea
            id="whatYouDo"
            name="whatYouDo"
            rows={8}
            placeholder="Enter the day-to-day responsibilities of this role"
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyUp={handleKeyUp}
            value={values.whatYouDo}
            borderColor={touched.whatYouDo && !!errors.whatYouDo ? 'ui.error' : 'ui.secondary'}
          />
          {errors.whatYouDo && (
            <FormErrorMessage>
              <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.whatYouDo}
            </FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={touched.whatYouBring && !!errors.whatYouBring}>
          <FormLabel htmlFor="whatYouBring" mb={2}>
            What You&apos;ll Bring
          </FormLabel>
          <Textarea
            id="whatYouBring"
            name="whatYouBring"
            rows={8}
            placeholder="Enter requirements for this role"
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyUp={handleKeyUp}
            value={values.whatYouBring}
            borderColor={
              touched.whatYouBring && !!errors.whatYouBring ? 'ui.error' : 'ui.secondary'
            }
          />
          {errors.whatYouBring && (
            <FormErrorMessage>
              <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.whatYouBring}
            </FormErrorMessage>
          )}
        </FormControl>
      </Flex>

      {/* Hiring manager info */}
      <Flex bg="bg.tertiary" mt={9} p={6} flexDir="column">
        <Heading variant="heading-2" mt={2}>
          Hiring Manager Info
        </Heading>
        <Flex w="full" mt={7} flexDir="column" gridGap={6}>
          <FormControl isInvalid={touched.hiringManager && !!errors.hiringManager}>
            <Typeahead
              name="hiringManager"
              fullScreen={{
                title: 'Hiring Manager',
              }}
              placeholder="Enter hiring manager name"
              shouldOpenOnFocus
              options={organizationUsers.map((user) => user.label)}
              formInputValue={values.hiringManager}
              containerProps={{
                display: 'flex',
                flexDirection: 'column',
                sx: {
                  '& input': {
                    bg: 'bg.primary',
                  },
                },
              }}
              onBlur={(e) => {
                const hiringUser = organizationUsers.find((el) => el.label === e.target.value);
                if (!hiringUser?.value) {
                  setFieldValue('hiringManager', '');
                }
              }}
              onSelectionChange={(field, value) => setFieldValue(field, value)}
              onInputChange={(event) => setFieldValue('hiringManager', event.target.value)}
              renderBefore={({ getLabelProps }) => (
                <FormLabel {...getLabelProps()} htmlFor="hiringManager" mb={2}>
                  Hiring Manager
                </FormLabel>
              )}
            />
            {errors.hiringManager && (
              <FormErrorMessage>
                <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.hiringManager}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="additionalNotes" mb={2}>
              Additional Notes
            </FormLabel>
            <Textarea
              id="additionalNotes"
              name="additionalNotes"
              rows={8}
              placeholder="Enter any additional notes for Terminal about this role"
              onChange={handleChange}
              onKeyUp={handleKeyUp}
              value={values.additionalNotes}
            />
          </FormControl>
        </Flex>
      </Flex>

      {/* General error message */}
      {(Object.keys(errors).length > 0 || !!draftInputError) && (
        <Flex
          bg="ui.lightest.error"
          alignItems="center"
          justifyContent="center"
          p={6}
          mt={6}
          border="1px solid"
          borderColor="ui.error"
        >
          <WarningCircleSolidIcon color="ui.error" mr={2} />
          <Text color="text.error">
            {draftInputError !== ''
              ? 'Please enter a Job Title to save this role as a draft.'
              : 'There are some incomplete or invalid fields in the form above. Please complete them to continue.'}
          </Text>
        </Flex>
      )}
      <Flex direction={['column', 'row', 'row']} mt={6} justifyContent="center">
        <Button size="lg" onClick={() => formikHandleSubmit()} variant="solid">
          Submit
        </Button>
        <Button
          marginLeft={[0, 6, 6]}
          marginTop={[6, 0, 0]}
          size="lg"
          onClick={() => handleSaveDraft()}
          variant="outline"
        >
          Save Draft
        </Button>
      </Flex>
    </Flex>
  );
}
