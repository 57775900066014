export enum ViewType {
  SEARCH = 'search',
  SAVED = 'saved',
  SHARED = 'shared',
  DASHBOARD = 'dashboard',
}

const highlightBadgeValues = [null, 'top_company_exp', 'years_tech_lead_exp', 'in_demand'] as const;

export type FeatureFlagHighlightBadge = (typeof highlightBadgeValues)[number];

export function isFeatureFlagHighlightBadge(
  value: string | null,
): value is FeatureFlagHighlightBadge {
  return highlightBadgeValues.includes(value as FeatureFlagHighlightBadge);
}
