import moment from 'moment';
import {
  determineRoleHealth,
  determinePositionHealth,
  selectDateByFavoringApprovedDate,
} from 'talent-hub/utils';
import { JobHealthStatus } from 'talent-hub/constants';
import { Job_Status_Choices_Enum } from 'global/types';
import type {
  RoleDataQuery,
  RoleFirstSubmission,
  RoleOfferAccepted,
  RoleOfferMade,
  RoleFirstInterview,
} from '../../data';

// Parts of the implementation of toPositionsProps function
const reduceToPositionProps =
  (
    totalActiveCandidates: any,
    totalSubmissions: any,
    totalHired: number,
    hiresDates: { [x: string]: any },
  ) =>
  ([accPositions, isAnyPositionHired]: any, positionCreateDate: any, index: number) => {
    const isPositionHired = index < totalHired;

    const positionHealthStatus = determinePositionHealth({
      isHired: isPositionHired,
      isAnyPositionHired: isPositionHired || isAnyPositionHired,
      positionCreateDate,
      roleTotalActiveCandidates: totalActiveCandidates,
      roleTotalSubmissions: totalSubmissions,
    });

    return [
      [
        ...accPositions,
        {
          hiredDate: hiresDates[index] ? moment(hiresDates[index]).format('MMMM D, YYYY') : null,
          healthStatus: positionHealthStatus,
          createDate: moment(positionCreateDate).format('MMM D, YYYY'),
          index,
        },
      ],
      isPositionHired || isAnyPositionHired,
    ];
  };

// Either uses the positions from the data or fills the positions from the posting headcount
// For each positions it returns hiredDate, healthStatus, createDate, index
function toPositionsProps({
  allPositions,
  roleCreateDate,
  totalActiveCandidates,
  totalCandidates,
  totalHired,
  hiresDates,
  totalPositions,
}: any) {
  const [rolePositions] = allPositions.length
    ? allPositions
        // Postings (role) and positions can have a created_date that is different than the datetime they were created
        // in ICIMS because they are assigned a new created date with each data transfer cron job based on the cron job
        // date. The accurate date which resembles the date they were created in ICIMS should be used instead.
        .map(selectDateByFavoringApprovedDate)
        .reduce(
          reduceToPositionProps(totalActiveCandidates, totalCandidates, totalHired, hiresDates),
          [[], false],
        )
    : [...Array(totalPositions)]
        .map(() => roleCreateDate)
        .reduce(
          reduceToPositionProps(totalActiveCandidates, totalCandidates, totalHired, hiresDates),
          [[], false],
        );

  return rolePositions;
}

// Determines the role health status and the role status's title and subtile
function toRoleProps({
  totalHired,
  totalPositions,
  totalActiveCandidates,
  totalCandidates,
  roleCreateDate,
  jobStatus,
}: {
  totalHired: number;
  totalPositions: number;
  totalActiveCandidates: number;
  totalCandidates: number;
  roleCreateDate: string;
  jobStatus: Job_Status_Choices_Enum | null;
}): { healthStatus: JobHealthStatus; statusTitle: string; statusSubtitle: string } {
  const roleHealthStatus = determineRoleHealth({
    totalHired,
    totalPositions,
    totalActiveCandidates,
    totalSubmissions: totalCandidates,
    roleCreateDate,
    jobStatus,
  });

  if (jobStatus === Job_Status_Choices_Enum.Paused)
    return { healthStatus: JobHealthStatus.Paused, statusTitle: 'Paused', statusSubtitle: '' };

  if (jobStatus === Job_Status_Choices_Enum.OnHold)
    return { healthStatus: JobHealthStatus.OnHold, statusTitle: 'On Hold', statusSubtitle: '' };

  let statusTitle = 'On Track';
  let statusSubtitle = '';

  if (roleHealthStatus === JobHealthStatus.OnTrack && totalHired > 0) {
    const totalHeadCounts = Math.max(0, totalPositions - totalHired);
    statusTitle = `${totalHeadCounts} Open ${totalHeadCounts !== 1 ? 'Positions' : 'Position'}`;
    statusSubtitle = `${totalHired} ${totalHired > 1 ? 'Hires' : 'Hire'}`;
  }

  if (roleHealthStatus === JobHealthStatus.WarningHighRejectionRate) {
    statusTitle = 'Warning: High Decline Rate';
  }

  if (roleHealthStatus === JobHealthStatus.WarningLate) {
    statusTitle = 'Warning: Running Behind';
  }

  return {
    healthStatus: roleHealthStatus,
    statusTitle,
    statusSubtitle,
  };
}

function toTotalHiresUpcomingText({
  totalHired,
  totalPositions,
  roleCreateDate,
  totalHireDateLabel,
  allPositions,
}: any): string {
  let totalHiresUpcomingText = '';
  if (totalHired === totalPositions) {
    totalHiresUpcomingText = 'All Hires Made';
  } else {
    const nextDate =
      allPositions.length > 0 && allPositions[totalHired]
        ? allPositions[totalHired].accurate_date?.last_approved ||
          allPositions[totalHired].accurate_date?.created_date ||
          allPositions[totalHired].created_at
        : roleCreateDate;

    totalHiresUpcomingText = !nextDate
      ? totalHireDateLabel
      : `${totalHireDateLabel}: ${moment(nextDate).add(90, 'days').format('MMMM D, YYYY')}`;
  }
  return totalHiresUpcomingText;
}

// This file was moved from src/talent-hub/features/roles/utils/serializeToJobOverviewMetrics.ts here
// The git history of this function can be found in that file.
export function serializerToProgressMetrics({
  hiresDates,
  totalCandidates,
  totalActiveCandidates,
  totalHired,
  role,
  totalHeadCounts,
}: {
  totalCandidates: number;
  totalActiveCandidates: number;
  totalHired: number;
  hiresDates: Date[];
  role: any; // TODO: add typescript here
  totalHeadCounts: number;
}):
  | {
      totalPositions: number;
      totalHiresUpcomingText: string;
      roleProps: { healthStatus: JobHealthStatus; statusTitle: string; statusSubtitle: string };
      positionsProps: never[];
    }
  | {
      totalHiresUpcomingText: string;
      roleProps: { healthStatus: JobHealthStatus; statusTitle: string; statusSubtitle: string };
      positionsProps: any;
      totalPositions?: undefined;
    } {
  if (role == null) {
    return {
      totalPositions: 0,
      totalHiresUpcomingText: '',
      roleProps: {
        healthStatus: JobHealthStatus.OnTrack,
        statusTitle: '',
        statusSubtitle: '',
      },
      positionsProps: [],
    };
  }

  const totalHireDateLabel = totalHired > 0 ? 'Next Expected Hire' : 'Expected First Hire';

  const positionsProps = toPositionsProps({
    allPositions: role.positions,
    roleCreateDate: role.openedDate,
    totalActiveCandidates,
    totalCandidates,
    totalHired,
    hiresDates,
    totalPositions: totalHeadCounts,
  });

  return {
    totalHiresUpcomingText: toTotalHiresUpcomingText({
      totalHired,
      totalPositions: totalHeadCounts,
      roleCreateDate: role.openedDate,
      totalHireDateLabel,
      allPositions: role.positions,
    }),
    roleProps: toRoleProps({
      totalHired,
      totalPositions: totalHeadCounts,
      totalActiveCandidates,
      totalCandidates,
      roleCreateDate: role.openedDate,
      jobStatus: role.job?.status,
    }),
    positionsProps,
  };
}

export function serializeToRoleMilestones(data: RoleDataQuery, roleOpenedDate?: string) {
  type DataKeys =
    | 'first_approved'
    | 'first_submission'
    | 'first_interview_scheduled'
    | 'first_offer_made'
    | 'first_offer_accepted';

  type DataKeyNumbers = [DataKeys, number];

  // To return props to show when the milestones when JobOverviewMetrics is fetching
  const createEmptyRoleMilestone = ([,]: DataKeyNumbers) => ({
    completed: false,
    date: '',
    datePrefix: 'Target for',
  });

  const createRoleMilestone = ([dataKey, numberOfDay]: DataKeyNumbers) => {
    const format = 'MMMM D, YYYY';

    // when completed
    if (dataKey === 'first_approved' || (data[dataKey] != null && data[dataKey].length)) {
      let date;

      if (dataKey === 'first_approved') {
        date = roleOpenedDate;
      } else {
        const milestone:
          | RoleFirstSubmission[number]
          | RoleFirstInterview[number]
          | RoleOfferMade[number]
          | RoleOfferAccepted[number]
          | undefined = data[dataKey][0];

        date = milestone?.created_at;
      }

      return {
        completed: true,
        date: moment(date).format(format),
        datePrefix: 'Completed',
      };
    }

    return {
      completed: false,
      date: moment(roleOpenedDate).add(numberOfDay, 'days').format(format),
      datePrefix: 'Target for',
    };
  };

  const dataKeysAndNumbers: DataKeyNumbers[] = [
    ['first_approved', 5],
    ['first_submission', 25],
    ['first_interview_scheduled', 40],
    ['first_offer_made', 70],
    ['first_offer_accepted', 90],
  ];

  const [milestone1, milestone2, milestone3, milestone4, milestone5] = dataKeysAndNumbers.map(
    roleOpenedDate ? createRoleMilestone : createEmptyRoleMilestone,
  );

  return { milestone1, milestone2, milestone3, milestone4, milestone5 };
}
