import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import type { FetchResult } from '@apollo/client';
import {
  DrawerCloseButton,
  DrawerBody,
  Button,
  Flex,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  PreferredIcon,
  ShareIcon,
  Box,
  Heading,
  BriefcaseIcon,
  DrawerFooter,
  CheckboxButton,
} from '@terminal/design-system';
import { BlankScreenLoading } from 'global/components';
import * as events from 'global/events';

import { CandidateInformation, PlayVideoIcon, ErrorFallback } from 'talent-hub/components';
import type {
  InsertSavedCandidateMutation,
  DeleteSavedCandidateMutation,
  useCandidateModalInfo_withInvitation,
} from 'talent-hub/utils';
import { useTalentHubFlags } from 'talent-hub/utils';
import type { Dispatch, SetStateAction } from 'react';
import type { ViewType } from '../../constants';

export function CandidateProfile({
  onResumeLinkClicked,
  loading,
  profile,
  onInviteRequestClick,
  isOpen,
  onClose,
  queryError,
  saveCandidateUtils: { handleOnRemoveSaveCandidate, handleOnSaveCandidate, isSaved, setIsSaved },
  onOpenShareWithModal,
  candidateHighlights,
  isInLimitedMode,
  viewPage,
}: {
  isInLimitedMode?: boolean;
  onResumeLinkClicked: () => void;
  loading: boolean;
  isOpen: boolean;
  profile: ReturnType<typeof useCandidateModalInfo_withInvitation>['profile'];
  onInviteRequestClick: () => void;
  onClose: () => void;
  queryError?: string | undefined;
  saveCandidateUtils: {
    handleOnSaveCandidate: (
      candidateId: number,
    ) => Promise<
      FetchResult<InsertSavedCandidateMutation, Record<string, any>, Record<string, any>>
    >;
    handleOnRemoveSaveCandidate: (
      candidateId: number,
    ) => Promise<
      FetchResult<DeleteSavedCandidateMutation, Record<string, any>, Record<string, any>>
    >;
    isSaved: boolean;
    setIsSaved: Dispatch<SetStateAction<boolean>>;
  };
  onOpenShareWithModal: () => void;
  candidateHighlights: ReturnType<
    typeof useCandidateModalInfo_withInvitation
  >['candidateHighlights'];
  viewPage: ViewType;
}) {
  const lasNameInitial = profile.lastNameInitial ? profile.lastNameInitial.concat('.') : '';
  const featureFlags = useTalentHubFlags();

  useEffect(() => {
    if (!profile.id) return;
    events.track(events.name.exploreCandidates.profileViewed, {
      candidate_id: profile.id,
      view_from_page: viewPage,
    });
  }, [viewPage, profile.id]);

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="3xl">
      <DrawerOverlay />
      <DrawerContent h="full" justifyContent="space-between">
        {queryError ? (
          <ErrorFallback
            subTitle="Error loading the Candidate info"
            useFullHeight={false}
            description={queryError}
            onActionClick={() => {
              window.location.reload();
            }}
            showBackButton
          />
        ) : (
          <>
            <DrawerBody p={0}>
              {loading ? (
                <BlankScreenLoading />
              ) : (
                <CandidateInformation
                  isInLimitedMode={isInLimitedMode}
                  profile={profile}
                  candidateHighlights={candidateHighlights}
                  handleResumeLinkClicked={onResumeLinkClicked}
                  subheader={
                    <>
                      <DrawerCloseButton color="brand.main" pos="relative" size="lg" top={0} />
                      <Box px={[6, 6, 14]} mt={3}>
                        {featureFlags.isContractorPilotEnabled && !!profile?.employmentType && (
                          <Flex alignItems="center" pb={1}>
                            <BriefcaseIcon w={6} h={6} mr={2} color="brand.main" />
                            <Heading variant="heading-3" color="brand.main">
                              {profile?.employmentType}
                            </Heading>
                          </Flex>
                        )}
                        <Flex flexDir={['column-reverse', 'row', 'row']} gridGap={9}>
                          <Box flex={1}>
                            <Heading variant="heading-1">
                              {profile.firstName}{' '}
                              {isInLimitedMode ? lasNameInitial : profile.lastName}
                            </Heading>
                            <Heading variant="heading-3" color="text.secondary">
                              {profile.currentRole}
                            </Heading>
                          </Box>
                          <Flex gridGap={2}>
                            <CheckboxButton
                              isChecked={isSaved}
                              colorScheme="accent"
                              p={4}
                              minW="auto"
                              h="fit-content"
                              lineHeight={0}
                              alignItems="center"
                              onClick={async (e) => {
                                e.preventDefault();
                                if (isSaved) {
                                  const { data } = await handleOnRemoveSaveCandidate(profile.id);
                                  if (data?.delete_saved_candidate?.returning[0]?.candidate_id) {
                                    setIsSaved((prev) => !prev);
                                  }
                                } else {
                                  const { data } = await handleOnSaveCandidate(profile.id);
                                  if (data?.insert_saved_candidate_one?.candidate_id) {
                                    setIsSaved((prev) => !prev);
                                  }
                                }
                              }}
                              checkedIcon={
                                <PreferredIcon
                                  sx={{
                                    '& path': {
                                      fill: '#EFD58B',
                                      stroke: 'brand.darkest',
                                    },
                                  }}
                                />
                              }
                              unCheckedIcon={<PreferredIcon color="ui.primary" />}
                            />
                            <Button
                              colorScheme="accent"
                              variant="outline"
                              p={4}
                              minW="auto"
                              h="fit-content"
                              lineHeight={0}
                              alignItems="center"
                              onClick={onOpenShareWithModal}
                            >
                              <ShareIcon />
                            </Button>
                          </Flex>
                        </Flex>
                      </Box>
                    </>
                  }
                />
              )}
            </DrawerBody>
            <DrawerFooter
              px={[4, 4, 14]}
              py={[4, 4, 6]}
              gridGap={4}
              boxShadow="reverse.md"
              display="flex"
              flexDir={['column', 'column', 'row']}
            >
              {!!profile?.interviewLink && (
                <Button
                  variant="outline"
                  size="lg"
                  leftIcon={<PlayVideoIcon w={4} h={4} mr={1} />}
                  to={{
                    pathname: profile?.interviewLink?.startsWith('http')
                      ? profile?.interviewLink
                      : `//${profile?.interviewLink}`,
                  }}
                  rel="noopener noreferrer"
                  target="_blank"
                  as={RouterLink}
                  width="full"
                  mb={[1, 1, 0]}
                >
                  View Interview Clips
                </Button>
              )}
              <Button
                variant="solid"
                size="lg"
                width={profile?.interviewLink ? 'full' : ['full', 'full', '50%']}
                onClick={() => {
                  onInviteRequestClick();
                  events.track(events.name.ctaClicked, {
                    name: featureFlags.candidateProfileInviteRequestButtonText,
                    context: 'Explore Candidates - Candidate Profile',
                  });
                }}
              >
                {featureFlags.candidateProfileInviteRequestButtonText}
              </Button>
            </DrawerFooter>
          </>
        )}
      </DrawerContent>
    </Drawer>
  );
}
