import { createDaysAgoDates, toHasuraDate } from 'global/utils';
import type {
  SelectDashboardQuery,
  DashboardPosting,
  DashboardApplicantWorkflow,
  DashboardWorkflowStatus,
  DashboardPerson,
} from './graphql';

const daysToGo = createDaysAgoDates([3, 10, 17, 20, 100]);

function createPosting(overwrite?: Partial<DashboardPosting>): DashboardPosting {
  return {
    id: 1042,
    name: 'Fast food restaurant manager',
    accurate_date: {
      created_date: null,
      last_approved: null,
    },
    icims_folder: {
      value: 'Approved',
    },
    applicant_workflows: [],
    initial_headcount: 3,
    created_at: toHasuraDate('2020-11-11'),
    positions: [],
    job: null, // TODO: Finish this
    ...overwrite,
  };
}

function createPosting_applicantWorkflow_statuses(
  datesAndStatuses: [string, string][],
): DashboardWorkflowStatus[] {
  return datesAndStatuses.map(([date, status]) => ({
    // date: toHasuraDate(date),
    date,
    status: {
      title: status,
    },
  }));
}

function createPerson(overwrites: Partial<DashboardPerson> = {}): DashboardPerson {
  return {
    candidate: {
      id: 1,
      firstname: 'Lindsay',
      lastname: 'Brown',
      email: 'LindsayBrown@yahoo.com',
      work_experience: [],
    },
    email: 'LindsayBrown@yahoo.com',
    profile_id: 328,
    firstname: null,
    lastname: null,
    ...overwrites,
  };
}

function createPosting_applicantWorkflow(
  overwrites: Partial<DashboardApplicantWorkflow> = {},
): DashboardApplicantWorkflow {
  return {
    job_profile_id: 1062,
    icims_person: createPerson(),
    workflow_statuses: createPosting_applicantWorkflow_statuses([
      [toHasuraDate('2020-12-16'), 'Internal Interview - Interview to be Scheduled'],
    ]),
    ...overwrites,
  };
}

function create_inProgressSubmission_candidate(
  overwrites: Partial<DashboardApplicantWorkflow> = {},
  lastStatusDate = daysToGo[17],
) {
  return createPosting_applicantWorkflow({
    workflow_statuses: createPosting_applicantWorkflow_statuses([
      [daysToGo[20], 'New Applicant - External Portal'],
      [lastStatusDate, 'Submitted to Client - Submitted'],
    ]),
    ...overwrites,
  });
}

function create_rejectedInInterview_candidate(
  overwrites: Partial<DashboardApplicantWorkflow> = {},
  lastStatusDate = daysToGo[10],
) {
  return createPosting_applicantWorkflow({
    workflow_statuses: createPosting_applicantWorkflow_statuses([
      [daysToGo[20], 'New Applicant - External Portal'],
      [daysToGo[17], 'Submitted to Client - Submitted'],
      [daysToGo[17], 'Client Review - Interview Scheduled'],
      [lastStatusDate, 'Candidate Withdrew - Self-Withdrew (Portal)'],
    ]),
    ...overwrites,
  });
}

function createMembersCount(count = 0): SelectDashboardQuery['members'] {
  return {
    aggregate: {
      count,
    },
  };
}

function createDashboard(overwrite?: Partial<SelectDashboardQuery>): SelectDashboardQuery {
  return {
    job: [],
    active_postings: [
      createPosting({
        id: 10,
        created_at: daysToGo[3],
      }),
      createPosting({
        id: 11,
        name: 'Police officer',
        initial_headcount: 1,
        created_at: daysToGo[100],
        applicant_workflows: [
          create_inProgressSubmission_candidate(
            {
              workflow_statuses: createPosting_applicantWorkflow_statuses([
                [toHasuraDate('2020-05-15'), 'Client Review - Interview Completed'],
              ]),
              icims_person: createPerson({
                candidate: {
                  id: 123,
                  firstname: 'David',
                  lastname: 'Harrison',
                  email: 'smithchristine@yahoo.com',
                  work_experience: [{ title: 'Diagnostic radiographer' }],
                },
                profile_id: 572,
              }),
            },
            daysToGo[3],
          ),
        ],
      }),
      createPosting({
        id: 11,
        name: null,
        initial_headcount: 1,
        created_at: daysToGo[100],
        applicant_workflows: [
          create_inProgressSubmission_candidate(
            {
              icims_person: createPerson({
                candidate: {
                  id: 12,
                  firstname: 'Tony',
                  lastname: 'Stark',
                  email: 'LindsayBrown@yahoo.com',
                  work_experience: [{ title: 'Data Analyst' }],
                },
                profile_id: 11,
              }),
            },
            toHasuraDate('2022-09-18'),
          ),
        ],
      }),
      createPosting({
        id: 11,
        name: 'Senior software developer',
        initial_headcount: 1,
        created_at: daysToGo[100],
        applicant_workflows: [
          create_inProgressSubmission_candidate(
            {
              workflow_statuses: createPosting_applicantWorkflow_statuses([
                [toHasuraDate('2020-05-15'), 'Client Review - Interview #1 to be Scheduled'],
              ]),
              icims_person: createPerson({
                candidate: {
                  id: 12,
                  firstname: 'Cynthia',
                  lastname: 'Baldwin',
                  email: 'dawn98@dudley.com',
                  work_experience: [{ title: 'Software Developer' }],
                },
                profile_id: 11,
              }),
            },
            daysToGo[3],
          ),
        ],
      }),
      createPosting({
        id: 11,
        name: 'Business Development Representative',
        initial_headcount: 1,
        created_at: daysToGo[100],
        applicant_workflows: [
          create_inProgressSubmission_candidate({}, toHasuraDate('2022-09-18')),
        ],
      }),
      createPosting({
        id: 11,
        name: 'Backend Engineer',
        initial_headcount: 1,
        created_at: daysToGo[100],
        applicant_workflows: [create_rejectedInInterview_candidate({}, daysToGo[3])],
      }),
      createPosting({
        id: 12,
        name: 'Diagnostic radiographer',
        initial_headcount: 4,
        created_at: daysToGo[100],
      }),
    ],
    members: createMembersCount(0),
    ...overwrite,
  };
}

export const SelectDashboardFixture = {
  createDashboard,
  createPosting,
  createPosting_applicantWorkflow,
  create_inProgressSubmission_candidate,
  create_rejectedInInterview_candidate,
  createPosting_applicantWorkflow_statuses,
  createPerson,
  createMembersCount,
};
