import { JOB_FOLDER_APPROVED, JOB_FOLDER_FILLED } from 'talent-hub/constants';
import { useLocalStorage, useQuery } from 'global/utils';
import { useCustomerAuthorizedUserSession, useSavedCandidates } from 'talent-hub/utils';
import { candidateSearchDateRange, ViewType } from 'talent-hub/shared/features';
import { sortOptionBackendValues } from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/ExploreCandidates.serializer';

// TODO: Carlos - move these to a shared place
import type {
  SelectDashboardBrowseCandidatesQuery,
  SelectDashboardBrowseCandidatesQueryVariables,
} from 'talent-hub/role/prospect/dashboard/data';
import { SelectDashboardBrowseCandidates } from 'talent-hub/role/prospect/dashboard/data';
import { useHistory, useParams } from 'react-router-dom';
import { CandidateProfileController } from '../explore-candidates/candidate-profile';

import { Dashboard } from './Dashboard';
import type { SelectDashboardQuery, SelectDashboardQueryVariables } from './data';
import { SelectCandidateMatchesByRole, SelectDashboard } from './data';
import {
  serializeCandidateMatchesByRole,
  serializeDashboard,
  serializeDashboardOtherGreatCandidates,
} from './Dashboard.serializer';
import type {
  SelectCandidateMatchesByRoleQuery,
  SelectCandidateMatchesByRoleQueryVariables,
} from './data/graphql/types/SelectCandidateMatchesByRole.query.generated';

const browseOtherCandidatesQuery_defaultVariables = ({
  organizationID,
  userID,
  isClientProspect,
}: {
  organizationID: number;
  userID: number;
  isClientProspect: boolean;
}) => {
  const candidateBrowseArgs = {
    organization_id: organizationID,
    candidate_role: null,
    must_have_skill_ids: [],
    should_have_skill_ids: [],
    regions: null,
    // country: null,
    min_years_of_experience: null,
    sort_option: sortOptionBackendValues.Recommended,
    employment_type: null,
    badges: null,
  };

  return {
    organization: organizationID,
    user_id: userID,
    updatedAfter: candidateSearchDateRange,
    isClientProspect,
    candidate_recently_active_args: {
      ...candidateBrowseArgs,
      sort_option: null,
    },
    candidate_top_company_experience_args: {
      ...candidateBrowseArgs,
      badges: ['top_company_exp'],
    },
    candidate_zero_one_experience_args: {
      ...candidateBrowseArgs,
      badges: ['built_new'],
    },
    candidate_tech_lead_args: {
      ...candidateBrowseArgs,
      badges: ['tech_leader'],
    },
  };
};

export function DashboardController() {
  const [active_roles_bookmark_ids] = useLocalStorage<number[]>('active_role_bookmark_ids', []);
  const { candidateID } = useParams<{ candidateID?: string }>();
  const history = useHistory();
  const {
    isClientReviewer,
    isRecruiter,
    user,
    viewingOrganization,
    isClientProspect,
    userPrioritizedRole,
  } = useCustomerAuthorizedUserSession();

  const { loading: isLoading_selectDashboard, data: selectDashboard_data } = useQuery<
    SelectDashboardQuery,
    SelectDashboardQueryVariables
  >(SelectDashboard, {
    variables: {
      organization_id: viewingOrganization.ID,
      job_folder_approved: JOB_FOLDER_APPROVED,
      job_folder_filled: JOB_FOLDER_FILLED,
    },
    fetchPolicy: 'network-only',
  });

  const {
    totalSubmittedCandidates,
    totalActiveMembers,
    totalOpenRoles,
    totalOpenPositions,
    totalActiveCandidates,
    hasMoreRoles,
    roles,
  } = serializeDashboard({
    data: selectDashboard_data,
    bookmarkedRoleIDs: active_roles_bookmark_ids,
  });

  const allRoles = [...roles.active, ...roles.pending];

  const { loading: isLoading_otherGreatCandidates, data: otherGreatCandidates_data } = useQuery<
    SelectDashboardBrowseCandidatesQuery,
    SelectDashboardBrowseCandidatesQueryVariables
  >(SelectDashboardBrowseCandidates, {
    variables: browseOtherCandidatesQuery_defaultVariables({
      organizationID: viewingOrganization.ID,
      userID: user?.id,
      isClientProspect,
    }),
    notifyOnNetworkStatusChange: true,
    skip: !user?.id || !viewingOrganization.ID,
  });

  const candidates = serializeDashboardOtherGreatCandidates(
    // TODO: Remove location from that query, is not being used
    otherGreatCandidates_data as Omit<SelectDashboardBrowseCandidatesQuery, 'location'>,
  );

  const { loading: isLoading_candidateMatchesByRole, data: candidateMatchesByRole_data } = useQuery<
    SelectCandidateMatchesByRoleQuery,
    SelectCandidateMatchesByRoleQueryVariables
  >(SelectCandidateMatchesByRole, {
    variables: {
      organization: viewingOrganization.ID,
      user_id: user?.id,
      updatedAfter: candidateSearchDateRange,
      isClientProspect,
      hasRole1: !!allRoles?.[0],
      hasRole2: !!allRoles?.[1],
      hasRole3: !!allRoles?.[2],
      role1_candidate_args: {
        organization_id: viewingOrganization.ID,
        must_have_skill_ids: allRoles?.[0]?.candidates_matches_query_args.must_have_skill_ids,
        regions: allRoles?.[0]?.candidates_matches_query_args.regions,
        countries: allRoles?.[0]?.candidates_matches_query_args.countries,
        sort_option: allRoles?.[0]?.candidates_matches_query_args.sort_option,
        employment_type: allRoles?.[0]?.candidates_matches_query_args.employment_type,
        badges: allRoles?.[0]?.candidates_matches_query_args.badges,
        candidate_role: allRoles?.[0]?.candidates_matches_query_args.candidate_role,
        should_have_skill_ids: allRoles?.[0]?.candidates_matches_query_args.should_have_skill_ids,
        min_years_of_experience:
          allRoles?.[0]?.candidates_matches_query_args.min_years_of_experience,
      },
      role2_candidate_args: {
        organization_id: viewingOrganization.ID,
        must_have_skill_ids: allRoles?.[1]?.candidates_matches_query_args.must_have_skill_ids,
        regions: allRoles?.[1]?.candidates_matches_query_args.regions,
        countries: allRoles?.[1]?.candidates_matches_query_args.countries,
        sort_option: allRoles?.[1]?.candidates_matches_query_args.sort_option,
        employment_type: allRoles?.[1]?.candidates_matches_query_args.employment_type,
        badges: allRoles?.[1]?.candidates_matches_query_args.badges,
        candidate_role: allRoles?.[1]?.candidates_matches_query_args.candidate_role,
        should_have_skill_ids: allRoles?.[1]?.candidates_matches_query_args.should_have_skill_ids,
        min_years_of_experience:
          allRoles?.[1]?.candidates_matches_query_args.min_years_of_experience,
      },
      role3_candidate_args: {
        organization_id: viewingOrganization.ID,
        must_have_skill_ids: allRoles?.[2]?.candidates_matches_query_args.must_have_skill_ids,
        regions: allRoles?.[2]?.candidates_matches_query_args.regions,
        countries: allRoles?.[2]?.candidates_matches_query_args.countries,
        sort_option: allRoles?.[2]?.candidates_matches_query_args.sort_option,
        employment_type: allRoles?.[2]?.candidates_matches_query_args.employment_type,
        badges: allRoles?.[2]?.candidates_matches_query_args.badges,
        candidate_role: allRoles?.[2]?.candidates_matches_query_args.candidate_role,
        should_have_skill_ids: allRoles?.[2]?.candidates_matches_query_args.should_have_skill_ids,
        min_years_of_experience:
          allRoles?.[2]?.candidates_matches_query_args.min_years_of_experience,
      },
    },
    notifyOnNetworkStatusChange: true,
    skip: isLoading_selectDashboard || isLoading_otherGreatCandidates || !allRoles?.length,
  });

  const candidateMatchesByRole = serializeCandidateMatchesByRole({
    data: candidateMatchesByRole_data,
    allRoles,
  });

  const { deleteSavedCandidate, insertSavedCandidate } = useSavedCandidates();
  const handleOnRemoveSaveCandidate = (savedCandidateId: number) => {
    return deleteSavedCandidate({
      variables: { candidate_id: savedCandidateId, saved_by_user_id: user?.id || 0 },
    });
  };
  const handleOnSaveCandidate = (savedCandidateId: number) => {
    return insertSavedCandidate({
      variables: { candidate_id: savedCandidateId, saved_by_user_id: user?.id || 0 },
    });
  };

  return (
    <>
      <Dashboard
        handleCandidateClick={(currentCandidateID) =>
          history.push(`/dashboard/candidate/${currentCandidateID}`)
        }
        widgets={{
          totalSubmittedCandidates,
          totalActiveMembers,
          totalOpenRoles,
          totalOpenPositions,
          totalActiveCandidates,
        }}
        roles={roles}
        hasMoreRoles={hasMoreRoles}
        isLoadingDashboard={isLoading_selectDashboard}
        is_loading_candidate_matches_by_role={
          isLoading_candidateMatchesByRole || candidateMatchesByRole_data === undefined
        }
        pageLayoutProps={{
          isClientReviewer,
          isRecruiter,
          orgName: viewingOrganization.name,
          user,
          isClientProspect,
          userPrioritizedRole,
        }}
        candidateMatchesByRole={candidateMatchesByRole}
        candidatesRecentlyActive={candidates.recentlyActive}
        candidatesTopCandidateExperience={candidates.topCandidateExperience}
        candidateZeroOneExperience={candidates.zeroOneExperience}
        candidateTechLead={candidates.techLeadExperience}
      />
      <CandidateProfileController
        key={`candidate-${candidateID}`}
        candidateID={Number(candidateID)}
        onCloseCandidateProfileClick={() => history.push(`/dashboard`)}
        handleOnRemoveSaveCandidate={handleOnRemoveSaveCandidate}
        handleOnSaveCandidate={handleOnSaveCandidate}
        viewPage={ViewType.DASHBOARD}
      />
    </>
  );
}
