import { displayRolesNames } from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/ExploreCandidates.serializer';
import {
  Role_Choices_Enum,
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
  Candidate_Highlight_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';
import { toFriendlyYearsOfExperienceRange } from 'global/utils';
import type { OnboardingState } from '../../Prospect.types';

export function serializeOnboardingMostMatchingRole({
  dataString,
  userID,
  onError,
}: {
  dataString?: string | null;
  userID?: number;
  onError?: (error: any) => void;
}) {
  if (!dataString || !userID) return {};
  try {
    const { [userID]: mostMatchingRole } = JSON.parse(dataString) as Record<
      string,
      OnboardingState['roles'][number]
    >;

    return mostMatchingRole
      ? {
          queryArgs: {
            should_have_skill_ids: mostMatchingRole.skillIDs,
            candidate_role: mostMatchingRole.value,
            min_years_of_experience: mostMatchingRole.experience,
          },
          initialValues: {
            niceToHaveSkills: mostMatchingRole?.skillIDs?.map((skillID, index) => ({
              value: `${skillID}`,
              label: mostMatchingRole.skillNames[index] as string,
            })),
            candidateRole: displayRolesNames[mostMatchingRole.value as Role_Choices_Enum],
            yearsOfExperience: toFriendlyYearsOfExperienceRange(mostMatchingRole.experience),
          },
        }
      : {};
  } catch (error) {
    onError?.(error);
    return {};
  }
}

export function serializeFiltersFromParams(params: {
  role?: string | null;
  yoe?: string | null;
  badges?: string | null;
  sort?: string | null;
}) {
  if (!Object.values(params).some(Boolean)) return {};

  const { role: roleStr, yoe: yoeStr, badges: badgesStr, sort } = params;
  const badges = (badgesStr?.split(',') || []).filter((badge) =>
    Object.values(Candidate_Highlight_Choices_Enum).includes(
      badge as Candidate_Highlight_Choices_Enum,
    ),
  );
  const role = Object.values(Role_Choices_Enum).includes(roleStr as Role_Choices_Enum) && roleStr;
  const yoe =
    Object.values(Candidate_Curation_Years_Of_Exp_Range_Choices_Enum).includes(
      yoeStr as Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
    ) && yoeStr;

  return {
    queryArgs: {
      ...(role ? { candidate_role: role } : {}),
      ...(yoe ? { min_years_of_experience: yoe } : {}),
      ...(badges.length ? { badges } : {}),
      ...(sort === 'Last Active' ? { sort_option: null } : {}),
      ...(sort === 'Recommended' ? { sort_option: 'recommended' } : {}),
    },
    initialValues: {
      ...(role ? { candidateRole: displayRolesNames[role as Role_Choices_Enum] } : {}),
      ...(yoe
        ? {
            yearsOfExperience: toFriendlyYearsOfExperienceRange(
              yoe as Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
            ),
          }
        : {}),
      ...(badges.length ? { candidateHighlights: badges } : {}),
      ...(['Last Active', 'Recommended'].includes(sort || '')
        ? { sort_option: sort as string }
        : {}),
    },
  };
}
