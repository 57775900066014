import { NavLink } from 'react-router-dom';

import { Link, Flex, Box, Tag, ArrowLeftIcon } from '@terminal/design-system';
import { PageLayout } from 'talent-hub/components';

export const roleTabs = {
  progress: 'progress',
  submissions: 'submissions',
  interviews: 'interviews',
  offers: 'offers',
  hires: 'hires',
  requirements: 'requirements',
  reports: 'reports',
};

export function TabBarItem({ to, children }: React.ComponentProps<typeof NavLink>) {
  return (
    <Link
      activeStyle={{
        borderBottom: '2px solid',
      }}
      borderBottom="2px solid"
      borderBottomColor="brand.lightest"
      style={{ textDecoration: 'none' }}
      to={to}
      as={NavLink}
      py={2}
      px={3}
      _focus={{ boxShadow: 'none' }}
      colorScheme="text"
    >
      {children}
    </Link>
  );
}

export function TabBarBadge({ value }: { value?: number }) {
  if (!value) return null;
  return (
    <Tag
      color="brand.darker"
      ml={2}
      p={0}
      justifyContent="center"
      size="sm"
      backgroundColor="bg.secondary"
    >
      {value}
    </Tag>
  );
}

export function RoleLayout({
  activeRole,
  children,
  hiredCompleteCandidates,
  pageLayoutProps,
  roleTitle,
  showSubHeader = true,
  upcomingInterviewCandidates,
  upcomingOffersCandidates,
  upcomingSubmissionCandidates,
  handleBackAction,
  superTitle,
  isClientReviewer = false,
  backActionMessage = 'Back to Manage Roles',
}: {
  activeRole: string;
  children?: React.ReactNode;
  hiredCompleteCandidates: number;
  pageLayoutProps: React.ComponentProps<typeof PageLayout>;
  roleTitle: string;
  showSubHeader?: boolean;
  upcomingInterviewCandidates: number;
  upcomingOffersCandidates: number;
  upcomingSubmissionCandidates: number;
  handleBackAction: () => void;
  superTitle?: string;
  isClientReviewer?: boolean;
  backActionMessage: string;
}) {
  if (!showSubHeader)
    return (
      <PageLayout
        superTitle={
          <Link
            textDecoration="none"
            pb={2}
            colorScheme="brand"
            as="button"
            onClick={handleBackAction}
            role="link"
          >
            <ArrowLeftIcon mr={2} /> {superTitle || `Back to ${roleTitle}`}
          </Link>
        }
        headerTitle="Candidate Review"
        {...pageLayoutProps}
      >
        <Box pt="initial">{children}</Box>
      </PageLayout>
    );

  return (
    <PageLayout
      superTitle={
        <Link
          textDecoration="none"
          pb={2}
          colorScheme="brand"
          as="button"
          onClick={handleBackAction}
          role="link"
        >
          <ArrowLeftIcon mr={2} />
          {backActionMessage}
        </Link>
      }
      headerTitle={roleTitle}
      subHeader={
        <Flex
          bg="bg.primary"
          wrap={['wrap', 'wrap', 'nowrap']}
          justify={['space-around', 'space-around', 'start']}
        >
          {!isClientReviewer && (
            <>
              <TabBarItem to={`/role/${activeRole}/${roleTabs.progress}`}>Progress</TabBarItem>
              <TabBarItem to={`/role/${activeRole}/${roleTabs.submissions}`}>
                Submissions
                <TabBarBadge value={upcomingSubmissionCandidates} />
              </TabBarItem>
            </>
          )}
          <TabBarItem to={`/role/${activeRole}/${roleTabs.interviews}`}>
            Interviews
            <TabBarBadge value={upcomingInterviewCandidates} />
          </TabBarItem>
          {!isClientReviewer && (
            <>
              <TabBarItem to={`/role/${activeRole}/${roleTabs.offers}`}>
                Offers
                <TabBarBadge value={upcomingOffersCandidates} />
              </TabBarItem>
              <TabBarItem to={`/role/${activeRole}/${roleTabs.hires}`}>
                Hires
                <TabBarBadge value={hiredCompleteCandidates} />
              </TabBarItem>
              <TabBarItem to={`/role/${activeRole}/${roleTabs.requirements}`}>
                Role Requirements
              </TabBarItem>
              <TabBarItem to={`/role/${activeRole}/${roleTabs.reports}`}>Weekly Reports</TabBarItem>
            </>
          )}
        </Flex>
      }
      {...pageLayoutProps}
    >
      <Box pt={[0, 0, 10]}>{children}</Box>
    </PageLayout>
  );
}
