import { createContext, useContext } from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { useCustomerAuthorizedUserSession } from 'talent-hub/utils';
import {
  Image,
  Heading,
  useDisclosure,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Text,
  Flex,
} from '@terminal/design-system';
import { useMutation } from '@apollo/client';
import Sentry from 'global/sentry';
import { useLocalStorage } from 'global/utils';
import { Client_Prospect_Events_Choices_Enum } from 'global/types/hasura-tables.generated.types';

import illustration from '../../assets/guy.svg?url';
import { useProspectInfo } from '../../useProspectInfo';
import { InsertClientProspectEvents, SelectProspectDashboardQuery } from '../../dashboard/data';
import type {
  InsertClientProspectEventsMutation,
  InsertClientProspectEventsMutationVariables,
} from '../../dashboard/data';

const meetingURL = import.meta.env.REACT_APP_CALENDLY_MEETING_URL;

const defaultCalendlyStates = {
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
  isCallScheduled: false,
};

type CalendlyStates = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  isCallScheduled: boolean;
};

const CalendlyWidgetContext = createContext<CalendlyStates>(defaultCalendlyStates);

export function useCalendlyWidget() {
  const context = useContext(CalendlyWidgetContext);

  if (!context) {
    throw new Error(
      'useCalendlyWidget have to be used where the CalendlyWidgetContext is provided',
    );
  }

  return context;
}

function CalendlyModalController({
  isOpen,
  onClose,
  prospectInfo,
  isLoading,
}: {
  isOpen: boolean;
  onClose: () => void;
  prospectInfo: ReturnType<typeof useProspectInfo>['data'];
  isLoading: boolean;
}) {
  const auth = useCustomerAuthorizedUserSession();
  const [campaignInfoInSession] = useLocalStorage<Record<string, string>>('campaignInfo', {});

  const [insertProspectEvent] = useMutation<
    InsertClientProspectEventsMutation,
    InsertClientProspectEventsMutationVariables
  >(InsertClientProspectEvents, {
    refetchQueries: [SelectProspectDashboardQuery],
    onError: (error) => {
      Sentry.captureException(error);
    },
  });

  useCalendlyEventListener({
    onEventScheduled: () => {
      insertProspectEvent({
        variables: {
          user_id: auth.user?.id as number,
          event_choice: Client_Prospect_Events_Choices_Enum.ScheduleCall,
        },
      });
    },
  });

  if (!isLoading && !prospectInfo) {
    Sentry.captureMessage('Prospect did not have associated prospect information');
  }

  if (!auth.user || isLoading || !prospectInfo) return null;

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} motionPreset="scale">
      <ModalOverlay />
      <ModalContent maxW="86rem">
        <ModalCloseButton />
        <ModalBody textAlign="center" display="flex">
          <Flex maxW="xs" flexDir="column" justifyContent="center">
            <Image
              w={40}
              mx="auto"
              mb={10}
              src={illustration}
              alt="Guy with a computer and stars"
            />
            <Heading variant="heading-2" mb={1}>
              Talk With Us!
            </Heading>
            <Text variant="body" textAlign="left">
              To continue your journey with Terminal, book a call with one of our talent experts! We
              want to deeply understand your needs to ensure success with your global hiring
              efforts. Plus, we can unlock more capabilities to accelerate your search, including:
              <ul style={{ paddingLeft: '20px' }}>
                <li>Detailed candidate profile and resume</li>
                <li>Profile sharing</li>
                <li>Job creation tools</li>
              </ul>
              <br />
              And more! Grab time with a talent expert to get started.
            </Text>
          </Flex>
          <InlineWidget
            styles={{ width: '100%', height: '43rem' }}
            url={`https://calendly.com/${meetingURL}?hide_gdpr_banner=1`}
            prefill={{
              email: auth.user.email,
              name: auth.user.name!,
            }}
            utm={{
              utmCampaign: campaignInfoInSession.utm_campaign,
              utmSource: campaignInfoInSession.utm_source,
              utmMedium: campaignInfoInSession.utm_medium,
              utmTerm: campaignInfoInSession.utm_term,
              // ! By Jonathan's request, this should not be overwritten.
              utmContent: `prospect:${prospectInfo?.id}`,
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export function CalendlyModalProvider({ children }: { children: React.ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: prospectInfo, loading: isLoadingProspectInfo } = useProspectInfo();

  return (
    <CalendlyWidgetContext.Provider
      // TODO (TP-1874): Fix this Eslint error
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ isOpen, onOpen, onClose, isCallScheduled: !!prospectInfo?.isCallScheduled }}
    >
      {children}
      <CalendlyModalController
        isOpen={isOpen}
        onClose={onClose}
        isLoading={isLoadingProspectInfo}
        prospectInfo={prospectInfo}
      />
    </CalendlyWidgetContext.Provider>
  );
}
