import { useEffect } from 'react';
import * as Yup from 'yup';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Heading,
  ModalFooter,
  Box,
  Text,
  Checkbox,
  FormControl,
  Flex,
} from '@terminal/design-system';
import { useCustomFormik } from 'global/utils/useCustomFormik';
import type { ModalOverlayProps } from '@chakra-ui/react';

// ! TAL- This component should import a type from a specific subfeature?
import type { serializeTeamMembersToShareWith } from '../subfeatures/explore-candidates/ExploreCandidates.serializer';

const validationSchema = Yup.object().shape({
  teamMembers: Yup.array()
    .of(Yup.string().required('Choose at least one team member'))
    .min(1, 'Choose at least one team member'),
});

export type FormData = {
  teamMembers: string[];
};

export function ShareWithOthersModal({
  candidateInfo,
  isOpen,
  onClose,
  teamMembers,
  handleInsert,
  overlayProps,
}: {
  candidateInfo: { id: number; name: string };
  isOpen: boolean;
  onClose: () => void;
  teamMembers: ReturnType<typeof serializeTeamMembersToShareWith>;
  handleInsert: (candidateId: number, teamMembersIds: string[]) => void;
  overlayProps?: ModalOverlayProps;
}) {
  const { values, handleSubmit, handleChange, resetForm, dirty, isValid } =
    useCustomFormik<FormData>({
      initialValues: {
        teamMembers: [],
      },
      validationSchema,
      validateOnChange: true,
      validateOnBlur: false,
      enableReinitialize: true,
      onSubmit: () => {
        handleInsert(candidateInfo.id, values.teamMembers);
      },
    });

  useEffect(() => {
    resetForm();
  }, [resetForm, isOpen]);

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="outside"
      motionPreset="scale"
      closeOnOverlayClick
      returnFocusOnClose
      closeOnEsc
    >
      <ModalOverlay {...overlayProps} />
      <ModalContent maxW={['21.938rem', '21.938rem', 'md']}>
        <ModalHeader
          px={3}
          py={6}
          bgColor="bg.primary"
          border="1px solid"
          borderColor="ui.secondary"
        >
          <Heading variant="heading-4" textColor="text.secondary">
            Share {candidateInfo.name} with:
          </Heading>
          <ModalCloseButton size="md" top={5} right={4} />
        </ModalHeader>
        <ModalBody p={0}>
          <Box pt={6} px={6}>
            <Text fontWeight="bold" mb={2}>
              Your Team Members
            </Text>
            {teamMembers.length > 0 ? (
              <FormControl>
                <Flex flexWrap="wrap" flexDir="column">
                  {teamMembers.map((member) => (
                    <Checkbox
                      key={member.id}
                      id="teamMembers"
                      name="teamMembers"
                      onChange={handleChange}
                      value={member.id}
                      mt={2}
                      isChecked={member.isChecked || values.teamMembers.includes(String(member.id))}
                      isDisabled={member.isChecked}
                    >
                      {member.name} {member.isChecked ? ' (shared)' : ''}
                    </Checkbox>
                  ))}
                </Flex>
              </FormControl>
            ) : (
              <Text>No other users from your company were found</Text>
            )}
          </Box>
        </ModalBody>
        <ModalFooter display="flex" justifyContent="end" pt={8} pb={4} px={4}>
          <Button
            variant="solid"
            colorScheme="brand"
            size="lg"
            type="button"
            onClick={() => handleSubmit()}
            disabled={teamMembers.length === 0 || !dirty || !isValid}
          >
            Share Candidate
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
