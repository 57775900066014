// @ts-nocheck // TODO: [TP-1472] Fix type
import { css } from '@emotion/react';
import { Text } from '@terminal/design-system';

/** @deprecated
 * WARNING: This component is very exposed to XSS injection, it should be removed or an HTML sanitizer should be included to reduce the risk.
 * https://pragmaticwebsecurity.com/articles/spasecurity/react-xss-part2.html
 * */
export function RenderFieldHTML({ html }: { html: string }) {
  return (
    <Text whiteSpace="pre-line">
      <div
        css={css`
          white-space: pre-line;
          ul {
            padding-top: 0.2;
            padding-left: 1.2em;
          }
        `}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </Text>
  );
}
