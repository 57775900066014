import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { useQuery } from 'global/utils';
import * as events from 'global/events';
import Sentry from 'global/sentry';
import { useToast } from '@terminal/design-system';

import { ICIMS, DB, AsyncStatus } from 'talent-hub/constants';
import { useCustomerAuthorizedUserSession } from 'talent-hub/utils';

import { useCandidateInformation } from 'talent-hub/components';
import moment from 'moment';
import { useInterviewFeedbackUtils } from '../../useInterviewFeedbackUtils';
import type { RoleLayout } from '../../components';
import { ViewType } from './components/ModalCandidateSubmissionReview/constants';
import { SelectJobCandidateProfile, InsertFeedback, InsertInterviewFeedback } from './data';
import { CandidateProfile } from './CandidateProfile';

export function CandidateProfileController({
  roleLayoutProps,
}: {
  roleLayoutProps: React.ComponentProps<typeof RoleLayout>;
}) {
  const toast = useToast({
    position: 'bottom-left',
    duration: 4000,
  });
  const history = useHistory<{ prevPath: string } | undefined>();
  const { state } = useLocation<{
    from?: string;
  }>();
  const { activeRole, candidateID } = useParams<{ activeRole: string; candidateID: string }>();
  const { user, viewingOrganization, isClientReviewer, isClientProspect } =
    useCustomerAuthorizedUserSession();

  const reviewerName = user?.name as string;
  const reviewerEmail = user?.email as string;
  const reviewerId = user?.id;

  const [submissionViewStatus, setSubmissionViewStatus] = useState(AsyncStatus.Idle);

  const { data, loading } = useQuery(SelectJobCandidateProfile, {
    variables: {
      organization: viewingOrganization.ID,
      candidate_id: candidateID,
      job_id: activeRole,
      reviewer_id: user?.id,
      isClientReviewer,
      updatedAfter: moment().subtract(45, 'days').utc().format('YYYY-MM-DD'),
      isClientProspect,
      isClientProspectOrReviewer: isClientProspect || isClientReviewer,
    },
    fetchPolicy: 'network-only',
    skip: !candidateID,
  });

  const { handleResumeLinkClicked, profile, stage, hasInterviewFeedback, candidateHighlights } =
    useCandidateInformation(data, true);

  const [insertFeedback] = useMutation(InsertFeedback, {
    refetchQueries: ['ProgressMetrics', 'LatestCandidateStatusForJobQuery', 'RoleData'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setSubmissionViewStatus(AsyncStatus.Resolved);
      toast({
        status: 'success',
        title: 'Feedback saved!',
        description: 'Your feedback has been saved.',
      });
    },
    onError: (mutationError) => {
      setSubmissionViewStatus(AsyncStatus.Rejected);
      Sentry.captureException(mutationError);
    },
  });

  const [
    insertInterviewFeedback,
    { loading: isLoadingInsertInterview, data: insertInterviewData },
  ] = useMutation(InsertInterviewFeedback, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: 'Feedback saved!',
        description: 'Your feedback has been saved.',
      });
    },
    onError: (mutationError) => {
      toast({
        status: 'error',
        title: 'Error!',
        description: 'Something went wrong trying to send your feedback. Please try again!',
      });
      Sentry.captureException(mutationError);
    },
  });

  const interviewFeedbackUtils = useInterviewFeedbackUtils({
    applicantWorkflowID: profile.applicantWorkflowId,
    jobID: data?.icims_job?.['0']?.job?.id,
  });

  useEffect(() => {
    if (profile) {
      // Track new modal view
      events.track(events.name.viewedCandidateProfile, {
        candidateID,
        candidate_name: profile.fullName,
        job: activeRole,
        organization: viewingOrganization.ID,
      });
    }
  }, [profile, candidateID, activeRole, viewingOrganization.ID]);

  // @ts-ignore
  const handleCandidateSubmissionSubmit = ({ comments, decision, reasons }) => {
    const { applicantWorkflowID, initialDataTransfer } = stage.submission.moreInfo;
    // @ts-ignore
    const reasonsFields = (reasons || []).reduce((prev, next) => {
      return { ...prev, [next.field]: true };
    }, {});
    insertFeedback({
      variables: {
        workflow_status_input: {
          applicant_workflow_profile_id: applicantWorkflowID,
          initial_data_transfer: initialDataTransfer,
          status_id:
            decision === ViewType.Advance
              ? ICIMS.Status.ClientPortalAdvance.ID
              : ICIMS.Status.ClientPortalRejected.ID,
          icims_applicant_workflow_feedback: {
            data: {
              comments,
              reviewer_id: reviewerId,
              reviewer_email: reviewerEmail,
              reviewer_name: reviewerName,
              initial_data_transfer: initialDataTransfer,
              applicant_workflow_profile_id: applicantWorkflowID,
              sync_status: DB.SyncStatus.Waiting,
              ...reasonsFields,
            },
          },
          sync_status: DB.SyncStatus.Waiting,
        },
      },
    });

    setSubmissionViewStatus(AsyncStatus.InProgress);
  };

  const handleInterviewFeedbackSubmit = (rating: string, comments: string) => {
    insertInterviewFeedback({
      variables: {
        applicant_id: profile.applicantWorkflowId,
        reviewer_id: reviewerId,
        rating,
        comments,
      },
    });
  };

  /**
   * When has previous page goBack, else go to role page
   */
  const handleBackAction = () => {
    if (isClientReviewer || state?.from === 'manage-candidates') {
      history.push(`/candidates`);
      return;
    }
    if (history.action === 'PUSH') {
      history.goBack();
      return;
    }
    if (state?.from === 'dashboard') {
      history.push(`/`);
      return;
    }
    history.push(`/role/${activeRole}`);
  };

  const handleGoToFeedbackPage = () => {
    history.push(`/role/${activeRole}/candidate/${candidateID}/feedback`);
  };

  function superTitleMapper(from: string | undefined) {
    if (isClientReviewer) {
      return 'Back to Manage Candidates';
    }

    switch (from) {
      case 'manage-candidates':
        return 'Back to Manage Candidates';
      case 'dashboard':
        return 'Back to Dashboard';

      default:
        return roleLayoutProps.superTitle;
    }
  }
  const superTitle = superTitleMapper(state?.from);

  return (
    <CandidateProfile
      handleCandidateSubmissionSubmit={handleCandidateSubmissionSubmit}
      handleResumeLinkClicked={handleResumeLinkClicked}
      loading={loading}
      profile={profile}
      reviewerName={reviewerName}
      roleLayoutProps={{
        ...roleLayoutProps,
        handleBackAction,
        superTitle,
      }}
      stage={stage}
      submissionViewStatus={submissionViewStatus}
      interviewFeedbackUtils={interviewFeedbackUtils}
      hasInterviewFeedback={hasInterviewFeedback || !!insertInterviewData}
      isLoadingInsertInterview={isLoadingInsertInterview}
      onInterviewFeedbackSubmit={handleInterviewFeedbackSubmit}
      onBackToFeedbackCLick={handleGoToFeedbackPage}
      isClientReviewer={isClientReviewer}
      candidateHighlights={candidateHighlights}
    />
  );
}
