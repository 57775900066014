import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { NetworkStatus, useMutation } from '@apollo/client';
import moment from 'moment';

import Sentry from 'global/sentry';
import { useQuery } from 'global/utils';
import { useIntersectionObserver } from 'global/hooks';
import { useToast, useDisclosure } from '@terminal/design-system';
import {
  useCustomerAuthorizedUserSession,
  useSavedCandidates,
  useSharedCandidates,
  useTalentHubFlags,
} from 'talent-hub/utils';
import { JOB_FOLDER_APPROVED } from 'talent-hub/constants';
import type {
  Candidate_Browse_Args,
  Role_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';
import type { useFormik } from 'formik';
import type {
  SelectBrowseCandidatesQueryVariables,
  SelectExploreCandidatesOptionsQuery,
  SelectExploreCandidatesOptionsQueryVariables,
} from './data';
import {
  DeleteUserSavedSearch,
  InsertUserSavedSearch,
  SelectExploreCandidatesOptions,
} from './data';
import type { Formfields } from './ExploreCandidates.serializer';
import {
  serializeSaveSearch,
  displayRolesNames,
  serializeExploreCandidatesOptions,
  sortOptionBackendValues,
} from './ExploreCandidates.serializer';

// limit of candidates to fetch in the query
export const candidateLimit = 100;
export const candidateSearchDateRange = moment().subtract(45, 'days').utc().format('YYYY-MM-DD');

export function useShareCandidates() {
  // #region share_candidate logic
  const toast = useToast({
    position: 'bottom-left',
    duration: 4000,
  });
  const { teamMembers, handleOnInsertSharedCandidate } = useSharedCandidates({});

  const {
    isOpen: isShareWithModalOpen,
    onClose: onCloseShareWithModal,
    onOpen: onOpenShareWithModal,
  } = useDisclosure();

  const onInsertSharedCandidate = (candidateId: number, teamMembersIds: string[]) => {
    handleOnInsertSharedCandidate(candidateId, teamMembersIds, () => {
      toast({
        title: 'Shared Successfully!',
        description: 'The candidate was shared successfully.',
        status: 'success',
      });
      onCloseShareWithModal();
    });
  };
  // #endregion

  return {
    onCloseShareWithModal,
    onOpenShareWithModal,
    isShareWithModalOpen,
    onInsertSharedCandidate,
    teamMembers,
  };
}

// TODO: Rename to useBrowseCandidatesController
export function useExploreCandidatesController({
  browseCandidates,
}: {
  browseCandidates: {
    loadingCandidates: boolean;
    networkStatus: NetworkStatus;
    defaultVariables: SelectBrowseCandidatesQueryVariables;
    // ! Hard to type
    data: any;
    refetch: any;
    fetchMore: any;
    client: any;
  };
}) {
  const { topCandidatesCriteria } = useTalentHubFlags();
  const { user, viewingOrganization } = useCustomerAuthorizedUserSession();
  const history = useHistory();

  // #region explore_candidates logic
  const exploreCandidatesOptions = useQuery<
    SelectExploreCandidatesOptionsQuery,
    SelectExploreCandidatesOptionsQueryVariables
  >(SelectExploreCandidatesOptions, {
    variables: {
      organization_id: viewingOrganization.ID,
      job_folder_approved: JOB_FOLDER_APPROVED,
      userID: user?.id,
    },
  });
  const {
    roles,
    skills,
    locations,
    yearsOfExperience,
    sorting,
    employmentType,
    candidateHighlights,
    popularSearches,
    savedSearches,
  } = serializeExploreCandidatesOptions(exploreCandidatesOptions.data);
  // #endregion

  // #region infinite_scroll logic
  const [infiniteScrollRef, setInfiniteScrollRef] = useState<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(infiniteScrollRef, { rootMargin: '200px' });
  const [shouldStopFetchingMore, setShouldStopFetchingMore] = useState(false);

  useEffect(() => {
    return () => {
      // known issue: this is reseting the entire cache data we want to remove just the cache for the current query
      browseCandidates.client.resetStore();
    };
  }, [browseCandidates.client]);

  const dataLength = browseCandidates.data?.candidate_browse?.length || 0;
  const isFetchingMore = browseCandidates.networkStatus === NetworkStatus.fetchMore;

  // ! Hard to type
  const refetchCandidates = (
    overwrite: Partial<ReturnType<typeof useFormik<Formfields>>['values']> = {},
  ) => {
    setShouldStopFetchingMore(false);

    const locationValue = locations.find(
      (location) => location.label === overwrite.location,
    )?.value;

    const overwrite_serialized: Candidate_Browse_Args = {
      // ? Typeahead not allow key-value, just strings
      candidate_role:
        Object.keys(displayRolesNames).find(
          (key) => displayRolesNames[key as Role_Choices_Enum] === overwrite.candidateRole,
        ) || null,
      regions: locationValue ? [locationValue] : null,
      must_have_skill_ids: overwrite.requiredSkills?.map(({ value }) => Number(value)),
      should_have_skill_ids: overwrite.niceToHaveSkills?.map(({ value }) => Number(value)),
      min_years_of_experience:
        yearsOfExperience.find(
          (yearOfExperience) => yearOfExperience.label === overwrite.yearsOfExperience,
        )?.value || null,
      sort_option:
        sortOptionBackendValues[overwrite.sort_option as keyof typeof sortOptionBackendValues],
      employment_type:
        employmentType.find(
          (currentEmploymentType) => currentEmploymentType.label === overwrite.employmentType,
        )?.value || null,
      badges: overwrite.candidateHighlights?.length ? overwrite.candidateHighlights : null,
    };

    const requestVariables = {
      ...browseCandidates.defaultVariables.candidate_browse_args,
      ...overwrite_serialized,
    };

    const isFilterSelected =
      /* eslint-disable eqeqeq */
      browseCandidates.defaultVariables.candidate_browse_args.candidate_role !=
        requestVariables.candidate_role ||
      browseCandidates.defaultVariables.candidate_browse_args.min_years_of_experience !=
        requestVariables.min_years_of_experience ||
      browseCandidates.defaultVariables.candidate_browse_args.employment_type !=
        requestVariables.employment_type ||
      browseCandidates.defaultVariables.candidate_browse_args.regions != requestVariables.regions ||
      browseCandidates.defaultVariables.candidate_browse_args.badges != requestVariables.badges ||
      browseCandidates.defaultVariables.candidate_browse_args.must_have_skill_ids?.length !=
        requestVariables.must_have_skill_ids?.length ||
      browseCandidates.defaultVariables.candidate_browse_args.should_have_skill_ids?.length !=
        requestVariables.should_have_skill_ids?.length;
    /* eslint-enable */

    return browseCandidates.refetch({
      ...browseCandidates.defaultVariables,
      candidate_browse_args: {
        ...requestVariables,
        // * If sort_option is different than recommended or have a filter selected, backend doesnt work well with this field
        ...(browseCandidates.defaultVariables.candidate_browse_args.sort_option !==
          requestVariables.sort_option || isFilterSelected
          ? { top_candidates_criteria: null }
          : { top_candidates_criteria: topCandidatesCriteria }),
      },
    });
  };

  useEffect(() => {
    if (
      entry?.isIntersecting &&
      dataLength >= candidateLimit &&
      !browseCandidates.loadingCandidates &&
      !isFetchingMore &&
      !shouldStopFetchingMore
    ) {
      browseCandidates
        .fetchMore({
          variables: {
            offset: dataLength,
          },
        })
        // !- Hard to type
        .then((response: any) => {
          setShouldStopFetchingMore(response.data.candidate_browse.length < candidateLimit);
        });
    }
  }, [
    dataLength,
    browseCandidates.fetchMore,
    isFetchingMore,
    entry?.isIntersecting,
    shouldStopFetchingMore,
    browseCandidates.loadingCandidates,
    browseCandidates,
  ]);
  // #endregion

  // #region save_candidate logic
  const {
    insertSavedCandidate,
    deleteSavedCandidate,
    savedByMeCandidatesCount,
    sharedWithMeCandidatesCount,
    refetchSelectFavoriteCandidatesCounts,
  } = useSavedCandidates({
    insertSavedCandidate_onCompletedHandler: () => {
      browseCandidates.refetch?.();
      refetchSelectFavoriteCandidatesCounts();
    },
    deleteSavedCandidate_onCompletedHandler: () => {
      browseCandidates.refetch?.();
      refetchSelectFavoriteCandidatesCounts();
    },
  });

  const handleOnRemoveSaveCandidate = (savedCandidateId: number) => {
    return deleteSavedCandidate({
      variables: { candidate_id: savedCandidateId, saved_by_user_id: user?.id || 0 },
    });
  };

  const handleOnSaveCandidate = (savedCandidateId: number) => {
    return insertSavedCandidate({
      variables: { candidate_id: savedCandidateId, saved_by_user_id: user?.id || 0 },
    });
  };
  // #endregion

  const toast = useToast({
    position: 'top',
    duration: 4000,
    containerStyle: {
      maxWidth: '100%',
    },
  });

  const [insertUserSavedSearch] = useMutation(InsertUserSavedSearch, {
    onError: (mutationError) => {
      toast({
        title: 'Error saving search',
        description: 'Please try again',
        status: 'error',
      });
      Sentry.captureException(mutationError);
    },
    onCompleted: ({
      insert_explore_candidates_user_saved_search_one: {
        explore_candidates_search: { title },
      },
    }) => {
      toast({
        title,
        description: 'has been added to your saved searches',
        status: 'success',
      });
    },
    update: (
      cache,
      {
        data: {
          insert_explore_candidates_user_saved_search_one: { explore_candidates_search },
        },
      },
    ) => {
      const cacheData = cache.readQuery<SelectExploreCandidatesOptionsQuery>({
        query: SelectExploreCandidatesOptions,
        variables: {
          organization_id: viewingOrganization.ID,
          job_folder_approved: JOB_FOLDER_APPROVED,
          userID: user?.id,
        },
      });
      cache.writeQuery({
        query: SelectExploreCandidatesOptions,
        variables: {
          organization_id: viewingOrganization.ID,
          job_folder_approved: JOB_FOLDER_APPROVED,
          userID: user?.id,
        },
        data: {
          ...(cacheData || {}),
          explore_candidates_search: [
            explore_candidates_search,
            ...(cacheData ? cacheData.explore_candidates_search : []),
          ],
        },
      });
    },
  });

  const [deleteUserSavedSearch] = useMutation(DeleteUserSavedSearch, {
    onError: (mutationError) => {
      toast({
        title: 'Error deleting search',
        description: 'Please try again',
        status: 'error',
      });
      Sentry.captureException(mutationError);
    },
    update: (cache, { data }) => {
      if (!data) return;
      const normalizedId = cache.identify({
        id: data.delete_explore_candidates_search_by_pk.id,
        // eslint-disable-next-line no-underscore-dangle
        __typename: data.delete_explore_candidates_search_by_pk.__typename,
      });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  return {
    saveCandidate: {
      savedByMeCandidatesCount,
      sharedWithMeCandidatesCount,
      handleOnRemoveSaveCandidate,
      handleOnSaveCandidate,
    },
    exploreCandidates: {
      searches: {
        popular: popularSearches,
        user: savedSearches,
        handleSave: (values: Formfields) => {
          const { isFilterSelected, saveSearchData } = serializeSaveSearch({
            values,
            locations,
            yearsOfExperience,
            employmentType,
            browseCandidatesQuery_DefaultVariables: browseCandidates.defaultVariables,
          });

          if (!isFilterSelected) {
            toast({
              title: 'No Filters Found',
              description: 'Please add at least one filter to save the search.',
              status: 'info',
            });
            return;
          }

          insertUserSavedSearch({
            variables: {
              object: {
                user_info_id: user?.id,
                explore_candidates_search: saveSearchData,
              },
            },
          });
        },
        handleDelete: (id: number) => {
          deleteUserSavedSearch({
            variables: { ID: id },
            optimisticResponse: {
              delete_explore_candidates_search_by_pk: {
                id,
                __typename: 'explore_candidates_search',
              },
            },
          });
        },
      },
      filterOption: {
        candidateHighlights,
        roles,
        skills,
        locations,
        sorting,
        yearsOfExperience,
        employmentType,
      },
      onCloseCandidateProfileClick: () => history.push(`/explore-candidates`),
      // TODO: what could be a better name for exploreCandidatesOptions
      exploreCandidatesOptions,
      // Scroll props
      refetchCandidates,
      setInfiniteScrollRef,
    },
  };
}
