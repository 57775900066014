import {
  Heading,
  Text,
  Flex,
  Image,
  Link,
  ArrowLeftIcon,
  Box,
  UnorderedList,
  ListItem,
  Button,
  VStack,
} from '@terminal/design-system';
import { PageLayout } from 'talent-hub/components';
import { Link as RouterLink } from 'react-router-dom';
import submittedImg from './assets/submitted.svg?url';
import backgroundImg from './assets/background.svg?url';
import interviewImg from './assets/interview.svg?url';
import type { InsertJobMutationVariables } from './data';
import type { serializeCreateJobOptions, serializeSelectJob } from './CreateEditJob.serializer';
import { CreateJobForm } from './CreateJobForm';

export function CreateEditJob({
  pageLayoutProps,
  options,
  viewMode,
  handleSubmit,
  initialValues,
  viewingOrg,
}: {
  pageLayoutProps: React.ComponentProps<typeof PageLayout>;
  options: ReturnType<typeof serializeCreateJobOptions>['options'];
  viewMode: 'success' | 'form';
  handleSubmit: (formData: InsertJobMutationVariables) => void;
  initialValues: ReturnType<typeof serializeSelectJob>;
  viewingOrg: number;
}) {
  return (
    <PageLayout
      {...pageLayoutProps}
      headerTitle="Open a New Role"
      superTitle={
        <Link
          textDecoration="none"
          pb={2}
          colorScheme="brand"
          as={RouterLink}
          to="/role"
          textTransform="none"
        >
          <ArrowLeftIcon mr={2} /> Back to Manage Roles
        </Link>
      }
    >
      <Box
        backgroundImage={backgroundImg}
        pos="fixed"
        left={0}
        top={0}
        bottom={0}
        right={0}
        zIndex={-1}
        pointerEvents="none"
      />
      {viewMode === 'success' && (
        <Box px={6}>
          <VStack
            flexDir="column"
            my={6}
            alignItems="center"
            border="1px solid"
            borderColor="ui.secondary"
            p={6}
            backgroundColor="white"
            spacing={4}
          >
            <Image
              src={submittedImg}
              alt=""
              maxH={{ base: 32, sm: '8.5rem' }}
              maxW="15.5rem"
              mt={6}
            />
            <Heading variant="heading-2" textAlign="center">
              Your Open Role Has Been Submitted!
            </Heading>
            <Text textAlign={['center', 'initial']}>
              Your new open role has been submitted and is now pending approval from our Talent
              Team. You can check the Manage Roles page to see the status of this role or submit
              another open role while you wait.
            </Text>
            <Button variant="solid" colorScheme="primary" to="/role" as={RouterLink}>
              OK, Great!
            </Button>
          </VStack>
        </Box>
      )}
      {viewMode === 'form' && (
        <Flex flexDir={['column', 'column', 'row']} alignItems="start" p={6}>
          <Flex
            w="xs"
            flexDir="column"
            mr={[0, 0, 6]}
            py={6}
            px={3}
            bg="bg.tertiary"
            border="1px solid"
            borderColor="ui.secondary"
          >
            <Heading variant="heading-3">Getting Started</Heading>
            <Text mt={2}>
              Reach available engineers and hire faster! Here’s what to expect after submitting your
              role information:
            </Text>
            <UnorderedList pl={2} pt={2}>
              <ListItem>
                <Text>
                  We&rsquo;ll review your requested position, with approvals for core roles within
                  one business day
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  Your assigned Customer Success Director will contact you to discuss compensation,
                  leveling, and the Terminal process
                </Text>
              </ListItem>
              <ListItem>
                <Text>Your new role will be live seven days after approval</Text>
              </ListItem>
              <ListItem>
                <Text>
                  We&rsquo;ll check in with you on role progress after 2 weeks, 30 days, and 90 days
                </Text>
              </ListItem>
            </UnorderedList>
            <Image alignSelf="center" src={interviewImg} alt="" maxH={48} maxW={48} mt={6} />
          </Flex>
          <CreateJobForm
            options={options}
            handleSubmit={handleSubmit}
            viewingOrg={viewingOrg}
            initialValues={initialValues}
          />
        </Flex>
      )}
    </PageLayout>
  );
}
