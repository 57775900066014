import moment from 'moment';

import {
  Box,
  Badge,
  Text,
  Stack,
  Grid,
  Progress as ProgressComponent,
  Heading,
  Skeleton,
  Button,
  useDisclosure,
} from '@terminal/design-system';

import { JobHealthStatus } from 'talent-hub/constants';
import {
  Card,
  CardBody,
  CardHeader,
  PageLayoutPaddedBody,
  StatWidget,
} from 'talent-hub/components';

import type { ProfileInfo } from '../../Role.types';
import type { serializeToRoleMilestones, serializerToProgressMetrics } from './Progress.serializer';
import {
  RoleLayout,
  roleTabs,
  JobsIcon,
  PeopleIcon,
  InviteToFeedbackModal,
} from '../../components';
import { ProgressCandidates, RoleMilestone } from './components';

const statusColorMapping = {
  [JobHealthStatus.Hired]: 'success',
  [JobHealthStatus.OnTrack]: 'success',
  [JobHealthStatus.WarningHighRejectionRate]: 'warning',
  [JobHealthStatus.WarningLate]: 'warning',
  [JobHealthStatus.OnHold]: 'disabled',
  [JobHealthStatus.Paused]: 'disabled',
};

const widgetText = {
  [JobHealthStatus.Hired]: 'On Track',
  [JobHealthStatus.OnTrack]: 'On Track',
  [JobHealthStatus.WarningLate]: 'Warning: Running Behind',
  [JobHealthStatus.WarningHighRejectionRate]: 'Warning: Over 70% of Submissions Inactive',
  [JobHealthStatus.OnHold]: 'On Hold',
  [JobHealthStatus.Paused]: 'Paused',
};

const healthStatusColorMapping = {
  [JobHealthStatus.Hired]: 'brand.main',
  [JobHealthStatus.OnTrack]: 'ui.success',
  [JobHealthStatus.WarningHighRejectionRate]: 'ui.warning',
  [JobHealthStatus.WarningLate]: 'ui.warning',
  [JobHealthStatus.OnHold]: 'text.disabled',
  [JobHealthStatus.Paused]: 'text.disabled',
} as const;

export function Progress({
  roleLayoutProps,
  totalSubmissions,
  totalActiveCandidates,
  totalPositions,
  totalHired,
  totalHiresUpcomingText,
  role,
  positions,
  milestone1,
  milestone2,
  milestone3,
  milestone4,
  milestone5,
  recentCandidatesActivities,
  isLoading,
  activeRole,
  today,
  interviewFeedbackUtils,
}: {
  roleLayoutProps: React.ComponentProps<typeof RoleLayout>;
  totalSubmissions: number;
  totalActiveCandidates: number;
  totalPositions: number;
  totalHired: number;
  totalHiresUpcomingText: string;
  role: ReturnType<typeof serializerToProgressMetrics>['roleProps'];
  positions: ReturnType<typeof serializerToProgressMetrics>['positionsProps'];
  milestone1: ReturnType<typeof serializeToRoleMilestones>['milestone1'];
  milestone2: ReturnType<typeof serializeToRoleMilestones>['milestone2'];
  milestone3: ReturnType<typeof serializeToRoleMilestones>['milestone3'];
  milestone4: ReturnType<typeof serializeToRoleMilestones>['milestone4'];
  milestone5: ReturnType<typeof serializeToRoleMilestones>['milestone5'];
  recentCandidatesActivities: ProfileInfo[];
  isLoading: boolean;
  activeRole: string;
  today: moment.Moment;
  interviewFeedbackUtils: {
    isLoading: boolean;
    users: { value: string; label: string }[];
    handleOnSubmitInviteFeedback: (currentValues: {
      users: {
        value: string;
        label: string;
      }[];
      isRoleAccess: boolean;
    }) => { error: boolean };
  };
}) {
  const pathRoot = `/role/${activeRole}/`;
  const feedbackModal = useDisclosure();

  return (
    <RoleLayout {...roleLayoutProps}>
      <InviteToFeedbackModal
        options={interviewFeedbackUtils.users}
        isLoading={interviewFeedbackUtils.isLoading}
        isOpen={feedbackModal.isOpen}
        onClose={feedbackModal.onClose}
        modalTitle="Invite Your Team"
        modalDescription="Team members will be able to see candidate profiles and leave interview feedback for all active candidates for this role. Compensation information will be hidden."
        handleOnSubmitInviteFeedback={interviewFeedbackUtils.handleOnSubmitInviteFeedback}
        shouldHide_candidateCheckbox
      />
      <PageLayoutPaddedBody>
        <Card>
          <CardHeader>
            <Heading variant="heading-3" color="text.primary">
              Progress
              <Skeleton ml={4} minW={10} isLoaded={!isLoading} display="inline-block">
                <Badge fontSize="sm" colorScheme={statusColorMapping[role.healthStatus]}>
                  {role.statusTitle}
                </Badge>
              </Skeleton>
              {!isLoading && role.statusSubtitle && (
                <Badge fontSize="sm" ml={2} colorScheme="primary">
                  {role.statusSubtitle}
                </Badge>
              )}
            </Heading>
          </CardHeader>
          <CardBody>
            {isLoading ? (
              <>
                <Skeleton h={2} w="full" />
                <Skeleton mt={2} h={2} w="full" />
              </>
            ) : (
              positions.map(
                ({
                  hiredDate,
                  index,
                  createDate,
                  healthStatus,
                }: {
                  index: number;
                  hiredDate: string;
                  createDate: string;
                  healthStatus: keyof typeof healthStatusColorMapping;
                }) => {
                  // TODO: move the rest of the business logic into the Progress.serializer.js
                  const targetEnd = moment(createDate).add(90, 'days').format('MMM D, YYYY');

                  const progressToHire =
                    healthStatus === JobHealthStatus.Hired
                      ? 100
                      : ((+today - +moment(createDate)) / (+targetEnd - +moment(createDate))) * 100;
                  return (
                    <Grid
                      mt={4}
                      key={`role-position-${index}`}
                      templateColumns={['1fr', '1fr', '1fr 5fr 1fr']}
                      alignItems="center"
                    >
                      <Box>
                        <Text variant="caption" color="text.secondary">
                          Date Opened {index > 0 && ` #${index + 1}`}
                        </Text>
                        <Text>{createDate}</Text>
                      </Box>
                      <ProgressComponent
                        colorScheme={healthStatusColorMapping[healthStatus]}
                        value={progressToHire}
                        size="sm"
                      />
                      <Box textAlign="right">
                        <Text variant="caption" color="text.secondary">
                          {healthStatus === JobHealthStatus.Hired ? 'Hired' : 'Target'}
                        </Text>
                        <Text>
                          {healthStatus === JobHealthStatus.Hired && hiredDate
                            ? hiredDate
                            : targetEnd}
                        </Text>
                      </Box>
                    </Grid>
                  );
                },
              )
            )}
          </CardBody>
        </Card>
        <Stack mt={6} w="full" direction={['column', 'column', 'row']} spacing={6}>
          <StatWidget.Variation.ThreeColumns
            icon={<PeopleIcon w={20} h={20} />}
            label={`${totalActiveCandidates} Active Candidate${
              totalActiveCandidates !== 1 ? 's' : ''
            }`}
            description={`from ${totalSubmissions} submission${totalSubmissions !== 1 ? 's' : ''}`}
            isLoading={isLoading}
            upcomingText={widgetText[role.healthStatus]}
          />
          <StatWidget.Variation.ThreeColumns
            icon={<JobsIcon w={20} h={20} />}
            label="Total Hires"
            description="Hires made vs positions opened"
            isLoading={isLoading}
            number={totalHired}
            target={totalPositions}
            upcomingText={totalHiresUpcomingText}
          />
          <StatWidget.Variation.ThreeColumns
            label="Invite Your Team"
            description={
              <>
                Let others review candidates for this role
                <Button variant="outline" w="full" mt={4} onClick={feedbackModal.onOpen}>
                  Add Reviewers
                </Button>
              </>
            }
            isLoading={isLoading}
          />
        </Stack>
        <Box mt={6}>
          <ProgressCandidates
            loading={isLoading}
            recentCandidatesActivities={recentCandidatesActivities}
          />
        </Box>
        <Card mt={6}>
          <CardHeader>
            <Heading variant="heading-3" color="text.primary">
              Milestones
            </Heading>
          </CardHeader>
          <CardBody px={4} overflowX="auto">
            {milestone1 && (
              <RoleMilestone
                milestoneNumber={1}
                milestoneTitle="Role Activated"
                buttonText="View Role Requirements"
                buttonRoute={`${pathRoot}${roleTabs.requirements}`}
                date={milestone1.date}
                datePrefix={milestone1.datePrefix}
                completed={milestone1.completed}
              />
            )}
            {milestone2 && (
              <RoleMilestone
                milestoneNumber={2}
                milestoneTitle="First Submission"
                buttonText="View All Submissions"
                buttonRoute={`${pathRoot}${roleTabs.submissions}`}
                date={milestone2.date}
                datePrefix={milestone2.datePrefix}
                completed={milestone2.completed}
              />
            )}
            {milestone3 && (
              <RoleMilestone
                milestoneNumber={3}
                milestoneTitle="First Interview Scheduled"
                buttonText="View All Interviews"
                buttonRoute={`${pathRoot}${roleTabs.interviews}`}
                date={milestone3.date}
                datePrefix={milestone3.datePrefix}
                completed={milestone3.completed}
              />
            )}
            {milestone4 && (
              <RoleMilestone
                milestoneNumber={4}
                milestoneTitle="First Offer Made"
                buttonText="View All Offers"
                buttonRoute={`${pathRoot}${roleTabs.offers}`}
                date={milestone4.date}
                datePrefix={milestone4.datePrefix}
                completed={milestone4.completed}
              />
            )}
            {milestone5 && (
              <RoleMilestone
                milestoneNumber={5}
                milestoneTitle="Offer Signed"
                buttonText="View All Offers"
                buttonRoute={`${pathRoot}${roleTabs.offers}`}
                date={milestone5.date}
                datePrefix={milestone5.datePrefix}
                completed={milestone5.completed}
              />
            )}
          </CardBody>
        </Card>
      </PageLayoutPaddedBody>
    </RoleLayout>
  );
}
