export enum CandidateStageStatus {
  Completed = 'Completed',
  InProgress = 'InProgress',
  Skipped = 'Skipped',
  RejectedAt = 'RejectedAt',
  Pending = 'Pending',
  NotApplicable = 'NotApplicable',
}

export enum CandidateStageName {
  Screening = 'Screening',
  Submission = 'Submission',
  Interview = 'Interview',
  Offer = 'Offer',
  Hired = 'Hired',
}

export enum CandidateInterviewSubStageName {
  ToBeScheduled = 'ToBeScheduled',
  Scheduled = 'Scheduled',
  AwaitingFeedback = 'AwaitingFeedback',
}

export enum CandidateOfferSubStageName {
  BackgroundCheckInitiated = 'BackgroundCheckInitiated',
  BackgroundCheckCompleted = 'BackgroundCheckCompleted',
  OfferExtended = 'OfferExtended',
}

export const CandidateSubStageFriendlyName: { [key: string]: string } = {
  'Submitted to Client - Submitted': 'Candidate Submitted',
  'Submitted – Awaiting Feedback': 'Awaiting Submission Feedback',
  'Submitted to Client - Client Portal Advance': 'Candidate Proceeding to Interview',
  'Client Review - Interview to be Scheduled': 'Interview Scheduling',
  'Client Review - Interview #1 to be Scheduled': 'Interview #1 - Scheduling',
  'Client Review - Interview #2 to be Scheduled': 'Interview #2 - Scheduling',
  'Client Review - Interview #3 to be Scheduled': 'Interview #3 - Scheduling',
  'Client Review - Interview #4 to be Scheduled': 'Interview #4 - Scheduling',
  'Client Review - Interview Scheduled': 'Interview Scheduled',
  'Client Review - Interview #1 - Initial Interview': 'Interview Scheduled',
  'Client Review - Interview #2 - Technical Interview / Assessment': 'Interview Scheduled',
  'Client Review - Interview #3 - Final interview': 'Interview Scheduled',
  'Client Review - Interview #4 - Additional Interview': 'Interview Scheduled',
  'Client Review - Awaiting Client Feedback': 'Awaiting Interview Feedback',
  'Client Review - Final Interview Completed': 'Awaiting Interview Feedback',
  'Client Review - Interview Completed': 'Interview Completed',
  'Client Review - Reference Check Initiated': 'Reference Check Started',
  'Client Review - Reference Check Completed': 'Reference Check Completed',
  'Offer - New Offer': 'New Offer',
  'Offer - Offer Extended': 'Offer Extended',
  'Offer - Offer Accepted': 'Offer Accepted',
  'Offer - Background Check Initiated': 'Background Check Started',
  'Offer - Background Check Completed': 'Background Check Completed',
  'Hired - Placed': 'Hired',
};

export type CandidateStatusGroupMatchings = {
  specifics: string[];
  prefixes: string[];
  buckets: [CandidateOfferSubStageName | CandidateInterviewSubStageName, string[]][];
};

// TODO: since this constant has also contains keys of ignored and rejected and those no
// not stages, maybe we should rename this to CandidateBucketsStatusGroup
export const CandidateStageStatusGroup: {
  Hired: CandidateStatusGroupMatchings;
  Offer: CandidateStatusGroupMatchings;
  Interview: CandidateStatusGroupMatchings;
  Submission: CandidateStatusGroupMatchings;
  Screening: CandidateStatusGroupMatchings;
  /** Not a stage, more of a bucket */
  Rejected: CandidateStatusGroupMatchings;
  /** Not a stage, more of a bucket */
  Ignored: CandidateStatusGroupMatchings;
} = {
  Hired: {
    specifics: ['Offer - Offer Accepted'],
    prefixes: ['Hired', 'Offer - Background Check'],
    buckets: [],
  },
  Offer: {
    specifics: ['Offer - New Offer', 'Offer - Offer Extended'],
    prefixes: [],
    buckets: [
      [CandidateOfferSubStageName.BackgroundCheckInitiated, ['Offer - Background Check Initiated']],
      [CandidateOfferSubStageName.BackgroundCheckCompleted, ['Offer - Background Check Completed']],
      [CandidateOfferSubStageName.OfferExtended, ['Offer - Offer Extended']],
    ],
  },
  Interview: {
    specifics: ['Submitted to Client - Client Portal Advance'],
    prefixes: ['Client Review'],
    buckets: [
      [
        CandidateInterviewSubStageName.ToBeScheduled,
        [
          'Submitted to Client - Client Portal Advance',
          'Client Review - Interview to be Scheduled',
          'Client Review - Interview #1 to be Scheduled',
          'Client Review - Interview #2 to be Scheduled',
          'Client Review - Interview #3 to be Scheduled',
          'Client Review - Interview #4 to be Scheduled',
        ],
      ],
      [
        CandidateInterviewSubStageName.Scheduled,
        [
          'Client Review - Interview Scheduled',
          'Client Review - Interview #1 - Initial Interview',
          'Client Review - Interview #2 - Technical Interview / Assessment',
          'Client Review - Interview #3 - Final interview',
          'Client Review - Interview #4 - Additional Interview',
          'Client Review - Interview #1',
          'Client Review - Interview #2',
          'Client Review - Interview #3',
          'Client Review - Interview #4',
        ],
      ],
      [
        CandidateInterviewSubStageName.AwaitingFeedback,
        [
          'Client Review - Interview Completed',
          'Client Review - Awaiting Client Feedback',
          'Client Review - Final Interview Completed',
        ],
      ],
    ],
  },
  Submission: {
    specifics: ['Submitted to Client - Submitted'],
    prefixes: [],
    buckets: [],
  },
  Screening: {
    specifics: [],
    prefixes: ['New', 'Incomplete', 'Terminal', 'Internal', 'Submitted to Talent'],
    buckets: [],
  },
  Rejected: {
    specifics: ['Candidate Withdrew - Self-Withdrew (Portal)'],
    prefixes: ['Rejected'],
    buckets: [],
  },
  Ignored: {
    specifics: ['Withdrawn - Withdrawn by Terminal'],
    prefixes: [],
    buckets: [],
  },
} as const;
