import moment from 'moment';
import type {
  Candidate_Browse_Args,
  Candidate_Curation_English_Proficiency_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';
import {
  Employment_Type_Choices_Enum,
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
  Role_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';
import { toFriendlyYearsOfExperienceRange } from 'global/utils';
import type {
  SelectTeamMembersQuery,
  InsertSharedCandidateMutationVariables,
} from 'talent-hub/utils';
import { capitalizeEachWord } from 'talent-hub/utils/data';
import {
  employmentTypeMap,
  serializeCandidateHighlights,
  transformCandidateProfile_toInDemandBadgeInfo,
} from 'talent-hub/utils/candidate/serializer.utils';
import { englishProficiencyMap } from 'talent-hub/components/CandidateInformation/JobCandidateProfile.serializer';
import type {
  SelectBrowseCandidatesQuery,
  SelectBrowseCandidatesQueryVariables,
  SelectExploreCandidatesOptionsQuery,
  NonNullable_BrowseCandidate,
} from './data';
import type { SelectSavedCandidatesQuery } from '../saved-candidates/data';
import type { SelectSharedCandidatesQuery } from '../shared-candidates/data';

import type { FeatureFlagHighlightBadge } from '../../constants';
import { isFeatureFlagHighlightBadge } from '../../constants';

// quantity of skills to show in the card
const quantitySkillsToShow = 4;

/**
 * TODO: Before edit this serializer, create test for serializePublicCandidateProfile
 */
export function serializeCandidates(
  data:
    | SelectBrowseCandidatesQuery['candidate_browse']
    | SelectSavedCandidatesQuery['saved_candidate']
    | SelectSharedCandidatesQuery['shared_candidate'],
  skillsToSearch?: number[],
  // TODO: [GROW-208] - This should be refactored, it doesn't seem like a good idea for a serializer to return a DB type since serialization modifies/parse the information.
) {
  return (data || [])
    .map((item) => {
      // item?.candidate

      const candidate = item?.candidate;

      if (!candidate) return null;

      const candidateInDemandBadgeInfo = transformCandidateProfile_toInDemandBadgeInfo(
        candidate?.icims_profile_person?.icims_people[0]?.all_applicant_workflows || [],
      );

      const computedTopCompanyExperience = candidate?.candidate_work_experiences?.some(
        (workExperience) => workExperience.company?.is_top_company,
      );
      const computedStartupExperience = candidate?.candidate_work_experiences?.some(
        (workExperience) => workExperience.company?.is_startup,
      );
      const computedCSDegree = candidate?.candidate_educations?.some(
        (education) => education.has_cs_degree,
      );

      const { badges, highlights, activeMessage } = serializeCandidateHighlights({
        maxBadges: 2,
        maxHighlights: 5,
        candidateInformation: {
          englishProficiency:
            englishProficiencyMap[
              candidate.candidate_curation_detail
                ?.english_proficiency as Candidate_Curation_English_Proficiency_Choices_Enum
            ] || candidate.icims_profile_person?.icims_people?.[0]?.english_proficiency,
          industries: !candidate?.candidate_badge?.top_industry
            ? [
                candidate?.candidate_badge?.industry_1?.name,
                candidate?.candidate_badge?.industry_2?.name,
              ]
            : [
                candidate?.candidate_badge?.top_industry,
                candidate?.candidate_badge?.second_top_industry,
              ],
          previouslyWorked: [
            candidate.candidate_work_experiences?.[0]?.company_name,
            candidate.candidate_work_experiences?.[1]?.company_name,
          ],
          skilledInMultipleLanguages: candidate.candidate_badge?.multiple_languages,
          csDegree:
            candidate.candidate_badge?.cs_degree == null
              ? computedCSDegree
              : candidate.candidate_badge?.cs_degree,
          hasBuiltProductsWith:
            candidate.candidate_badge?.built_new_candidate_work_experience?.company_name,
          yearsTechLeadExperience: candidate.candidate_badge?.years_tech_lead_exp,
          yearsPeopleLeadershipExperience: candidate.candidate_badge?.years_people_lead_exp,
          mutualInterest: candidate?.candidate_jobs,
          topCompanyExperience:
            candidate.candidate_badge?.top_company_exp == null
              ? computedTopCompanyExperience
              : candidate.candidate_badge?.top_company_exp,
          startupCompanyExperience: computedStartupExperience,
          referredCandidate: candidate.candidate_badge?.referred,
          inDemand: candidateInDemandBadgeInfo,
          activeMessage: {
            candidateCurationWorkflows: candidate.candidate_curation_workflows || [],
            candidateStageLogs: candidate.candidate_stage_logs || [],
          },
          previousHire: candidate.is_previous_hire,
        },
      });

      return {
        // ! it may not be a good idea to do this propagation, because we are sending information that we do not observe also is not required by the serializer.
        ...candidate,
        fullName: `${capitalizeEachWord(
          candidate.first_name || candidate.icims_profile_person?.icims_people[0]?.firstname,
        )} ${capitalizeEachWord(
          candidate.last_name || candidate.icims_profile_person?.icims_people[0]?.lastname,
        )}`,
        firstName: capitalizeEachWord(
          candidate.first_name || candidate.icims_profile_person?.icims_people[0]?.firstname,
        ),
        lastName: capitalizeEachWord(
          candidate.last_name || candidate.icims_profile_person?.icims_people[0]?.lastname,
        ),
        last_name_initial: capitalizeEachWord(
          candidate.last_name_initial ||
            candidate.icims_profile_person?.icims_people[0]?.lastname_initial,
        ),
        currentRole: candidate.candidate_work_experiences[0]?.job_title,
        recruiterSummary: undefined,
        skills: candidate.candidate_skills
          .map(({ skill }) => ({
            id: skill.id,
            name: skill.name || '',
            isSelected: skillsToSearch
              ? Boolean(skillsToSearch.find((id) => id === skill.id))
              : false,
          }))
          .sort((a, b) => Number(b.isSelected) - Number(a.isSelected))
          .slice(0, quantitySkillsToShow),
        remainingSkills:
          candidate.candidate_skills.length - quantitySkillsToShow > 0
            ? candidate.candidate_skills.length - quantitySkillsToShow
            : null,
        isSaved: (candidate.saved_candidates_aggregate?.aggregate?.count || 0) > 0,
        yearsExperience: toFriendlyYearsOfExperienceRange(
          candidate.candidate_curation_detail?.years_of_exp_range || null,
        ),
        badges,
        highlights,
        activeMessage,
        employmentType: candidate.employment_type
          ? employmentTypeMap[candidate.employment_type]
          : null,
        ...(item.hasOwnProperty('total_count')
          ? {
              totalCount: (item as NonNullable_BrowseCandidate).total_count,
            }
          : {}),
      };
    })
    .filter((x) => x != null);
}

export function serializeHighlightCandidates({
  candidates,
  isFilterSelected,
  highlightBadgeType,
  isSortByRecommended,
  isLoadingCandidates,
}: {
  candidates: SelectBrowseCandidatesQuery['candidate_browse'];
  isFilterSelected: boolean;
  highlightBadgeType: FeatureFlagHighlightBadge;
  isSortByRecommended?: boolean;
  isLoadingCandidates?: boolean;
}): {
  candidates: SelectBrowseCandidatesQuery['candidate_browse'];
  highlightedCandidates: SelectBrowseCandidatesQuery['candidate_browse'];
} {
  if (
    !highlightBadgeType ||
    !isFeatureFlagHighlightBadge(highlightBadgeType) ||
    !isSortByRecommended ||
    isLoadingCandidates ||
    !candidates
  ) {
    return {
      candidates,
      highlightedCandidates: [],
    };
  }
  const numCandidatesToCheck = 5;
  const numMinCandidatesToShowHighlighted = 3;
  const numMinCandidatesToShowHighlightedWithFilter = 4;

  if (isFilterSelected && candidates.length < numMinCandidatesToShowHighlightedWithFilter) {
    return {
      candidates,
      highlightedCandidates: [],
    };
  }

  if (isFilterSelected && candidates.length <= numCandidatesToCheck) {
    return {
      candidates: candidates.slice(numMinCandidatesToShowHighlighted),
      highlightedCandidates: candidates.slice(0, numMinCandidatesToShowHighlighted),
    };
  }

  if (isFilterSelected) {
    return {
      candidates: candidates.slice(numCandidatesToCheck),
      highlightedCandidates: candidates.slice(0, numCandidatesToCheck),
    };
  }

  if (candidates.length <= numMinCandidatesToShowHighlighted) {
    return {
      candidates,
      highlightedCandidates: [],
    };
  }

  const potentialHighlightedCandidates = candidates.slice(0, numCandidatesToCheck);
  const potentialOtherCandidates = candidates.slice(numCandidatesToCheck);

  const partitionedHighlightedCandidates: SelectBrowseCandidatesQuery['candidate_browse'] = [];
  const partitionedOtherCandidates: SelectBrowseCandidatesQuery['candidate_browse'] = [];

  potentialHighlightedCandidates.forEach((candidate) => {
    if (candidate?.[`has_${highlightBadgeType}`]) {
      partitionedHighlightedCandidates.push(candidate);
    } else {
      partitionedOtherCandidates.push(candidate);
    }
  });

  // if there are less than 3 candidates with the badge, we want to remove the highlighted candidates bucket
  if (partitionedHighlightedCandidates.length < numMinCandidatesToShowHighlighted) {
    return {
      candidates,
      highlightedCandidates: [],
    };
  }

  return {
    candidates: [...partitionedOtherCandidates, ...potentialOtherCandidates],
    highlightedCandidates: partitionedHighlightedCandidates,
  };
}

export function serializeCandidateBrowse({
  data,
  skillsToSearch,
  selectedYoE,
  highlightBadgeType,
  isFilterSelected,
  isSortByRecommended,
  isLoadingCandidates,
}: {
  data?: SelectBrowseCandidatesQuery;
  skillsToSearch?: number[];
  selectedYoE?: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum;
  highlightBadgeType: FeatureFlagHighlightBadge;
  isFilterSelected?: boolean;
  isSortByRecommended?: boolean;
  isLoadingCandidates?: boolean;
}): {
  // TODO: [GROW-208] - This should be refactored, it doesn't seem like a good idea for a serializer to return a DB type since serialization modifies/parse the information.
  candidates: any[];
  otherCandidates?: any[];
  highlightedCandidates: any[];
  highlightedCandidatesTitle: string | null;
  highlightedCandidatesSubtitle: string | null;
} {
  if (!data) {
    return {
      candidates: [],
      highlightedCandidates: [],
      highlightedCandidatesTitle: null,
      highlightedCandidatesSubtitle: null,
    };
  }

  const { title: highlightedCandidatesTitle, subtitle: highlightedCandidatesSubtitle } = (() => {
    if (isFilterSelected) {
      return { title: 'Top Candidates For You', subtitle: null };
    }
    switch (highlightBadgeType) {
      case 'top_company_exp':
        return {
          title: 'Candidates With Top Company Experience',
          subtitle: 'Experience with FAANG, series B+ startups, or leading professional services.',
        };
      case 'years_tech_lead_exp':
        return { title: 'Candidates With Tech Lead Experience', subtitle: null };
      case 'in_demand':
        return {
          title: 'In-Demand Candidates',
          subtitle: 'Candidates interviewing with 2 or more customers',
        };
      default:
        return { title: null, subtitle: null };
    }
  })();

  if (selectedYoE) {
    const candidatesWithMatchedYoE: SelectBrowseCandidatesQuery['candidate_browse'] = [];
    const candidatesWithoutMatchedYoE: SelectBrowseCandidatesQuery['candidate_browse'] = [];

    (data?.candidate_browse || []).forEach((browse_candidate) => {
      if (
        browse_candidate?.candidate?.candidate_curation_detail?.years_of_exp_range === selectedYoE
      ) {
        candidatesWithMatchedYoE.push(browse_candidate);
      } else {
        candidatesWithoutMatchedYoE.push(browse_candidate);
      }
    });

    const { candidates, highlightedCandidates } = serializeHighlightCandidates({
      candidates: candidatesWithMatchedYoE,
      isFilterSelected: Boolean(isFilterSelected),
      highlightBadgeType,
      isSortByRecommended,
      isLoadingCandidates,
    });

    return {
      candidates: serializeCandidates(candidates, skillsToSearch),
      otherCandidates: serializeCandidates(candidatesWithoutMatchedYoE, skillsToSearch),
      highlightedCandidates: serializeCandidates(highlightedCandidates, skillsToSearch),
      highlightedCandidatesTitle,
      highlightedCandidatesSubtitle,
    };
  }

  const { candidates, highlightedCandidates } = serializeHighlightCandidates({
    candidates: data.candidate_browse,
    isFilterSelected: Boolean(isFilterSelected),
    highlightBadgeType,
    isSortByRecommended,
    isLoadingCandidates,
  });

  return {
    candidates: serializeCandidates(candidates, skillsToSearch),
    highlightedCandidates: serializeCandidates(highlightedCandidates, skillsToSearch),
    highlightedCandidatesTitle,
    highlightedCandidatesSubtitle,
  };
}

// same as - https://github.com/terminalinc/product/blob/master/web-candidate/src/candidate/shared/modules/roles/data/Roles.serializer.ts#L4
export const displayRolesNames: Record<Role_Choices_Enum, string> = {
  [Role_Choices_Enum.Fed]: 'Frontend',
  [Role_Choices_Enum.Bed]: 'Backend',
  [Role_Choices_Enum.Fsd]: 'Full Stack',
  [Role_Choices_Enum.Dev]: 'DevOps',
  [Role_Choices_Enum.Mqa]: 'Manual QA',
  [Role_Choices_Enum.Mod]: 'Mobile',
  [Role_Choices_Enum.Dsc]: 'Data Science',
  [Role_Choices_Enum.Enl]: 'Eng. Lead',
  [Role_Choices_Enum.Des]: 'Designer',
  [Role_Choices_Enum.Aqa]: 'Automation QA',
  [Role_Choices_Enum.Dan]: 'Data Analyst',
  [Role_Choices_Enum.Den]: 'Data Engineer',
  [Role_Choices_Enum.Scm]: 'Scrum Master',
  [Role_Choices_Enum.Prm]: 'Product Manager',
  [Role_Choices_Enum.Aml]: 'AI/ML',
};

export const employmentTypeDisplayNames = {
  [Employment_Type_Choices_Enum.FullTime]: 'Full-time',
  [Employment_Type_Choices_Enum.Contract]: 'Contractor',
  [Employment_Type_Choices_Enum.FullTimeAndContract]: '', // * this is not being used
};

export const sortOptionBackendValues = {
  Recommended: 'recommended',
  'Last Active': null,
};

export const candidateHighlightsOptions = [
  { value: 'top_company_exp', label: 'Top Company Experience' },
  { value: 'startup_exp', label: 'Startup Experience' },
  { value: 'previous_hire', label: 'Previous Hire' },
  { value: 'referred', label: 'Referred Candidate' },
  { value: 'built_new', label: '0 -> 1 Experience' },
  { value: 'people_leader', label: 'People Leadership Experience' },
  { value: 'tech_leader', label: 'Tech Lead Experience' },
] as const;

export const candidateHighlights_displayShortNames = {
  top_company_exp: 'Top Company',
  startup_exp: 'Startup',
  previous_hire: 'Previous Hire',
  referred: 'Referred',
  built_new: '0 -> 1',
  people_leader: 'Leadership',
  tech_leader: 'Tech Lead',
};

export type Formfields = {
  candidateRole: string;
  yearsOfExperience: string;
  employmentType: string;
  requiredSkills: {
    value: string;
    label: string;
  }[];
  niceToHaveSkills: {
    value: string;
    label: string;
  }[];
  location: string;
  sort_option: string;
  candidateHighlights: string[];
};

/**
  title is created based on:
  - the first `3` selected filters,
  - using the prepared candidate parts of the title,
  - following the priority order from product.
  */
function createSaveSearchTitle(fiterValues: Formfields): string {
  let skills = '';
  if (fiterValues.requiredSkills?.[0]?.label && fiterValues.requiredSkills?.[1]?.label) {
    skills = `${fiterValues.requiredSkills[0].label} + ${fiterValues.requiredSkills[1].label}`;
  } else if (fiterValues.requiredSkills?.[0]?.label && fiterValues.niceToHaveSkills?.[0]?.label) {
    skills = `${fiterValues.requiredSkills[0].label} + ${fiterValues.niceToHaveSkills[0].label}`;
  } else if (fiterValues.requiredSkills?.[0]?.label) {
    skills = `${fiterValues.requiredSkills[0].label}`;
  } else if (fiterValues.niceToHaveSkills?.[0]?.label) {
    skills = `${fiterValues.niceToHaveSkills[0].label}`;
  }

  const candidateParts_ofSaveSearchTitle = {
    candidateRole: fiterValues.candidateRole,
    skills,
    location: fiterValues.location,
    employmentType: fiterValues.employmentType,
    yearsOfExperience: fiterValues.yearsOfExperience,
    highlights:
      candidateHighlights_displayShortNames[
        fiterValues.candidateHighlights[0] as keyof typeof candidateHighlights_displayShortNames
      ] || '',
  };

  const priorityOrder_ofSaveSearchTitle = [
    'candidateRole',
    'skills',
    'location',
    'employmentType',
    'yearsOfExperience',
    'highlights',
  ] as const;

  return priorityOrder_ofSaveSearchTitle
    .reduce((acc, key) => {
      const value = candidateParts_ofSaveSearchTitle[key];
      if (!value) return acc;
      return [...acc, value];
    }, [] as string[])
    .slice(0, 3)
    .join(' - ');
}

export function serializeSaveSearch({
  values,
  locations,
  yearsOfExperience,
  employmentType,
  browseCandidatesQuery_DefaultVariables,
}: {
  values: Formfields;
  locations: { value: string; label: string }[];
  yearsOfExperience: { value: string; label: string }[];
  employmentType: { value: string; label: string }[];
  browseCandidatesQuery_DefaultVariables: SelectBrowseCandidatesQueryVariables;
}) {
  // TODO: This serialization is same to other ones, maybe we can extract it to a helper
  const locationValue = locations.find((location) => location.label === values.location)?.value;
  const overwriteSerialized: Candidate_Browse_Args = {
    // ? Typeahead not allow key-value, just strings
    candidate_role:
      Object.keys(displayRolesNames).find(
        (key) => displayRolesNames[key as Role_Choices_Enum] === values.candidateRole,
      ) || null,
    regions: locationValue ? [locationValue] : null,
    must_have_skill_ids: values.requiredSkills?.map(({ value }) => Number(value)),
    should_have_skill_ids: values.niceToHaveSkills?.map(({ value }) => Number(value)),
    min_years_of_experience:
      yearsOfExperience.find(
        (yearOfExperience) => yearOfExperience.label === values.yearsOfExperience,
      )?.value || null,
    sort_option:
      sortOptionBackendValues[values.sort_option as keyof typeof sortOptionBackendValues],
    employment_type:
      employmentType.find(
        (currentEmploymentType) => currentEmploymentType.label === values.employmentType,
      )?.value || null,
    badges: values.candidateHighlights?.length ? values.candidateHighlights : null,
  };

  // TODO: This validation is same to other ones, maybe we can extract it to a helper
  const isFilterSelected =
    /* eslint-disable eqeqeq */
    browseCandidatesQuery_DefaultVariables.candidate_browse_args.candidate_role !=
      overwriteSerialized.candidate_role ||
    browseCandidatesQuery_DefaultVariables.candidate_browse_args.min_years_of_experience !=
      overwriteSerialized.min_years_of_experience ||
    browseCandidatesQuery_DefaultVariables.candidate_browse_args.employment_type !=
      overwriteSerialized.employment_type ||
    browseCandidatesQuery_DefaultVariables.candidate_browse_args.regions !=
      overwriteSerialized.regions ||
    browseCandidatesQuery_DefaultVariables.candidate_browse_args.badges !=
      overwriteSerialized.badges ||
    browseCandidatesQuery_DefaultVariables.candidate_browse_args.must_have_skill_ids?.length !=
      overwriteSerialized.must_have_skill_ids?.length ||
    browseCandidatesQuery_DefaultVariables.candidate_browse_args.should_have_skill_ids?.length !=
      overwriteSerialized.should_have_skill_ids?.length;
  /* eslint-enable */

  if (isFilterSelected)
    return {
      isFilterSelected,
      saveSearchData: {
        data: {
          title: createSaveSearchTitle(values),
          explore_candidates_search_candidate_highlights: {
            data: overwriteSerialized.badges?.length
              ? overwriteSerialized.badges.map((badge: string) => ({
                  candidate_highlight: badge,
                }))
              : [],
          },
          employment_type: overwriteSerialized.employment_type,
          role: overwriteSerialized.candidate_role,
          min_years_of_experience: overwriteSerialized.min_years_of_experience,
          must_have_skills: {
            data: overwriteSerialized.must_have_skill_ids.map((skillId: number) => ({
              skill_id: skillId,
            })),
          },
          nice_to_have_skills: {
            data: overwriteSerialized.should_have_skill_ids.map((skillId: number) => ({
              skill_id: skillId,
            })),
          },
          regions: {
            data: overwriteSerialized.regions?.length
              ? [{ region: overwriteSerialized.regions[0] }]
              : [],
          },
        },
      },
    };

  return {
    isFilterSelected,
    saveSearchData: null,
  };
}

export function serializeExploreCandidatesOptions(data?: SelectExploreCandidatesOptionsQuery): {
  candidateHighlights: typeof candidateHighlightsOptions;
  skills: { value: string; label: string }[];
  roles: string[];
  activePostings: { value: number; label: string }[];
  locations: { value: string; label: string }[];
  yearsOfExperience: { value: string; label: string }[];
  sorting: { value: string; label: string }[];
  employmentType: { value: string; label: string }[];
  popularSearches: { title: string; values: Formfields }[];
  savedSearches: { id: number; title: string; createdAt: string; values: Formfields }[];
} {
  if (!data) {
    return {
      candidateHighlights: candidateHighlightsOptions,
      roles: [],
      skills: [],
      activePostings: [],
      locations: [],
      yearsOfExperience: [],
      sorting: [],
      employmentType: [],
      popularSearches: [],
      savedSearches: [],
    };
  }

  return {
    roles:
      data.roles
        ?.map(({ value }) => displayRolesNames[value as Role_Choices_Enum] || '')
        .filter((label) => !!label) || [],
    skills:
      data.skills?.map(({ id, name }) => ({
        value: `${id}`,
        label: name,
      })) || [],
    activePostings: [
      ...(data.active_postings?.map(({ value, label }) => ({
        value,
        label: label || '',
      })) || []),
      { value: 0, label: 'Open a New Role' },
    ],
    locations: data.regions?.map(({ value, name }) => ({
      value,
      label: name,
    })),
    yearsOfExperience: [
      Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.ZeroTwo,
      Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TwoFive,
      Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.FiveTen,
      Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TenPlus,
    ].map((item) => ({
      value: item,
      label: toFriendlyYearsOfExperienceRange(item),
    })),
    sorting: [
      { value: 'recommended', label: 'Recommended' },
      { value: 'lastActive', label: 'Last Active' },
    ],
    employmentType: [
      { value: Employment_Type_Choices_Enum.FullTime, label: employmentTypeDisplayNames.FULL_TIME },
      { value: Employment_Type_Choices_Enum.Contract, label: employmentTypeDisplayNames.CONTRACT },
    ],
    candidateHighlights: candidateHighlightsOptions,
    popularSearches:
      data.explore_candidates_popular_search?.map((popularSearch) => ({
        title: popularSearch.explore_candidates_search.title,
        values: {
          sort_option: 'Recommended',
          candidateRole:
            popularSearch.explore_candidates_search.role != null
              ? displayRolesNames[
                  popularSearch.explore_candidates_search.role satisfies Role_Choices_Enum
                ]
              : '',
          yearsOfExperience: popularSearch.explore_candidates_search.min_years_of_experience
            ? toFriendlyYearsOfExperienceRange(
                popularSearch.explore_candidates_search
                  .min_years_of_experience as Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
              )
            : '',
          employmentType: popularSearch.explore_candidates_search.employment_type
            ? employmentTypeDisplayNames[popularSearch.explore_candidates_search.employment_type]
            : '',
          requiredSkills: popularSearch.explore_candidates_search.must_have_skills.map(
            ({ skill: { name, id } }) => ({ value: `${id}`, label: name }),
          ),
          niceToHaveSkills: popularSearch.explore_candidates_search.nice_to_have_skills.map(
            ({ skill: { name, id } }) => ({ value: `${id}`, label: name }),
          ),
          candidateHighlights:
            popularSearch.explore_candidates_search.explore_candidates_search_candidate_highlights.map(
              (highlight) => highlight.candidate_highlight,
            ),
          location: popularSearch.explore_candidates_search.regions[0]?.region_choice.name || '',
        },
      })) || [],
    savedSearches:
      data.explore_candidates_search?.map((search) => ({
        id: search.id,
        title: search.title,
        createdAt: moment(search.created_at).format('MMMM D, YYYY'),
        values: {
          sort_option: 'Recommended',
          candidateRole:
            search.role != null ? displayRolesNames[search.role satisfies Role_Choices_Enum] : '',
          yearsOfExperience: search.min_years_of_experience
            ? toFriendlyYearsOfExperienceRange(
                search.min_years_of_experience as Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
              )
            : '',
          employmentType: search.employment_type
            ? employmentTypeDisplayNames[search.employment_type]
            : '',
          requiredSkills: search.must_have_skills.map(({ skill: { name, id } }) => ({
            value: `${id}`,
            label: name,
          })),
          niceToHaveSkills: search.nice_to_have_skills.map(({ skill: { name, id } }) => ({
            value: `${id}`,
            label: name,
          })),
          candidateHighlights: search.explore_candidates_search_candidate_highlights.map(
            (highlight) => highlight.candidate_highlight,
          ),
          location: search.regions[0]?.region_choice.name || '',
        },
      })) || [],
  };
}

export function serializeTeamMembersToShareWith(
  data?: SelectTeamMembersQuery,
  candidateId?: number,
) {
  if (!candidateId || !data) {
    return [];
  }

  return data.users.map((user) => ({
    ...user,
    isChecked: Boolean(
      data.shared_candidate.find(
        (el) => el.candidate_id === candidateId && el.user_info_id === user.id,
      ),
    ),
  }));
}

export function serializeToInsertSharedCandidate(
  teamMembersIds: string[],
  candidateId: number,
  userId: number,
  organizationId: number,
): InsertSharedCandidateMutationVariables {
  return {
    candidates_to_share: teamMembersIds
      .map((el) => Number(el))
      .map((teamMember) => ({
        candidate_id: candidateId,
        shared_by_user_info_id: userId,
        shared_by_user_org_id: organizationId,
        user_info_id: teamMember,
      })),
  };
}
