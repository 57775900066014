import {
  capitalizeEachWord,
  createCandidatesStageGraph,
  createCandidateStagesInfo,
  hasCandidateHitStage,
  mergePostingsAndPositions,
} from 'talent-hub/utils';

import type {
  MergedPostingAndPosition,
  AllStagesAllBuckets,
  CandidateStageInfo,
  MergedPostingAndPosition as MergedPostingAndPositionGeneric,
} from 'talent-hub/utils';
import { CandidateInterviewSubStageName } from 'talent-hub/constants';
import { workflowStatuses_ToCandidateWorkflowStatus } from 'talent-hub/utils/candidate/serializer.utils';
import { compareByObjectsDateAsc } from 'global/utils';
import moment from 'moment';
import { candidateFriendlyStatusInfo } from '../../../role/client/dashboard/Dashboard.serializer';
import type { ManageCandidatesPosting, SelectManageCandidatesQuery } from './data';

function groupCandidates_toManageCandidates_RequiringAction({
  submission,
  interview,
}: Pick<AllStagesAllBuckets<CandidateRequiringActionStagingInfo>, 'submission' | 'interview'>): {
  upcomingSubmissionCandidates: CandidateRequiringActionWithStatusInfo[];
  upcomingInterviewCandidates: CandidateRequiringActionWithStatusInfo[];
  interviewsToBeScheduledCandidates: CandidateRequiringActionWithStatusInfo[];
} {
  const pickProfileData = (candidateProfile: CandidateRequiringActionStagingInfo) => {
    const { friendlyStatustext, status, linkText } = candidateFriendlyStatusInfo(
      candidateProfile.candidateRequiringActionInfo.statusText,
    );
    return {
      ...candidateProfile.candidateRequiringActionInfo,
      statusText: friendlyStatustext,
      status,
      linkText,
    };
  };
  const upcomingInterviewCandidatesUnMapped: CandidateRequiringActionStagingInfo[] =
    interview.inProgress.buckets[CandidateInterviewSubStageName.Scheduled] || [];
  const interviewsToBeScheduledCandidatesUnMapped: CandidateRequiringActionStagingInfo[] =
    interview.inProgress.buckets[CandidateInterviewSubStageName.ToBeScheduled] || [];
  return {
    upcomingSubmissionCandidates: submission.inProgress.all
      .map(pickProfileData)
      .sort(compareByObjectsDateAsc),
    upcomingInterviewCandidates: upcomingInterviewCandidatesUnMapped
      .map(pickProfileData)
      .sort(compareByObjectsDateAsc),
    interviewsToBeScheduledCandidates: interviewsToBeScheduledCandidatesUnMapped
      .map(pickProfileData)
      .sort(compareByObjectsDateAsc),
  };
}

type CandidateRequiringAction = {
  jobID?: number | null;
  id?: number | null;
  name: string;
  statusText?: string;
  date?: string;
  role: string;
};

export type CandidateRequiringActionWithStatusInfo = CandidateRequiringAction & {
  status: string;
  linkText: string;
};

type CandidateRequiringActionStagingInfo = {
  candidateRequiringActionInfo: CandidateRequiringAction;
} & CandidateStageInfo;

function transformTo_candidatesWithStageInfo(
  applicantWorkFlows: MergedPostingAndPositionGeneric<ManageCandidatesPosting>['applicant_workflows'][number][],
  postingName: MergedPostingAndPosition<ManageCandidatesPosting>['name'],
): CandidateRequiringActionStagingInfo[] {
  return applicantWorkFlows.map(({ job_profile_id, icims_person, workflow_statuses }) => {
    const statuses = workflow_statuses.map(workflowStatuses_ToCandidateWorkflowStatus);

    const { currentStatus, ...rest } = createCandidateStagesInfo(statuses);

    return {
      ...rest,
      currentStatus,
      candidateRequiringActionInfo: {
        jobID: job_profile_id,
        id: icims_person?.profile_id,
        name: `${capitalizeEachWord(
          icims_person?.candidate?.firstname || icims_person?.firstname,
        )} ${capitalizeEachWord(icims_person?.candidate?.lastname || icims_person?.lastname)}`,
        statusText: currentStatus?.title,
        date: moment(currentStatus?.date).format('MMM D, YYYY'),
        role: postingName || '-',
      },
    };
  });
}

function postingsWithCandidatesRequiringActionAndTotals(
  postings: MergedPostingAndPosition<ManageCandidatesPosting>[],
): {
  openHeadCount: number;
  candidatesRequiringActionByPosting: CandidateRequiringActionWithStatusInfo[];
  totalSubmissions: number;
  totalInterview: number;
}[] {
  return postings.map((posting) => {
    const stagedCandidates = transformTo_candidatesWithStageInfo(
      // Operating on applicant_workflows that are not in the Ignored stage
      posting.applicant_workflows.filter(
        (applicantWorkflow) =>
          !hasCandidateHitStage(
            'Ignored',
            applicantWorkflow.workflow_statuses.map(workflowStatuses_ToCandidateWorkflowStatus),
          ),
      ),
      posting.name,
    );
    const candidateStageGraph = createCandidatesStageGraph(stagedCandidates);

    const {
      upcomingSubmissionCandidates,
      upcomingInterviewCandidates,
      interviewsToBeScheduledCandidates,
    } = groupCandidates_toManageCandidates_RequiringAction({
      submission: candidateStageGraph.submission,
      interview: candidateStageGraph.interview,
    });
    return {
      openHeadCount: posting.openHeadCount,
      // We need to show on candidatesRequiringActionTable the same candidates under "Submissions Requiring Action" and "Interviews - Active" supported statuses on roles
      candidatesRequiringActionByPosting: upcomingSubmissionCandidates.concat(
        upcomingInterviewCandidates,
        interviewsToBeScheduledCandidates,
      ),
      totalSubmissions: upcomingSubmissionCandidates.length,
      totalInterview: upcomingInterviewCandidates.length + interviewsToBeScheduledCandidates.length,
    };
  });
}

export function serializeManageCandidates(data?: SelectManageCandidatesQuery): {
  candidatesRequiringAction: CandidateRequiringActionWithStatusInfo[];
  candidatesReadyForReviewTitle: string;
  candidatesWithInterviewFeedbackTitle: string;
  hasOpenRoles: boolean;
} {
  if (!data) {
    return {
      candidatesRequiringAction: [],
      candidatesReadyForReviewTitle: '0 candidates',
      candidatesWithInterviewFeedbackTitle: '0 candidates',
      hasOpenRoles: false,
    };
  }

  const postings = mergePostingsAndPositions(data.active_postings);

  const augmentedPostings = postingsWithCandidatesRequiringActionAndTotals(postings);

  const candidatesRequiringAction = augmentedPostings.flatMap(
    (h) => h.candidatesRequiringActionByPosting,
  );

  const { candidatesWithInterviewFeedback, candidatesReadyForReview, totalOpenHeadCount } =
    augmentedPostings.reduce(
      (acc, augmentedPosting) => {
        return {
          candidatesWithInterviewFeedback:
            acc.candidatesWithInterviewFeedback + augmentedPosting.totalInterview,
          candidatesReadyForReview:
            acc.candidatesReadyForReview + augmentedPosting.totalSubmissions,
          totalOpenHeadCount: acc.totalOpenHeadCount + augmentedPosting.openHeadCount,
        };
      },
      {
        candidatesWithInterviewFeedback: 0,
        candidatesReadyForReview: 0,
        totalOpenHeadCount: 0,
      },
    );

  return {
    candidatesRequiringAction,
    candidatesReadyForReviewTitle: `${candidatesReadyForReview} candidate${
      candidatesReadyForReview === 1 ? '' : 's'
    }`,
    candidatesWithInterviewFeedbackTitle: `${candidatesWithInterviewFeedback} candidate${
      candidatesWithInterviewFeedback === 1 ? '' : 's'
    }`,
    hasOpenRoles: totalOpenHeadCount !== 0,
  };
}
