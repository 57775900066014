import { createIcon } from '@terminal/design-system';

export const InvitationSubmittedIcon = createIcon({
  displayName: 'InvitationSubmittedIcon',
  viewBox: '0 0 112 112',
  path: (
    <>
      <g clipPath="url(#clip0_241_14129)">
        <path
          d="M3.00012 58.8614L57 26.1606L108.504 58.8614L106.007 112H4.5L3.00012 58.8614Z"
          fill="#AACED9"
        />
        <rect
          x="15.876"
          y="11.2408"
          width="81.3431"
          height="96.8759"
          rx="2.65693"
          fill="white"
          stroke="#25383F"
          strokeWidth="1.22628"
        />
        <path
          d="M40 53C48.8366 53 56 45.8366 56 37C56 28.1634 48.8366 21 40 21C31.1635 21 24 28.1634 24 37C24 45.8366 31.1635 53 40 53Z"
          stroke="#25383F"
          strokeWidth="1.22628"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M43.7413 45.6604C43.6355 48.7389 43.7781 51.0582 44.4039 51.9049"
          stroke="#25383F"
          strokeWidth="1.22628"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.5966 51.9049C36.2546 50.3311 36.365 48.1039 36.2684 45.6282"
          stroke="#25383F"
          strokeWidth="1.22628"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.9486 37.0046C46.9486 37.0046 49.2771 34.0227 47.2201 31.7495C46.4332 30.8752 45.9546 30.9948 45.0435 30.3183C44.5834 29.987 44.8641 28.4455 44.229 27.5573C43.7044 26.8349 42.7381 26.3287 41.2932 26.5266C39.1396 26.8165 39.2684 28.1924 37.2529 28.6893C35.2374 29.1863 34.0823 28.5007 32.0806 29.8904C31.4629 30.3482 30.9792 30.9631 30.6798 31.6713C30.3804 32.3794 30.2762 33.1548 30.378 33.9169C30.6127 35.4262 31.8321 36.7561 32.8077 36.9816L34.1882 37.1104V35.7299C34.1882 35.7299 39.3052 36.549 41.6429 33.9491C41.6429 33.9491 45.9224 33.0287 45.9362 36.977L46.9486 37.0046Z"
          fill="#25383F"
        />
        <path
          d="M37.4967 38.813C37.9084 38.813 38.2422 38.4792 38.2422 38.0675C38.2422 37.6558 37.9084 37.322 37.4967 37.322C37.085 37.322 36.7512 37.6558 36.7512 38.0675C36.7512 38.4792 37.085 38.813 37.4967 38.813Z"
          fill="#25383F"
        />
        <path
          d="M42.5078 38.813C42.9195 38.813 43.2533 38.4792 43.2533 38.0675C43.2533 37.6558 42.9195 37.322 42.5078 37.322C42.0961 37.322 41.7623 37.6558 41.7623 38.0675C41.7623 38.4792 42.0961 38.813 42.5078 38.813Z"
          fill="#25383F"
        />
        <path
          d="M38.9188 42.3701C39.2005 42.6617 39.5863 42.8299 39.9917 42.8376C40.397 42.8454 40.789 42.6922 41.0816 42.4115"
          stroke="#25383F"
          strokeWidth="1.22628"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.8483 38.8269C39.8483 38.8269 40.5431 39.8761 40.3683 40.166"
          stroke="#25383F"
          strokeWidth="1.22628"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.9208 40.7825C46.2695 40.8626 47.4777 40.8042 47.7842 40.6197C48.0906 40.4351 48.3135 40.1388 48.4058 39.7932L48.7325 38.4771C48.9119 37.75 48.484 36.8895 47.7385 36.8343C47.6027 36.8081 46.6204 36.8158 46.4883 36.8567C46.3562 36.8975 46.2361 36.9703 46.1388 37.0685C46.0414 37.1668 45.9698 37.2875 45.9302 37.42C45.8906 37.5525 45.8842 37.6927 45.9116 37.8283L45.9208 40.7825Z"
          stroke="#25383F"
          strokeWidth="1.22628"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.5892 40.7825C33.2405 40.8626 32.0323 40.8042 31.7258 40.6197C31.4194 40.4351 31.1965 40.1388 31.1043 39.7932L30.7775 38.4771C30.5981 37.75 31.026 36.8895 31.7715 36.8343C31.9073 36.8081 32.8896 36.8158 33.0217 36.8567C33.1538 36.8975 33.2739 36.9703 33.3712 37.0685C33.4686 37.1668 33.5402 37.2875 33.5798 37.42C33.6194 37.5525 33.6258 37.6927 33.5984 37.8283L33.5892 40.7825Z"
          stroke="#25383F"
          strokeWidth="1.22628"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.8765 35.2375V40.893C45.8765 41.6659 45.7243 42.4312 45.4285 43.1453C45.1328 43.8594 44.6992 44.5082 44.1527 45.0547C43.6062 45.6012 42.9574 46.0347 42.2433 46.3305C41.5292 46.6263 40.7639 46.7785 39.991 46.7785C39.2181 46.7785 38.4528 46.6263 37.7387 46.3305C37.0246 46.0347 36.3758 45.6012 35.8293 45.0547C35.2828 44.5082 34.8493 43.8594 34.5535 43.1453C34.2577 42.4312 34.1055 41.6659 34.1055 40.893V35.2974"
          stroke="#25383F"
          strokeWidth="1.22628"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="61.0439" y="25" width="26" height="8" rx="1.63504" fill="#D9E3E7" />
        <rect x="27" y="59" width="26" height="8" rx="1.63504" fill="#D9E3E7" />
        <rect x="61.0439" y="36" width="26" height="8" rx="1.63504" fill="#D9E3E7" />
        <rect x="27" y="70" width="26" height="8" rx="1.63504" fill="#D9E3E7" />
        <rect x="27" y="81" width="26" height="8" rx="1.63504" fill="#D9E3E7" />
        <rect x="60" y="52" width="27" height="44" rx="1.63504" fill="#D9E3E7" />
        <path d="M108.693 58.8613L34 111.974H108.285L108.693 58.8613Z" fill="#FFDC82" />
        <path d="M108 59.5L68 87.5" stroke="#25383F" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M2.99998 58.8613L77.6935 111.974H2.99998V58.8613Z" fill="#FFDC82" />
        <path
          d="M3.69348 59.5L43.6935 87.5"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <circle cx="94.2118" cy="16" r="16" fill="#7AA550" />
        <path d="M102 11L91.3333 21L86 16" stroke="white" strokeWidth="1.63504" />
        <path d="M54.095 79.2993L105.599 111.591H54.5037H3.40881L54.095 79.2993Z" fill="#FFDC82" />
        <path
          d="M108.608 113.639C108.961 113.856 109.423 113.745 109.639 113.392C109.856 113.039 109.745 112.578 109.392 112.361L108.608 113.639ZM54.095 79.2993L54.4873 78.6601L54.0824 78.4116L53.6846 78.6715L54.095 79.2993ZM0.589689 113.372C0.242959 113.599 0.145582 114.064 0.372191 114.41C0.598799 114.757 1.06358 114.854 1.41031 114.628L0.589689 113.372ZM109.392 112.361L54.4873 78.6601L53.7026 79.9385L108.608 113.639L109.392 112.361ZM53.6846 78.6715L0.589689 113.372L1.41031 114.628L54.5053 79.9271L53.6846 78.6715Z"
          fill="#25383F"
        />
      </g>
      <defs>
        <clipPath id="clip0_241_14129">
          <rect width="112" height="112" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});

export const NoSearchesIcon = createIcon({
  displayName: 'NoSearchesIcon',
  viewBox: '0 0 72 72',
  path: (
    <>
      <rect width="72" height="72" rx="36" fill="#EBF0F5" />
      <g clipPath="url(#clip0_3545_195996)">
        <rect
          x="37.9312"
          y="40.987"
          width="3.41189"
          height="20.2317"
          transform="rotate(-45 37.9312 40.987)"
          fill="#ABB6BA"
          stroke="black"
        />
        <rect
          x="36.6449"
          y="38.6637"
          width="1.94577"
          height="2.26641"
          transform="rotate(-45 36.6449 38.6637)"
          fill="#F9D357"
          stroke="black"
        />
        <circle cx="28.8631" cy="28.8631" r="12.3631" fill="white" stroke="black" />
        <circle cx="28.8631" cy="28.8631" r="9.79049" fill="#D5DADC" stroke="black" />
        <path d="M36.581 28.8631C36.581 24.9559 33.1256 21.7884 28.8631 21.7884" stroke="white" />
      </g>
      <defs>
        <clipPath id="clip0_3545_195996">
          <rect width="40" height="40" fill="white" transform="translate(16 16)" />
        </clipPath>
      </defs>
    </>
  ),
});
