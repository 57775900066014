import { PageLayoutPaddedBody } from 'talent-hub/components';
import { JobRequirementsOverview } from './components';
import { RoleLayout, RoleBlankLoadingScreen } from '../../components';

export function JobRequirements({
  currentView,
  job,
  loading,
  roleLayoutProps,
}: {
  currentView: string;
  job: any;
  loading: boolean;
  onChangeView: (newView: string) => void;
  roleLayoutProps: React.ComponentProps<typeof RoleLayout>;
}) {
  if (loading) {
    return <RoleBlankLoadingScreen roleLayoutProps={roleLayoutProps} />;
  }

  return (
    <RoleLayout {...roleLayoutProps}>
      <PageLayoutPaddedBody>
        {currentView === 'OVERVIEW' ? <JobRequirementsOverview job={job} /> : undefined}
      </PageLayoutPaddedBody>
    </RoleLayout>
  );
}
