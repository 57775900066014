import {
  Candidate_Curation_English_Proficiency_Choices_Enum,
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
  Candidate_Highlight_Choices_Enum,
  Employment_Type_Choices_Enum,
  Role_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';
import type { SelectTeamMembersQuery } from 'talent-hub/utils';
import type {
  SelectExploreCandidatesOptionsQuery,
  SelectBrowseCandidatesQuery,
  NonNullable_BrowseCandidate,
  NonNullable_BrowseCandidate_Candidate,
} from './graphql';

function createSkill(
  overwrite: Partial<SelectExploreCandidatesOptionsQuery['skills'][number]> = {},
): SelectExploreCandidatesOptionsQuery['skills'][number] {
  return {
    name: 'C++',
    id: 1,
    ...overwrite,
  };
}

function createRole(
  overwrite: Partial<SelectExploreCandidatesOptionsQuery['roles'][number]> = {},
): SelectExploreCandidatesOptionsQuery['roles'][number] {
  return {
    value: 'Webmaster',
    ...overwrite,
  };
}

function createActivePosting(
  overwrite: Partial<SelectExploreCandidatesOptionsQuery['active_postings'][number]> = {},
): SelectExploreCandidatesOptionsQuery['active_postings'][number] {
  return {
    value: 1,
    label: 'Diagnostic Radiographer',
    ...overwrite,
  };
}

function createRegion(
  overwrite: Partial<SelectExploreCandidatesOptionsQuery['regions'][number]> = {},
): SelectExploreCandidatesOptionsQuery['regions'][number] {
  return {
    value: 'CANADA',
    name: 'Canada',
    ...overwrite,
  };
}

function createExploreCandidatesPopularSearch(
  overwrite: Partial<
    SelectExploreCandidatesOptionsQuery['explore_candidates_popular_search'][number]['explore_candidates_search']
  > = {},
): SelectExploreCandidatesOptionsQuery['explore_candidates_popular_search'][number] {
  return {
    explore_candidates_search: {
      id: 1,
      created_at: '2021-08-05T15:00:00.000000+00:00',
      title: 'Frontend - React',
      role: Role_Choices_Enum.Fed,
      min_years_of_experience: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TwoFive,
      nice_to_have_skills: [
        {
          skill: createSkill({
            name: 'React',
            id: 2,
          }),
        },
      ],
      must_have_skills: [
        {
          skill: createSkill({
            name: 'C++',
            id: 1,
          }),
        },
      ],
      employment_type: Employment_Type_Choices_Enum.FullTime,
      explore_candidates_search_candidate_highlights: [
        { candidate_highlight: Candidate_Highlight_Choices_Enum.Referred },
      ],
      regions: [
        {
          region_choice: {
            name: 'Canada',
          },
        },
      ],
      ...overwrite,
    },
  };
}

function createExploreCandidatesUserSearch(
  overwrite: Partial<SelectExploreCandidatesOptionsQuery['explore_candidates_search'][number]> = {},
): SelectExploreCandidatesOptionsQuery['explore_candidates_search'][number] {
  return {
    id: 1,
    created_at: '2021-08-05T15:00:00.000000+00:00',
    title: 'Frontend - React',
    role: Role_Choices_Enum.Fed,
    min_years_of_experience: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TwoFive,
    nice_to_have_skills: [
      {
        skill: createSkill({
          name: 'React',
          id: 2,
        }),
      },
    ],
    must_have_skills: [
      {
        skill: createSkill({
          name: 'C++',
          id: 1,
        }),
      },
    ],
    employment_type: Employment_Type_Choices_Enum.FullTime,
    explore_candidates_search_candidate_highlights: [
      { candidate_highlight: Candidate_Highlight_Choices_Enum.TechLeader },
    ],
    regions: [
      {
        region_choice: {
          name: 'Canada',
        },
      },
    ],
    ...overwrite,
  };
}

function createSelectExploreCandidatesOptionsData(
  overwrite: Partial<SelectExploreCandidatesOptionsQuery> = {},
): SelectExploreCandidatesOptionsQuery {
  return {
    roles: [createRole({ value: 'Frontend' }), createRole({ value: 'Backend' })],
    skills: [
      createSkill({ name: 'Ruby', id: 2 }),
      createSkill({ name: 'Python', id: 3 }),
      createSkill({ name: 'PHP', id: 4 }),
    ],
    active_postings: [createActivePosting()],
    regions: [createRegion()],
    explore_candidates_popular_search: [createExploreCandidatesPopularSearch()],
    explore_candidates_search: [createExploreCandidatesUserSearch()],
    ...overwrite,
  };
}

export const SelectExploreCandidatesOptionsFixture = {
  createData: createSelectExploreCandidatesOptionsData,
  createRole,
  createSkill,
  createActivePosting,
  createRegion,
  createExploreCandidatesPopularSearch,
  createExploreCandidatesUserSearch,
  result: {
    all: createSelectExploreCandidatesOptionsData({
      roles: [
        {
          value: 'FED',
          __typename: 'role_choices',
        },
        {
          value: 'BED',
          __typename: 'role_choices',
        },
        {
          value: 'MQA',
          __typename: 'role_choices',
        },
        {
          value: 'DEV',
          __typename: 'role_choices',
        },
        {
          value: 'FSD',
          __typename: 'role_choices',
        },
        {
          value: 'MOD',
          __typename: 'role_choices',
        },
        {
          value: 'DSC',
          __typename: 'role_choices',
        },
        {
          value: 'ENL',
          __typename: 'role_choices',
        },
        {
          value: 'DES',
          __typename: 'role_choices',
        },
        {
          value: 'AQA',
          __typename: 'role_choices',
        },
        {
          value: 'DEN',
          __typename: 'role_choices',
        },
        {
          value: 'DAN',
          __typename: 'role_choices',
        },
      ],
      skills: [
        {
          id: 17,
          name: 'MySQL',
          __typename: 'skill',
        },
        {
          id: 31,
          name: 'Kotlin',
          __typename: 'skill',
        },
        {
          id: 5,
          name: 'Postgres',
          __typename: 'skill',
        },
        {
          id: 21,
          name: 'GCP',
          __typename: 'skill',
        },
        {
          id: 44,
          name: 'Elixir',
          __typename: 'skill',
        },
        {
          id: 11,
          name: 'Vue.js',
          __typename: 'skill',
        },
        {
          id: 1,
          name: 'Python',
          __typename: 'skill',
        },
        {
          id: 13,
          name: 'R',
          __typename: 'skill',
        },
        {
          id: 72,
          name: 'CSS',
          __typename: 'skill',
        },
        {
          id: 18,
          name: 'NoSQL',
          __typename: 'skill',
        },
        {
          id: 19,
          name: 'Redis',
          __typename: 'skill',
        },
        {
          id: 43,
          name: '.NET',
          __typename: 'skill',
        },
        {
          id: 23,
          name: 'Payments / Fintech',
          __typename: 'skill',
        },
        {
          id: 26,
          name: 'Selenium',
          __typename: 'skill',
        },
        {
          id: 2076,
          name: 'Apache Airflow',
          __typename: 'skill',
        },
        {
          id: 28,
          name: 'Ember.js',
          __typename: 'skill',
        },
        {
          id: 30,
          name: 'Backbone.js',
          __typename: 'skill',
        },
        {
          id: 34,
          name: 'Kinesis',
          __typename: 'skill',
        },
        {
          id: 35,
          name: 'Jenkins',
          __typename: 'skill',
        },
        {
          id: 38,
          name: 'Bootstrap',
          __typename: 'skill',
        },
        {
          id: 41,
          name: 'Flask',
          __typename: 'skill',
        },
        {
          id: 42,
          name: 'Drupal',
          __typename: 'skill',
        },
        {
          id: 45,
          name: 'Haskell',
          __typename: 'skill',
        },
        {
          id: 46,
          name: 'JQuery',
          __typename: 'skill',
        },
        {
          id: 47,
          name: 'Perl',
          __typename: 'skill',
        },
        {
          id: 49,
          name: 'Git',
          __typename: 'skill',
        },
        {
          id: 57,
          name: 'Clojure',
          __typename: 'skill',
        },
        {
          id: 59,
          name: 'Netsuite',
          __typename: 'skill',
        },
        {
          id: 60,
          name: 'Salesforce',
          __typename: 'skill',
        },
        {
          id: 61,
          name: 'Lambda',
          __typename: 'skill',
        },
        {
          id: 62,
          name: 'Hadoop',
          __typename: 'skill',
        },
        {
          id: 65,
          name: 'Cassandra',
          __typename: 'skill',
        },
        {
          id: 4,
          name: 'PHP',
          __typename: 'skill',
        },
        {
          id: 67,
          name: 'Cloudera',
          __typename: 'skill',
        },
        {
          id: 68,
          name: 'Erlang',
          __typename: 'skill',
        },
        {
          id: 69,
          name: 'Redshift',
          __typename: 'skill',
        },
        {
          id: 694,
          name: 'C#',
          __typename: 'skill',
        },
        {
          id: 222,
          name: 'API',
          __typename: 'skill',
        },
        {
          id: 78,
          name: 'Pandas',
          __typename: 'skill',
        },
        {
          id: 82,
          name: 'Automation',
          __typename: 'skill',
        },
        {
          id: 83,
          name: 'Cucumber.js',
          __typename: 'skill',
        },
        {
          id: 85,
          name: 'Sales',
          __typename: 'skill',
        },
        {
          id: 89,
          name: 'Manual',
          __typename: 'skill',
        },
        {
          id: 29,
          name: 'Scala',
          __typename: 'skill',
        },
        {
          id: 79,
          name: 'iOS',
          __typename: 'skill',
        },
        {
          id: 112,
          name: 'Seleium',
          __typename: 'skill',
        },
        {
          id: 115,
          name: 'Business Development',
          __typename: 'skill',
        },
        {
          id: 121,
          name: 'Data Analysis',
          __typename: 'skill',
        },
        {
          id: 122,
          name: 'Express.js',
          __typename: 'skill',
        },
        {
          id: 125,
          name: 'Shell',
          __typename: 'skill',
        },
        {
          id: 131,
          name: 'QAComplete',
          __typename: 'skill',
        },
        {
          id: 150,
          name: 'PySpark',
          __typename: 'skill',
        },
        {
          id: 152,
          name: 'Customer Success',
          __typename: 'skill',
        },
        {
          id: 153,
          name: 'Human Resources',
          __typename: 'skill',
        },
        {
          id: 157,
          name: 'Recruiting',
          __typename: 'skill',
        },
        {
          id: 158,
          name: 'Alluxio',
          __typename: 'skill',
        },
        {
          id: 159,
          name: 'Apache Beam',
          __typename: 'skill',
        },
        {
          id: 160,
          name: 'AMPLab SIMR',
          __typename: 'skill',
        },
        {
          id: 170,
          name: 'Finance',
          __typename: 'skill',
        },
        {
          id: 172,
          name: 'SAP',
          __typename: 'skill',
        },
        {
          id: 173,
          name: 'Accounting',
          __typename: 'skill',
        },
        {
          id: 180,
          name: 'PyTorch',
          __typename: 'skill',
        },
        {
          id: 192,
          name: 'Google Cloud',
          __typename: 'skill',
        },
        {
          id: 203,
          name: 'Cucumber',
          __typename: 'skill',
        },
        {
          id: 240,
          name: 'Marketing',
          __typename: 'skill',
        },
        {
          id: 305,
          name: 'Apache Cassandra',
          __typename: 'skill',
        },
        {
          id: 311,
          name: 'Design',
          __typename: 'skill',
        },
        {
          id: 312,
          name: 'UI/UX',
          __typename: 'skill',
        },
        {
          id: 313,
          name: 'Product',
          __typename: 'skill',
        },
        {
          id: 319,
          name: 'Accessibility',
          __typename: 'skill',
        },
        {
          id: 336,
          name: 'Postman',
          __typename: 'skill',
        },
        {
          id: 385,
          name: 'Looker',
          __typename: 'skill',
        },
        {
          id: 397,
          name: 'WordPress',
          __typename: 'skill',
        },
        {
          id: 416,
          name: 'TestNG',
          __typename: 'skill',
        },
        {
          id: 457,
          name: 'Oracle',
          __typename: 'skill',
        },
        {
          id: 474,
          name: 'Jira',
          __typename: 'skill',
        },
        {
          id: 513,
          name: 'JMeter',
          __typename: 'skill',
        },
        {
          id: 527,
          name: 'Leadership',
          __typename: 'skill',
        },
        {
          id: 2072,
          name: '.NET Core',
          __typename: 'skill',
        },
        {
          id: 560,
          name: 'TestING',
          __typename: 'skill',
        },
        {
          id: 568,
          name: 'SoapUI',
          __typename: 'skill',
        },
        {
          id: 570,
          name: 'Apache MapReduce',
          __typename: 'skill',
        },
        {
          id: 576,
          name: 'Engineering',
          __typename: 'skill',
        },
        {
          id: 583,
          name: 'Maven',
          __typename: 'skill',
        },
        {
          id: 617,
          name: 'Invision',
          __typename: 'skill',
        },
        {
          id: 634,
          name: 'Adobe XD',
          __typename: 'skill',
        },
        {
          id: 640,
          name: 'Apache Accumulo',
          __typename: 'skill',
        },
        {
          id: 644,
          name: 'Apex Code',
          __typename: 'skill',
        },
        {
          id: 645,
          name: 'Triggers',
          __typename: 'skill',
        },
        {
          id: 646,
          name: 'Lightning Web Components',
          __typename: 'skill',
        },
        {
          id: 650,
          name: 'Lightning',
          __typename: 'skill',
        },
        {
          id: 653,
          name: 'Apache Apex',
          __typename: 'skill',
        },
        {
          id: 1930,
          name: 'Objective C',
          __typename: 'skill',
        },
        {
          id: 665,
          name: 'Apache Hadoop Benchmarking',
          __typename: 'skill',
        },
        {
          id: 668,
          name: 'Jasmine',
          __typename: 'skill',
        },
        {
          id: 672,
          name: 'CircleCI',
          __typename: 'skill',
        },
        {
          id: 680,
          name: 'TestComplete',
          __typename: 'skill',
        },
        {
          id: 682,
          name: 'GitHub',
          __typename: 'skill',
        },
        {
          id: 686,
          name: 'Appium',
          __typename: 'skill',
        },
        {
          id: 27,
          name: 'Elasticsearch',
          __typename: 'skill',
        },
        {
          id: 691,
          name: 'Athena',
          __typename: 'skill',
        },
        {
          id: 693,
          name: 'Blockchain',
          __typename: 'skill',
        },
        {
          id: 695,
          name: 'COBOL',
          __typename: 'skill',
        },
        {
          id: 697,
          name: 'CloudFront',
          __typename: 'skill',
        },
        {
          id: 698,
          name: 'Cocoa',
          __typename: 'skill',
        },
        {
          id: 699,
          name: 'Data Lakes',
          __typename: 'skill',
        },
        {
          id: 700,
          name: 'Data Warehouse',
          __typename: 'skill',
        },
        {
          id: 701,
          name: 'Dropwizard',
          __typename: 'skill',
        },
        {
          id: 702,
          name: 'DynamoDB',
          __typename: 'skill',
        },
        {
          id: 703,
          name: 'EDI',
          __typename: 'skill',
        },
        {
          id: 704,
          name: 'F#',
          __typename: 'skill',
        },
        {
          id: 705,
          name: 'Flink',
          __typename: 'skill',
        },
        {
          id: 706,
          name: 'Geospatial / GIS',
          __typename: 'skill',
        },
        {
          id: 708,
          name: 'Machine Learning',
          __typename: 'skill',
        },
        {
          id: 709,
          name: 'OOP',
          __typename: 'skill',
        },
        {
          id: 710,
          name: 'SOA',
          __typename: 'skill',
        },
        {
          id: 711,
          name: 'Tensorflow',
          __typename: 'skill',
        },
        {
          id: 713,
          name: 'AngularJS',
          __typename: 'skill',
        },
        {
          id: 58,
          name: 'GraphQL',
          __typename: 'skill',
        },
        {
          id: 95,
          name: 'Golang',
          __typename: 'skill',
        },
        {
          id: 40,
          name: 'Django',
          __typename: 'skill',
        },
        {
          id: 50,
          name: 'Linux',
          __typename: 'skill',
        },
        {
          id: 32,
          name: 'Redux',
          __typename: 'skill',
        },
        {
          id: 56,
          name: 'Apache Spark',
          __typename: 'skill',
        },
        {
          id: 63,
          name: 'Objective-C',
          __typename: 'skill',
        },
        {
          id: 37,
          name: 'Swift',
          __typename: 'skill',
        },
        {
          id: 852,
          name: 'Executive',
          __typename: 'skill',
        },
        {
          id: 1815,
          name: 'SQL Database',
          __typename: 'skill',
        },
        {
          id: 39,
          name: 'Laravel',
          __typename: 'skill',
        },
        {
          id: 20,
          name: 'Angular',
          __typename: 'skill',
        },
        {
          id: 850,
          name: 'Adobe',
          __typename: 'skill',
        },
        {
          id: 53,
          name: 'Spring',
          __typename: 'skill',
        },
        {
          id: 856,
          name: 'Airflow',
          __typename: 'skill',
        },
        {
          id: 64,
          name: 'Ansible',
          __typename: 'skill',
        },
        {
          id: 25,
          name: 'Terraform',
          __typename: 'skill',
        },
        {
          id: 692,
          name: 'Big Data',
          __typename: 'skill',
        },
        {
          id: 835,
          name: 'Talent Attraction',
          __typename: 'skill',
        },
        {
          id: 94,
          name: 'HTML/CSS',
          __typename: 'skill',
        },
        {
          id: 36,
          name: 'Azure',
          __typename: 'skill',
        },
        {
          id: 52,
          name: 'Android',
          __typename: 'skill',
        },
        {
          id: 325,
          name: 'Tableau',
          __typename: 'skill',
        },
        {
          id: 55,
          name: 'Pytest',
          __typename: 'skill',
        },
        {
          id: 24,
          name: 'C/C++',
          __typename: 'skill',
        },
        {
          id: 16,
          name: 'Docker',
          __typename: 'skill',
        },
        {
          id: 641,
          name: 'Preact',
          __typename: 'skill',
        },
        {
          id: 22,
          name: 'Kubernetes',
          __typename: 'skill',
        },
        {
          id: 7,
          name: 'SQL',
          __typename: 'skill',
        },
        {
          id: 1552,
          name: 'Ruby on Rails',
          __typename: 'skill',
        },
        {
          id: 48,
          name: 'REST API',
          __typename: 'skill',
        },
        {
          id: 51,
          name: 'Unix',
          __typename: 'skill',
        },
        {
          id: 569,
          name: 'Sketch',
          __typename: 'skill',
        },
        {
          id: 54,
          name: 'MongoDB',
          __typename: 'skill',
        },
        {
          id: 10,
          name: 'Go',
          __typename: 'skill',
        },
        {
          id: 2074,
          name: 'Amazon Redshift',
          __typename: 'skill',
        },
        {
          id: 2077,
          name: 'Apache Kafka',
          __typename: 'skill',
        },
        {
          id: 2080,
          name: 'ASP .NET',
          __typename: 'skill',
        },
        {
          id: 2083,
          name: 'Cordova',
          __typename: 'skill',
        },
        {
          id: 2085,
          name: 'Cypress.js ',
          __typename: 'skill',
        },
        {
          id: 2086,
          name: 'DataDog',
          __typename: 'skill',
        },
        {
          id: 2088,
          name: 'Ember',
          __typename: 'skill',
        },
        {
          id: 2090,
          name: 'Firebase',
          __typename: 'skill',
        },
        {
          id: 2092,
          name: 'Flutter',
          __typename: 'skill',
        },
        {
          id: 2093,
          name: 'GCP ',
          __typename: 'skill',
        },
        {
          id: 2094,
          name: 'GitLab',
          __typename: 'skill',
        },
        {
          id: 2095,
          name: 'Ionic',
          __typename: 'skill',
        },
        {
          id: 2096,
          name: 'Jeast',
          __typename: 'skill',
        },
        {
          id: 2100,
          name: 'Mocha',
          __typename: 'skill',
        },
        {
          id: 2102,
          name: 'Neo4j',
          __typename: 'skill',
        },
        {
          id: 2103,
          name: 'NextUI',
          __typename: 'skill',
        },
        {
          id: 2104,
          name: 'NumPY',
          __typename: 'skill',
        },
        {
          id: 2107,
          name: 'Power BI',
          __typename: 'skill',
        },
        {
          id: 2108,
          name: 'Puppet',
          __typename: 'skill',
        },
        {
          id: 2109,
          name: 'RabbitMQ',
          __typename: 'skill',
        },
        {
          id: 2111,
          name: 'Sinatra',
          __typename: 'skill',
        },
        {
          id: 2112,
          name: 'Sketch ',
          __typename: 'skill',
        },
        {
          id: 2115,
          name: 'Splunk',
          __typename: 'skill',
        },
        {
          id: 8,
          name: 'Node',
          __typename: 'skill',
        },
        {
          id: 2113,
          name: 'Snowflake',
          __typename: 'skill',
        },
        {
          id: 3,
          name: 'JavaScript',
          __typename: 'skill',
        },
        {
          id: 33,
          name: 'Kafka',
          __typename: 'skill',
        },
        {
          id: 6,
          name: 'AWS',
          __typename: 'skill',
        },
        {
          id: 2,
          name: 'React',
          __typename: 'skill',
        },
        {
          id: 2352,
          name: 'JVM',
          __typename: 'skill',
        },
        {
          id: 14,
          name: 'Java',
          __typename: 'skill',
        },
        {
          id: 2596,
          name: 'CI/CD',
          __typename: 'skill',
        },
        {
          id: 2592,
          name: 'Angular 2+',
          __typename: 'skill',
        },
        {
          id: 2593,
          name: 'Kibana',
          __typename: 'skill',
        },
        {
          id: 2595,
          name: 'Prometheus',
          __typename: 'skill',
        },
        {
          id: 2599,
          name: 'AdobeXD',
          __typename: 'skill',
        },
        {
          id: 2601,
          name: 'Mockups',
          __typename: 'skill',
        },
        {
          id: 2594,
          name: 'New Relic',
          __typename: 'skill',
        },
        {
          id: 2598,
          name: 'Figma',
          __typename: 'skill',
        },
        {
          id: 1551,
          name: 'Microservices',
          __typename: 'skill',
        },
        {
          id: 71,
          name: 'HTML',
          __typename: 'skill',
        },
        {
          id: 2120,
          name: 'XCode',
          __typename: 'skill',
        },
        {
          id: 9,
          name: 'Ruby',
          __typename: 'skill',
        },
        {
          id: 70,
          name: 'React Native',
          __typename: 'skill',
        },
        {
          id: 12,
          name: 'Typescript',
          __typename: 'skill',
        },
        {
          id: 1011,
          name: 'N/A',
          __typename: 'skill',
        },
        {
          id: 2119,
          name: 'Xamarin',
          __typename: 'skill',
        },
      ],
      active_postings: [],
      regions: [
        {
          value: 'CANADA',
          name: 'Canada',
          __typename: 'region_choices',
        },
        {
          value: 'EUROPE',
          name: 'Europe',
          __typename: 'region_choices',
        },
        {
          value: 'LAT_AM',
          name: 'Latin America',
          __typename: 'region_choices',
        },
      ],
    }),
  },
};

function createCandidateSkill(
  overwrite: Partial<NonNullable_BrowseCandidate_Candidate['candidate_skills'][number]> = {},
): NonNullable_BrowseCandidate_Candidate['candidate_skills'][number] {
  return {
    skill: {
      id: 1,
      name: 'Python',
    },
    ...overwrite,
  };
}

function createCandidateCuration(
  overwrite: Partial<NonNullable_BrowseCandidate_Candidate['candidate_curation_detail']> = {},
): NonNullable_BrowseCandidate_Candidate['candidate_curation_detail'] {
  return {
    english_proficiency: Candidate_Curation_English_Proficiency_Choices_Enum.Basic,
    years_of_exp_range: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TwoFive,
    ...overwrite,
  };
}

function createWork(
  overwrite: Partial<
    NonNullable_BrowseCandidate_Candidate['candidate_work_experiences'][number]
  > = {},
): NonNullable_BrowseCandidate_Candidate['candidate_work_experiences'][number] {
  return {
    job_title: 'Fullstack Engineer',
    company_name: 'Terminal',
    company: {
      is_startup: false,
      is_top_company: false,
    },
    ...overwrite,
  };
}

function createCandidatesAggregate(
  overwrite: Partial<NonNullable_BrowseCandidate_Candidate['saved_candidates_aggregate']> = {},
): NonNullable_BrowseCandidate_Candidate['saved_candidates_aggregate'] {
  return {
    aggregate: {
      count: 0,
    },
    ...overwrite,
  };
}

function createCandidateBadges(
  overwrite: Partial<NonNullable_BrowseCandidate_Candidate['candidate_badge']> = {},
): NonNullable_BrowseCandidate_Candidate['candidate_badge'] {
  return {
    top_company_exp: false,
    referred: false,
    rising_star: false,
    multiple_languages: false,
    cs_degree: false,
    years_tech_lead_exp: null,
    years_people_lead_exp: null,
    industry_1: null,
    industry_2: null,
    built_new_candidate_work_experience: null,
    top_industry: null,
    second_top_industry: null,
    ...overwrite,
  };
}

function createCandidateStageLogs(
  overwrite: Partial<NonNullable_BrowseCandidate_Candidate['candidate_stage_logs'][number]> = {},
): NonNullable_BrowseCandidate_Candidate['candidate_stage_logs'][number] {
  return {
    created_at: '2023-01-04T21:53:44.6991+00:00',
    ...overwrite,
  };
}
function createCandidateCurationWorkflows(
  overwrite: Partial<
    NonNullable_BrowseCandidate_Candidate['candidate_curation_workflows'][number]
  > = {},
): NonNullable_BrowseCandidate_Candidate['candidate_curation_workflows'][number] {
  return {
    created_at: '2023-01-04T21:53:44.6991+00:00',
    ...overwrite,
  };
}

function createCandidateJobs(
  overwrite: Partial<NonNullable_BrowseCandidate_Candidate['candidate_jobs'][number]> = {},
): NonNullable_BrowseCandidate_Candidate['candidate_jobs'][number] {
  return {
    icims_profile_job: {
      icims_jobs: [
        {
          icims_company: {
            name: 'Parker-Gomez',
            profile_id: 1012,
            organizations: [],
          },
        },
      ],
    },
    ...overwrite,
  };
}

function createCandidate(
  overwrite: Partial<NonNullable_BrowseCandidate_Candidate> = {},
): NonNullable_BrowseCandidate_Candidate {
  return {
    id: 67,
    first_name: 'Edward',
    last_name: 'Owens',
    last_name_initial: 'O',
    country_choice: {
      name: 'Mexico',
    },
    city: 'Guadalajara',
    candidate_curation_detail: createCandidateCuration({}),
    employment_type: Employment_Type_Choices_Enum.Contract,
    candidate_skills: [
      createCandidateSkill({
        skill: {
          id: 18,
          name: 'NoSQL',
        },
      }),
      createCandidateSkill(),
      createCandidateSkill({
        skill: {
          id: 71,
          name: 'HTML',
        },
      }),
    ],
    candidate_work_experiences: [createWork()],
    icims_profile_person: null,
    saved_candidates_aggregate: createCandidatesAggregate(),
    candidate_badge: createCandidateBadges(),
    candidate_curation_workflows: [createCandidateCurationWorkflows()],
    candidate_stage_logs: [createCandidateStageLogs()],
    candidate_jobs: [createCandidateJobs()],
    candidate_educations: [],
    is_previous_hire: false,
    ...overwrite,
  };
}

function createCandidateBrowse(
  overwrite: Partial<NonNullable_BrowseCandidate> = {},
): NonNullable_BrowseCandidate {
  return {
    candidate: createCandidate(),
    total_count: 1,
    has_top_company_exp: false,
    has_years_tech_lead_exp: false,
    has_in_demand: false,
    ...overwrite,
  };
}

function createSelectExploreCandidateData(
  overwrite: Partial<SelectBrowseCandidatesQuery> = {},
): SelectBrowseCandidatesQuery {
  return {
    candidate_browse: [createCandidateBrowse()],
    // @ts-ignore TODO (TP-1666): fix ignored typescript error
    location: [
      {
        country: 'Canada',
        employment_type: Employment_Type_Choices_Enum.FullTime,
      },
    ],
    ...overwrite,
  };
}

function createTeamMember(
  overwrite: Partial<SelectTeamMembersQuery['users'][number]> = {},
): SelectTeamMembersQuery['users'][number] {
  return {
    id: 1,
    name: 'Steve Rogers',
    ...overwrite,
  };
}

function createSelectTeamMembers(
  overwrite: Partial<SelectTeamMembersQuery> = {},
): SelectTeamMembersQuery {
  return {
    users: [createTeamMember()],
    shared_candidate: [
      {
        candidate_id: 2,
        user_info_id: 1,
      },
    ],
    ...overwrite,
  };
}

export const SelectBrowseCandidatesFixture = {
  createData: createSelectExploreCandidateData,
  createCandidate,
  createCandidateBrowse,
  createCandidateBadges,
  createCandidateSkill,
  createWork,
  createCandidateCuration,
  createCandidatesAggregate,
  createTeamMember,
  createSelectTeamMembers,
  createCandidateJobs,
  createCandidateCurationWorkflows,
  createCandidateStageLogs,
};
