import { useLocalStorage } from 'global/utils';
import Sentry from 'global/sentry';
import { useMutation } from '@apollo/client';
import type { ApolloError } from '@apollo/client';
import { useCustomerAuth } from 'talent-hub/utils';
import { UpsertLastStepCompleted } from '../../data/graphql';
import type {
  UpsertLastStepCompletedMutationVariables,
  UpsertLastStepCompletedMutation,
} from '../../data/graphql';
import { BookCall } from './BookCall';

export function BookCallController({
  prospectID,
  onClickSignUp,
}: {
  prospectID: number;
  onClickSignUp: () => void;
}) {
  const auth = useCustomerAuth();
  const [campaignInfoInSession] = useLocalStorage<Record<string, string>>('campaignInfo', {});

  const [upsertLastStepCompleted] = useMutation<
    UpsertLastStepCompletedMutation,
    UpsertLastStepCompletedMutationVariables
  >(UpsertLastStepCompleted, {
    onError: (error: ApolloError) => {
      Sentry.captureException(error);
    },
  });

  const handleEventScheduled = () => {
    upsertLastStepCompleted({
      variables: {
        userId: auth.user?.id as number,
        lastStepCompleted: 'Sales Meeting',
      },
    });
  };

  return (
    <BookCall
      prospectID={prospectID}
      onEventScheduled={handleEventScheduled}
      campaignInfoInSession={campaignInfoInSession}
      onClickSignUp={onClickSignUp}
    />
  );
}
