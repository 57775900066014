import { useFormik } from 'formik';
import * as events from 'global/events';
import * as Yup from 'yup';
import { useQuery } from 'global/utils';
import { useDisclosure, useToast } from '@terminal/design-system';
import { JOB_FOLDER_APPROVED } from 'talent-hub/constants';
import {
  useCustomerAuthorizedUserSession,
  useCandidateModalInfo_withInvitation,
  useSharedCandidates,
  useTalentHubFlags,
} from 'talent-hub/utils';
import { serializeExploreCandidatesOptions } from '../explore-candidates/ExploreCandidates.serializer';
import type {
  SelectSavedCandidatesOptionsQuery,
  SelectSavedCandidatesOptionsQueryVariables,
} from '../saved-candidates/data';
import { SelectSavedCandidatesOptions } from '../saved-candidates/data';

const validationSchema = Yup.object().shape({
  openPosition: Yup.string()
    .matches(/^(?!Select an Open Role$).*$/)
    .required(),
});

export function useCandidateProfileController({ candidateID }: { candidateID?: number } = {}) {
  const featureFlags = useTalentHubFlags();
  const { user, viewingOrganization } = useCustomerAuthorizedUserSession();
  const toast = useToast({
    position: 'bottom-left',
    duration: 4000,
  });

  // TODO: rename this query, because is not related with saved candidates.
  const savedCandidatesOptions = useQuery<
    SelectSavedCandidatesOptionsQuery,
    SelectSavedCandidatesOptionsQueryVariables
  >(SelectSavedCandidatesOptions, {
    variables: {
      organization_id: viewingOrganization.ID,
      job_folder_approved: JOB_FOLDER_APPROVED,
    },
  });

  const { activePostings } = serializeExploreCandidatesOptions({
    active_postings: savedCandidatesOptions.data?.active_postings || [],
    skills: [],
    roles: [],
    regions: [],
    explore_candidates_popular_search: [],
    explore_candidates_search: [],
  });

  const {
    handleResumeLinkClicked,
    createInvitation,
    onCloseInviteModalClick,
    inviteModal,
    inviteView,
    candidateQueryErrors,
    loadingCandidateInformation,
    profile,
    candidateHighlights,
  } = useCandidateModalInfo_withInvitation({ candidateID });

  const showCandidateProfile = !!candidateID;

  const { isValid, values, handleBlur, handleChange, handleSubmit, resetForm } = useFormik<{
    openPosition?: string;
  }>({
    initialValues: {
      openPosition: undefined,
    },
    validateOnMount: true,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: ({ openPosition }) => {
      const openPositionID =
        activePostings.find((position) => position.label === openPosition)?.value || null;

      createInvitation({
        variables: {
          interview_invitation: {
            candidate_id: Number(candidateID),
            organization_id: viewingOrganization.ID,
            interested_user_info_id: user?.id,
            job_profile_id: openPositionID ? Number(openPositionID) : null,
          },
        },
      });

      // Track event
      events.track(events.name.exploreCandidates.inviteToInterview, {
        interview_initiated_by: featureFlags.candidateProfileInviteRequestButtonText,
        organization_id: viewingOrganization.ID,
        user_id: user.id,
        user_name: `${user.first_name} ${user.last_name}`,
        candidate_id: candidateID,
        candidate_name: profile.fullName,
        role_id: openPositionID ? Number(openPositionID) : null,
        role_name: openPosition,
      });
    },
  });

  const handleCloseInviteToInterviewModal = () => {
    inviteModal.onClose();
    onCloseInviteModalClick();
    resetForm();
  };

  // Start shared_candidate logic
  const {
    isOpen: isShareWithModalOpen,
    onClose: onCloseShareWithModal,
    onOpen: onOpenShareWithModal,
  } = useDisclosure();
  const { teamMembers, handleOnInsertSharedCandidate } = useSharedCandidates({});

  const onInsertSharedCandidate = (candidateId: number, teamMembersIds: string[]) => {
    handleOnInsertSharedCandidate(candidateId, teamMembersIds, () => {
      toast({
        title: 'Shared Successfully!',
        description: 'The candidate was shared successfully.',
        status: 'success',
      });
      onCloseShareWithModal();
    });
  };
  // end shared_candidate logic

  return {
    showCandidateProfile,
    profile,
    loadingCandidateInformation,
    handleResumeLinkClicked,
    candidateQueryErrors,
    onOpenShareWithModal,
    candidateHighlights,
    InviteToInterview: {
      isValid,
      values,
      handleBlur,
      handleChange,
      handleSubmit,
      inviteModal,
      handleCloseInviteToInterviewModal,
      inviteView,
      activePostings,
      viewingOrg: viewingOrganization.ID,
    },
    shareWithOthersModal: {
      isShareWithModalOpen,
      teamMembers,
      onCloseShareWithModal,
      onInsertSharedCandidate,
    },
  };
}
